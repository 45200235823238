import api from "../../../core/api";
import { useQuery } from "react-query";

const useGetList = (id) => {
  const data = useQuery(["getServiceOrderList", id], () =>
    api.get(`/orden_servicio/orden_servicio_por_id/${id}`)
  );

  return data;
};

export default useGetList;
