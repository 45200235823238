import api from "../../../core/api";
import { useQuery } from "react-query";

const useGetPendingTestFilter = (
  start_date,
  end_date,
  client,
  workLine,
  id_order,
  codigo_muestra
) => {
  const data = useQuery(["useGetPendingTestFilter"], () =>
    api.get(
      `/ensayo/filtro_ensayo/?data={"fecha_inicio":${start_date},"fecha_fin":${end_date},"cliente":${client},"linea_trabajo":${workLine},"id_orden":${id_order},"codigo_muestra":${codigo_muestra}}`
    )
  );
  return data;
};

export default useGetPendingTestFilter;
