import styled from "styled-components";
import { theme } from "../../styles/theme";

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  margin-top: 30px;
  width: 100%;
  max-height: 60vh;
  height: 60vh;
  background-color: ${theme.colors.white};
  box-shadow: ${(props) => theme.elevation[`z${props.elevation}`]};
  border-radius: ${(props) => props.radius}px;
  align-self: center;
`;

export const WrapperForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(50vh - 50px);
  max-height: calc(50vh - 50px);
  overflow-y: auto;
  margin-top: 10px;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    height: 100%;
    max-height: 100%;
  }
`;

export const WrapperLine = styled.div`
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  background: ${theme.colors.lightOrange};
  margin-right: 10px;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    display: flex;
    padding: 12px;
  }
`;
