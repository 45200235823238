import { useMutation } from "react-query";
import api from "../../../core/api";

const useDeleteUser = () => {
  const mutation = useMutation((userId) => {
    return api.delete(`/ensayo/auxiliar_operativo/${userId}`);
  });
  return mutation;
};

export default useDeleteUser;
