import api from "../../../core/api";
import { useMutation } from "react-query";

const useCreateSpecimens = () => {
  const mutation = useMutation((formData) => {
    return api.post("/muestra/especimenes/", formData);
  });
  return mutation;
};
export default useCreateSpecimens;
