import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import "./App.css";
import { Layout } from "../components/Layout";

const App = () => {
  const { pathname } = useLocation();

  return (
    <section className="App">
      {pathname === "/" ? (
        <main className="main">
          <Outlet />
        </main>
      ) : (
        <main className="main">
          <Layout>
            <Outlet />
          </Layout>
        </main>
      )}
    </section>
  );
};

export { App };
