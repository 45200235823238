export const theme = {
  colors: {
    white: "#FFFFFF",
    black: "#000000",
    dark: "#4D4D4D",
    yellow: "#EBA900",
    gray: "#c0c0c0dc",
    gray2: "#6c757d",
    gray3: "#A6B4BF",
    green: "#198754",
    greenHover: "#157347",
    light: "#e8f0fe",
    light2: "#dee2e6",
    light3: "#F8F9FA",
    red: "#cb410b",
    red2: "#ff4d4f",
    orange: "#FE7200",
    lightOrange: "#ff9c4b",
    orangeHover: "#E36600",
  },
  fonts: {
    size: {
      h1: "3rem",
      h2: "2.5rem",
      h3: "2rem",
      h4: "1.5rem",
      h5: "1.25rem",
      h6: "1.125rem",
      default: "1rem",
      sm: "0.875rem",
      xs: "0.75rem",
      xxs: "0.625rem",
      xxxs: "0.5rem",
    },
    weight: {
      light: 300,
      regular: 400,
      medium: 500,
      semibold: 600,
      bold: 700,
    },
  },
  breakpoints: {
    xs: "320px",
    sm: "480px",
    md: "820px",
    lg: "992px",
    xl: "1200px",
  },
  zIndex: {
    base: 1,
    menu: 2,
    overlay: 3,
    modal: 4,
    toast: 5,
  },
  elevation: {
    z0: "0 0 1px 0 hsl(0deg 0% 0% / 50%)",
    z1: "0 0 1px 0 black",
    z2: "0 0 6px 0 hsl(0deg 0% 0% / 10%)",
    z3: "0 0 20px 0 hsl(0deg 0% 0% / 10%)",
    z4: "0 0 1px 0 black",
  },
};
