import api from "../../../core/api";
import { useQuery } from "react-query";

const useGetTest = () => {
  const data = useQuery(["getTest"], () => api.get(`/ensayo/ensayos/`), {
    refetchOnWindowFocus: false,
  });

  return data;
};

export default useGetTest;
