import styled from "styled-components";
import { Input } from "antd";
import { theme } from "../../styles/theme";

export const CustomInput = styled(Input)`
  width: ${({ width }) => width || "300px"};
  height: ${({ height }) => height || "40px"};
  border: 1px solid ${theme.colors.light2};
  border-radius: ${({ radius }) => radius || "8px"};
  padding: 0 16px;
  opacity: 1;
  background: ${theme.colors.white};

  && {
    &.ant-input,
    &.ant-input-affix-wrapper {
      color: ${theme.colors.grey};
      size: 16px;

      &::placeholder {
        color: ${theme.colors.gray2};
      }

      &:focus {
        outline: 0;
        border: 1px solid ${theme.colors.light2} !important;
        box-shadow: initial;
      }

      &:hover {
        border: 1px solid ${theme.colors.light2} !important;
        box-shadow: initial;
      }
    }

    &.ant-input-affix-wrapper {
      &-focused {
        outline: 0;
        border: 1px solid ${theme.colors.white} !important;
        box-shadow: initial;
      }

      input {
        &::placeholder {
          color: ${theme.colors.gray2};
        }
      }
    }
  }
`;
