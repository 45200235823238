import styled from "styled-components";
import { Table } from "antd";
import { theme } from "../../styles/theme";

export const Wrapper = styled.div`
  width: 100% !important;
`;

export const StyledTable = styled(Table)`
  &&& {
    .ant-table {
      overflow: auto;

      &-column-sorters {
        height: 20px;
        display: contents;
      }

      &-thead {
        th {
          padding: 10px 16px;
          color: ${theme.colors.black};
          font-size: ${theme.fonts.size.sm};
          font-weight: ${theme.fonts.weight.medium};
          background-color: ${theme.colors.light2};
          border: 0;

          &:first-child {
            border-top-left-radius: 8px;
          }

          &:last-child {
            border-top-right-radius: 8px;
          }
        }
      }

      &-tbody {
        td {
          padding: 14px 16px;
          border-color: ${theme.colors.gray};
          color: ${theme.colors.black};
          font-size: ${theme.fonts.size.sm};
          font-weight: ${theme.fonts.weight.regular};
        }
      }

      &-column-title {
        flex: auto;
        padding: 16px 0;
      }

      // Filter
      &-filter {
        &-column {
          margin: -16px 0;
          &-title {
            padding-left: 0px;
          }
        }

        &-trigger {
          cursor: pointer;
          display: flex;
          justify-content: flex-end;
          transform: translateX(-12px);

          &.active {
            color: ${theme.colors.orange};
          }

          &:hover {
            background-color: transparent;
          }

          i {
            display: flex;
            align-items: center;
          }

          &-container {
            &-open,
            &:hover {
              background: transparent;
            }
          }
        }
      }

      &-tbody {
        // Selected row
        & > .ant-table-row-selected td {
          background-color: ${theme.colors.gray};
        }
      }
    }

    /* ### PAGINATION ### */
    .ant-pagination {
      gap: 4px;
      align-items: center;
      margin-top: 32px;

      &-item,
      &-prev,
      &-next {
        min-width: 40px;
        height: 40px;
        margin: 0;
      }

      /* ### PAGINATION - ITEM ### */

      &-item {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0;
        border-radius: 8px;

        a {
          color: ${theme.colors.orange};
          font-size: ${theme.fonts.size.sm};
          font-weight: ${theme.fonts.weight.regular};
        }

        &:hover {
          background-color: ${theme.colors.light};

          a {
            color: ${theme.colors.orange};
          }
        }

        &-active {
          background-color: ${theme.colors.orange};

          a {
            color: ${theme.colors.white};
          }

          &:hover {
            background-color: ${theme.colors.orangeHover};

            a {
              color: ${theme.colors.white};
            }
          }
        }
      }

      /* ### PAGINATION - PREV & NEXT ### */

      &-prev,
      &-next {
        .anticon {
          color: ${theme.colors.dark};
          font-family: "remixicon";

          svg {
            display: none;
          }

          &:after {
            font-style: normal;
            font-size: 24px;
            font-weight: 400;
            line-height: 1;
          }

          &:hover {
            color: ${theme.colors.dark};
          }
        }
      }

      &-prev {
        .anticon {
          &:after {
            content: "\\ea64";
          }
        }
      }

      &-next {
        .anticon {
          &:after {
            content: "\\ea6e";
          }
        }
      }

      &-item-link {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0;
        border-radius: 8px;
      }

      /* ### PAGINATION - DISABLED ### */
      &-disabled {
        .anticon,
        .anticon:hover {
          color: ${theme.colors.gray};
        }
      }

      /* ### PAGINATION - ITEMS PER PAGE */
      &-options {
        order: 1;
        margin-right: auto;
        margin-left: 0;

        .ant-select {
          cursor: default;
          display: flex;
          align-items: center;

          &:before {
            content: "${(props) => props.sizerLabels[0]}";
            margin-right: 12px;
          }

          &:after {
            content: "${(props) => props.sizerLabels[1]}";
            margin-left: -8px;
          }

          &:before,
          &:after {
            display: inline-block;
            color: ${theme.colors.gray2};
            font-size: ${theme.fonts.size.sm};
          }

          &-selector {
            cursor: pointer;
            flex: 1;
            border-color: ${theme.colors.gray2};
            border-radius: 50px;

            &:focus-within {
              border-color: ${theme.colors.orange};
              box-shadow: none;
            }
          }

          &.ant-select-open {
            .ant-select-arrow {
              .anticon {
                transform: rotate(180deg);
              }
            }
          }

          &-arrow {
            position: relative;
            margin-top: 0;
            right: 28px;
            width: initial;
            height: initial;
            top: 0;

            .anticon {
              color: ${theme.colors.orange};
              font-family: "remixicon";
              font-size: 21px;
              line-height: 1;

              &:after {
                content: "\\ea4e";
              }

              svg {
                display: none;
              }
            }
          }

          &-selection-item {
            color: ${theme.colors.black};
            font-size: ${theme.fonts.size.sm};
          }

          &-dropdown {
            min-width: 80px !important;
          }
        }
      }

      &-prev {
        order: 2;
      }
      &-jump-prev {
        order: 3;
      }
      &-item {
        order: 3;
      }
      &-jump-next {
        order: 3;
      }
      &-next {
        order: 4;
      }
    }
  }
`;
