import api from "../../../core/api";
import { useQuery } from "react-query";

const useGetUsers = () => {
  const data = useQuery(["useGetUsers"], () => api.get(`/usuario/usuarios`));

  return data;
};

export default useGetUsers;
