import React from "react";
import { Flex, Text } from "../../styles/common";
import { CustomSelect } from "./styles";
import { theme } from "../../styles/theme";
import { Tooltip } from "antd";
import info from "../../assets/icons/info.svg";

export const Select = ({
  options,
  defaultValue,
  placeholder,
  helper,
  noborder,
  paddingx,
  islangselector,
  showSearch,
  notFoundContent,
  filterOption,
  optionFilterProp,
  optionLabelProp,
  error,
  disabled,
  onChange,
  onSearch,
  height,
  label,
  colorLabel,
  sizeLabel,
  weightLabel,
  tooltip,
  ...rest
}) => {
  return (
    <div style={{ alignSelf: "start" }}>
      {label && (
        <Flex align="center" gap="10px" m="0 0 8px 0">
          <Text
            size={sizeLabel || theme.fonts.size.sm}
            color={colorLabel || theme.colors.dark}
            weight={weightLabel || theme.fonts.weight.medium}
            style={{ textAlign: "start" }}
          >
            {label}
          </Text>
          {tooltip && (
            <Tooltip title={`${tooltip}`} color={theme.colors.gray}>
              <img alt="info" src={info} height={"16px"} />
            </Tooltip>
          )}
        </Flex>
      )}
      <CustomSelect
        options={options}
        status={error ? "error" : ""}
        defaultValue={defaultValue}
        placeholder={placeholder}
        noborder={noborder}
        paddingx={paddingx}
        islangselector={islangselector ? 1 : 0}
        showSearch={showSearch}
        notFoundContent={notFoundContent}
        filterOption={filterOption}
        optionFilterProp={optionFilterProp}
        optionLabelProp={optionLabelProp}
        disabled={disabled ? 1 : 0}
        onChange={onChange}
        onSearch={onSearch}
        height={height}
        {...rest}
      />
      {helper && (
        <Text
          size={theme.fonts.size.sm}
          color={theme.colors.red}
          mt="2px"
          style={{ textAlign: "-webkit-left" }}
        >
          {helper}
        </Text>
      )}
    </div>
  );
};
