import api from "../../../core/api";
import { useQuery } from "react-query";
import { getUser } from "../../../utilities/helpers";

const useGetOperationalAssistants = () => {
  const data = useQuery(
    ["getOperationalAssistants"],
    () => api.get(`/ensayo/auxiliar_operativo`),
    { enabled: getUser().permissions.includes(14) }
  );

  return data;
};

export default useGetOperationalAssistants;
