import api from "../../../core/api";
import { useQuery } from "react-query";

const useGetSamples = (id, step) => {
  const data = useQuery(
    ["getSamples", id, step],
    () => api.get(`/muestra/muestras_json/?id_orden=${id}`),
    { enabled: step === 0 && id !== null }
  );

  return data;
};

export default useGetSamples;

// import api from "../../../core/api";
// import { useQuery } from "react-query";

// const useGetSamples = (id, step) => {
//   const data = useQuery(
//     ["getSamplesUpdate", id, step],
//     () => api.get(`/muestra/muestras_id/?id_orden=${id}`),
//     { enabled: step === 0 && id !== null }
//   );

//   return data;
// };

// export default useGetSamples;
