import React from "react";
import PropTypes from "prop-types";

import Skeleton from "../../Skeleton";
import { Flex } from "../../../styles/common";

const TableSkeleton = ({ columns }) => {
  const numberOfColumns = columns || 8;

  return (
    <Flex gap={16}>
      {[...Array(numberOfColumns)].map((v, idx) => (
        <Skeleton
          key={idx}
          title={false}
          loading
          paragraph={{
            rows: 10,
            width: "100%",
          }}
        />
      ))}
    </Flex>
  );
};

TableSkeleton.propTypes = {
  columns: PropTypes.number,
};

export default TableSkeleton;
