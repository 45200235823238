import { useMutation } from "react-query";
import api from "../../../core/api";

const useDeleteRegulations = () => {
  const mutation = useMutation((id) => {
    return api.delete(`/muestra/normativas/${id}`);
  });
  return mutation;
};

export default useDeleteRegulations;
