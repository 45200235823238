import React from "react";
import ReactDOM from "react-dom";
import "antd/dist/reset.css";
import { Provider } from "react-redux";
import { ThemeProvider } from "styled-components";
import { AppRouter } from "./pages/App.router";
import { theme } from "./styles/theme";
import { QueryClient, QueryClientProvider } from "react-query";
import { Toaster } from "react-hot-toast";
import { store } from "./core/store";

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <Toaster
          position="top-right"
          reverseOrder={false}
          containerStyle={{ zIndex: 999999 }}
          toastOptions={{
            success: {
              iconTheme: {
                primary: "#2f5851",
                secondary: "#fff",
              },
            },
            error: {
              iconTheme: {
                primary: "#ce2828",
                secondary: "#fff",
              },
            },
            style: { minWidth: "400px", zIndex: 999999 },
          }}
        />
        <ThemeProvider theme={theme}>
          <AppRouter />
        </ThemeProvider>
      </Provider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
