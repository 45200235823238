import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import arrowBack from "../../../assets/icons/arrow-back.svg";
import { useNavigate } from "react-router";
import { Link } from "../../../components/Link";
import useGetDataReport from "../hooks/useGetDataReport";
import { Container, Flex } from "../../../styles/common";
import Modal from "../../../components/Modal";
import { Button } from "../../../components/Button";
import { theme } from "../../../styles/theme";
import { TextArea } from "../../../components/TextArea";
import toast from "react-hot-toast";
import useSaveApprove from "../hooks/useSaveApprove";
import { PDFViewer, pdf } from "@react-pdf/renderer";
import { Pdf } from "./Pdf";
import { getUser } from "../../../utilities/helpers";

export const Approve = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const getDataReport = useGetDataReport(id);
  const { data, isSuccess } = getDataReport;
  const [openModal, setOpenModal] = useState(false);
  const [error, setError] = useState(false);
  const [rejectMessage, setRejectMessage] = useState("");
  const [forceUpdate, setForceUpdate] = useState(false);
  const update = useSaveApprove();
  const [dateTimeNow] = useState(new Date());
  const [reportData, setReportData] = useState(null);

  const opcionesDeFormato = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: false,
  };

  const dateTimeNowFormatted = dateTimeNow.toLocaleDateString(
    "es-ES",
    opcionesDeFormato
  );

  const handleBackPage = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (isSuccess) {
      setReportData(data.data);
    }
  }, [isSuccess, data]);

  const BlobToBase64Converter = ({ blobPdf }) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = function (event) {
        const base64String = event.target.result.split(",")[1];
        resolve(base64String);
      };
      reader.onerror = function (event) {
        reject(event.target.error);
      };
      reader.readAsDataURL(blobPdf);
    });
  };

  const getBlob = async () => {
    try {
      let blobPdf = await pdf(
        Pdf({ reportData, dateTimeNowFormatted })
      ).toBlob();
      let base64Report = await BlobToBase64Converter({ blobPdf });
      return base64Report;
    } catch (error) {
      console.error("Error converting blob to base64:", error);
      throw error;
    }
  };

  const handleUpdate = async (accept, reject) => {
    try {
      let base64Report = await getBlob();
      if (reject && rejectMessage === "") {
        setError(true);
        setForceUpdate(!forceUpdate);
      } else {
        update.reset();
        await update.mutate(
          {
            muestra: id,
            informe_pdf: base64Report,
            aprobar: accept,
            rechazar: reject,
            observacion: rejectMessage,
          },
          {
            onSuccess: () => {
              toast.success(
                `Informe ${accept ? "Aceptado" : "Rechazado"} con éxito`
              );
              navigate("/test/pending-reports");
            },
          }
        );
      }
    } catch (error) {
      console.error("Error updating:", error);
    }
  };

  const handleClose = () => {
    setOpenModal(false);
    setRejectMessage("");
    setError(false);
    setForceUpdate(!forceUpdate);
  };

  const handleChangeReject = (e) => {
    const { value } = e.target;
    setRejectMessage(value);
    setError(false);
    setForceUpdate(!forceUpdate);
  };

  return (
    <>
      <Link
        style={{
          marginTop: "10px",
          marginBottom: "10px",
        }}
        onClick={handleBackPage}
        icon={<img alt="back" src={arrowBack} />}
      >
        Atrás
      </Link>
      {reportData ? (
        <>
          <Modal
            align="center"
            isOpen={openModal}
            onClose={handleClose}
            padding={36}
            style={{ width: "100px" }}
          >
            <Modal.Header
              padding="0 0 16px 0"
              title="Rechazar ensayo"
              size={theme.fonts.size.h5}
            />
            <Modal.Body margin="16px 0 24px 0" minHeight={600}>
              <TextArea
                value={rejectMessage}
                label={"Motivo de rechazo*"}
                placeholder={"Ingrese el motivo del rechazo de la orden"}
                onChange={handleChangeReject}
                width={"100%"}
                error={error}
                helper={error ? "Campo requerido" : ""}
              />
            </Modal.Body>
            <Modal.Footer gap={"16px"} justify="center">
              <Button
                background={theme.colors.white}
                hover={theme.colors.light3}
                color={theme.colors.dark}
                onClick={handleClose}
                style={{ width: "150px" }}
              >
                Cancelar
              </Button>
              <Button
                background={theme.colors.orange}
                hover={theme.colors.orangeHover}
                onClick={() => handleUpdate(false, true)}
              >
                Rechazar
              </Button>
            </Modal.Footer>
          </Modal>
          <Container style={{ padding: "0px" }}>
            <PDFViewer style={{ width: "100%", height: "75vh" }}>
              <Pdf
                reportData={reportData}
                dateTimeNowFormatted={dateTimeNowFormatted}
              />
            </PDFViewer>
          </Container>
          <Flex gap="16px" justify="center" m="30px">
            {getUser().permissions?.includes(21) && (
              <Button
                background={theme.colors.orange}
                hover={theme.colors.orangeHover}
                onClick={() => handleUpdate(true, false)}
                style={{ width: "150px" }}
              >
                Aprobar
              </Button>
            )}
            {getUser().permissions?.includes(22) && (
              <Button
                background={theme.colors.white}
                hover={theme.colors.light3}
                color={theme.colors.dark}
                onClick={() => setOpenModal(true)}
                style={{ width: "150px" }}
              >
                Rechazar
              </Button>
            )}
          </Flex>
        </>
      ) : (
        <div>Cargando datos...</div>
      )}
    </>
  );
};
