import api from "../../../core/api";
import { useQuery } from "react-query";

const useGetTypeOfContact = () => {
  const data = useQuery(["useGetTypeOfContact"], () =>
    api.get(`/muestra/tipo_numero_contacto`)
  );

  return data;
};

export default useGetTypeOfContact;
