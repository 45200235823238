import api from "../../../core/api";
import { useMutation } from "react-query";

const useCreateProviders = () => {
  const mutation = useMutation((formData) => {
    return api.post("/muestra/proveedores/", formData);
  });
  return mutation;
};
export default useCreateProviders;
