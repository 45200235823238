import { useMutation } from "react-query";
import api from "../../../core/api";

const useDeleteUsers = () => {
  const mutation = useMutation((id) => {
    return api.delete(`/usuario/usuarios/${id}`);
  });
  return mutation;
};

export default useDeleteUsers;
