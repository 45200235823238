import React from "react";
import { CustomTextArea } from "./styles";
import { Text } from "../../styles/common";
import { theme } from "../../styles/theme";

export const TextArea = ({
  placeholder,
  suffix,
  helper,
  error,
  disabled,
  onChange,
  onPressEnter,
  width,
  label,
  colorLabel,
  sizeLabel,
  weightLabel,
  ...rest
}) => {
  return (
    <div>
      {label && (
        <Text
          size={sizeLabel || theme.fonts.size.sm}
          color={colorLabel || theme.colors.dark}
          weight={weightLabel || theme.fonts.weight.medium}
          mb="8px"
          style={{ textAlign: "start" }}
        >
          {label}
        </Text>
      )}
      <CustomTextArea
        placeholder={placeholder}
        suffix={suffix}
        status={error ? "error" : ""}
        disabled={disabled}
        onChange={onChange}
        onPressEnter={onPressEnter}
        width={width}
        autoComplete="off"
        {...rest}
      />
      {helper && (
        <Text
          size={theme.fonts.size.sm}
          color={theme.colors.red}
          mt="2px"
          style={{ textAlign: "-webkit-left" }}
        >
          {helper}
        </Text>
      )}
    </div>
  );
};
