import api from "../../../core/api";
import { useQuery } from "react-query";

const useGetClients = () => {
  const data = useQuery(["getClients"], () =>
    api.get(`/orden_servicio/proyectosCC/`)
  );

  return data;
};

export default useGetClients;
