import api from "../../../core/api";
import { useQuery } from "react-query";

const useGetSpecifications = (step) => {
  const data = useQuery(
    ["getSpecifications", step],
    () => api.get(`/muestra/especificaciones/`),
    { enabled: step === 0, refetchOnWindowFocus: false }
  );

  return data;
};

export default useGetSpecifications;
