import { useMutation } from "react-query";
import api from "../../../core/api";

const useCreateUser = () => {
  const mutation = useMutation((formData) => {
    return api.post("/ensayo/auxiliar_operativo/", formData);
  });
  return mutation;
};
export default useCreateUser;
