/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { WrapperForm } from "../styles";
import Table from "../../../components/Table";
import { Flex } from "../../../styles/common";
import { Alert } from "antd";
import { Button } from "../../../components/Button";
import { theme } from "../../../styles/theme";
import deleteIcon from "../../../assets/icons/delete.svg";
import editIcon from "../../../assets/icons/edit.svg";
import { useLocation } from "react-router-dom";

export const Step3 = ({
  isLoading,
  handleSave,
  errorMessage,
  setErrorMessage,
  samplesArray,
  handleBack,
  setSamplesArray,
  handleEdit,
  isLoadingUpdate,
  handleUpdate,
  noSamples,
}) => {
  const location = useLocation();
  const { search } = location;
  const searchParams = new URLSearchParams(search);
  const id = searchParams.get("id");
  const { common } = useSelector((state) => state);
  const { specificationsList, specimensList, providersList } = common;
  const [totalItems, setTotalItems] = useState(3);
  const [pages, setPages] = useState({ current: 1, pageSize: 3 });
  const [dataTable, setDataTable] = useState([]);
  const [forceUpdate, setForceUpdate] = useState(false);

  const columns = [
    {
      title: "Especificaciones",
      dataIndex: "especificaciones",
      key: "especificaciones",
    },
    {
      title: "Especimen",
      dataIndex: "especimen",
      key: "especimen",
    },
    {
      title: "Proveedor",
      dataIndex: "proveedor",
      key: "proveedor",
    },
    {
      title: "Observación",
      dataIndex: "observacion",
      key: "observacion",
    },
    {
      title: "Cantidad especimen",
      dataIndex: "cantidad",
      key: "cantidad",
    },
    {
      title: "Acciones",
      dataIndex: "acciones",
      key: "acciones",
      render: (actions) => (
        <Flex gap={16} justify={"space-around"}>
          {actions.map((action, i) => (
            <Button
              icon={
                <img alt={`${action.icon}`} src={action.icon} width={"24px"} />
              }
              onClick={() => action.onClick()}
              style={{
                background: "transparent",
                border: "none",
                boxShadow: "none",
              }}
            />
          ))}
        </Flex>
      ),
    },
  ];

  const handleDelete = (index) => {
    const newArray = samplesArray.filter((ele, indx) => indx !== index);
    setSamplesArray(newArray);
    setForceUpdate(!forceUpdate);
  };

  useEffect(() => {
    if (samplesArray?.length > 0) {
      const dataTable = samplesArray.map((ele, i) => ({
        key: i,
        especificaciones: specificationsList.find(
          (elem) => elem.value === ele.especificacion
        )?.label,
        especimen: specimensList.find((elem) => elem.value === ele.especimen)
          ?.label,
        proveedor: providersList.find((elem) => elem.value === ele.proveedor)
          ?.label,
        observacion: ele.observacion,
        cantidad: ele.cantidad_especimen,
        acciones: [
          {
            key: `1${i}`,
            icon: editIcon,
            onClick: () => handleEdit(i),
          },
          {
            key: `1${i}`,
            icon: deleteIcon,
            onClick: () => handleDelete(i),
          },
        ],
      }));

      setDataTable(dataTable);
      setTotalItems(samplesArray.length);
    } else {
      setDataTable([]);
    }
  }, [samplesArray, specificationsList, specimensList, providersList]);

  const handleChangeTable = (pagination) => {
    setTotalItems(pagination.total);
    setPages({ current: pagination.current, pageSize: pagination.pageSize });
  };

  const handleClose = () => {
    setErrorMessage("");
  };

  return (
    <>
      <WrapperForm>
        <Table
          columns={columns}
          data={dataTable}
          sizerLabels={["Mostrando", "por página"]}
          pagination={{
            ...pages,
            total: totalItems,
            showSizeChanger: true,
            pageSizeOptions: ["3", "6", "18"],
            locale: {
              items_per_page: "",
            },
          }}
          onChange={handleChangeTable}
          locale={{
            emptyText: "No hay datos",
          }}
          style={{ paddingTop: "40px" }}
        />
      </WrapperForm>
      {errorMessage !== "" && (
        <Flex m="30px 0 0 0" justify="flex-end">
          <Alert
            closable
            message={`${errorMessage}`}
            type="error"
            onClose={handleClose}
          />
        </Flex>
      )}
      <Flex
        m={errorMessage !== "" ? "10px 0 0 0" : "60px 0 0 0"}
        gap="16px"
        justify="flex-end"
      >
        {(!id || (id && noSamples)) && (
          <Button
            background={theme.colors.light2}
            hover={theme.colors.gray}
            color={theme.colors.dark}
            onClick={handleBack}
          >
            Añadir otra muestra
          </Button>
        )}
        <Button
          background={theme.colors.orange}
          hover={theme.colors.orangeHover}
          loading={isLoading || isLoadingUpdate}
          onClick={id && !noSamples ? handleUpdate : handleSave}
          style={{ width: "150px" }}
        >
          Guardar
        </Button>
      </Flex>
    </>
  );
};
