import styled from "styled-components";
import { theme } from "../theme";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    background: ${theme.colors.white};
    height: calc(100vh - 70px);
  }
`;

export const Flex = styled.div`
  display: flex;
  flex: ${({ flex }) => flex || null};
  align-items: ${({ align }) => align || null};
  justify-content: ${({ justify }) => justify || null};
  gap: ${({ gap }) => gap || null};
  margin: ${({ m }) => m || null};
  padding: ${({ p }) => p || null};
  width: ${({ width }) => width || null};
  height: ${({ height }) => height || null};
  min-width: ${({ minwidth }) => minwidth || null};
  min-height: ${({ minheight }) => minheight || null};
  max-width: ${({ maxwidth }) => maxwidth || null};
  max-height: ${({ maxheight }) => maxheight || null};
  flex-direction: ${({ direction }) => direction || null};
`;

export const Text = styled.div`
  color: ${(props) => props.color || theme.colors.dark};
  font-size: ${(props) => props.size || theme.fonts.size.default};
  line-height: ${(props) => props.line};
  font-weight: ${(props) => props.weight || 400};
  text-align: ${(props) => props.align || "left"};
  margin-top: ${(props) => props.mt};
  margin-bottom: ${(props) => props.mb};
`;
