import { useMutation } from "react-query";
import api from "../../../core/api";

const useUpdateSamples = (id) => {
  const mutation = useMutation((formData) => {
    return api.put(`/muestra/muestras_json/${id}/`, formData);
  });
  return mutation;
};
export default useUpdateSamples;
