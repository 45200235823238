import React from "react";
import PropTypes from "prop-types";

import { Wrapper } from "./styles";

const ModalBody = ({
  margin,
  padding,
  maxHeight,
  align,
  children,
  ...rest
}) => {
  return (
    <Wrapper
      margin={margin}
      padding={padding}
      maxHeight={maxHeight}
      aling={align}
      {...rest}
    >
      {children}
    </Wrapper>
  );
};

ModalBody.propTypes = {
  margin: PropTypes.string,
  padding: PropTypes.string,
  maxHeight: PropTypes.number,
  align: PropTypes.oneOf(["left", "center", "right"]),
  children: PropTypes.node,
};

export default ModalBody;
