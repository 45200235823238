import React, { useEffect, useState } from "react";
import { Container, Flex, Text } from "../../styles/common";
import { theme } from "../../styles/theme";
import Table from "../../components/Table";
import Skeleton from "../../components/Skeleton";
import { useNavigate } from "react-router-dom";
import document from "../../assets/icons/document.svg";
import { Tooltip } from "antd";
import { Button } from "../../components/Button";
import useGetPendingSamples from "./hooks/useGetPendingSamples";

export const Reports = () => {
  const navigate = useNavigate();
  const ordersList = useGetPendingSamples();
  const { data, isSuccess, isLoading } = ordersList;
  const [totalItems, setTotalItems] = useState(7);
  const [pages, setPages] = useState({ current: 1, pageSize: 7 });
  const [dataTable, setDataTable] = useState([]);

  const handleApprove = (id) => {
    navigate(`/test/approve/${id}`);
  };

  useEffect(() => {
    if (isSuccess && data?.data.length > 0) {
      const newdataTable = data?.data.map((ele, i) => ({
        ...ele,
        acciones: [
          {
            key: `1${i}`,
            tooltip: "Aprobar muestra",
            icon: document,
            onClick: () => handleApprove(ele.id_muestra),
          },
        ],
      }));

      setDataTable(newdataTable);
      setTotalItems(newdataTable.length);
    } else {
      setDataTable([]);
    }
  }, [data, isSuccess]);

  const columns = [
    {
      title: "N° orden",
      dataIndex: "id_orden_servicio",
      key: "id_orden_servicio",
    },
    {
      title: "Código muestra laboratorio",
      dataIndex: "codigo_laboratorio",
      key: "codigo_laboratorio",
    },
    {
      title: "Línea de trabajo",
      dataIndex: "linea_trabajo",
      key: "linea_trabajo",
    },
    {
      title: "Cliente",
      dataIndex: "cliente",
      key: "cliente",
    },
    {
      title: "Acciones",
      dataIndex: "acciones",
      key: "acciones",
      render: (actions) => (
        <Flex gap={16} justify={"space-around"}>
          {actions.map((action, i) => (
            <Tooltip title={action.tooltip} color={theme.colors.orange}>
              <Button
                icon={
                  <img
                    alt={`${action.icon}`}
                    src={action.icon}
                    width={"24px"}
                  />
                }
                onClick={() => action.onClick()}
                disabled={action.disabled}
                style={{
                  background: "transparent",
                  border: "none",
                  boxShadow: "none",
                }}
              />
            </Tooltip>
          ))}
        </Flex>
      ),
    },
  ];

  const handleChangeTable = (pagination) => {
    setTotalItems(pagination.total);
    setPages({ current: pagination.current, pageSize: pagination.pageSize });
  };

  return (
    <Container>
      <Text
        size={theme.fonts.size.h4}
        weight={theme.fonts.weight.bold}
        color={theme.colors.dark}
        style={{ textAlign: "left" }}
        mb="40px"
      >
        Informes de muestras pendientes de aprobación por parte del
        coordinador/director.
      </Text>
      <Table
        columns={columns}
        data={dataTable}
        sizerLabels={["Mostrando", "por página"]}
        pagination={{
          ...pages,
          total: totalItems,
          showSizeChanger: true,
          pageSizeOptions: ["7", "45", "70"],
          locale: {
            items_per_page: "",
          },
        }}
        onChange={handleChangeTable}
        locale={{
          emptyText: isLoading ? (
            <Flex justify="center" style={{ gap: 16 }}>
              {[...Array(9)].map((v, idx) => (
                <Skeleton
                  title={false}
                  paragraph={{
                    requests: 10,
                    width: "100%",
                  }}
                  loading
                  key={idx}
                />
              ))}
            </Flex>
          ) : (
            "No hay datos"
          ),
        }}
        style={{ width: "100%" }}
      />
    </Container>
  );
};
