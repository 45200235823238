import api from "../../../core/api";
import { useMutation } from "react-query";

const useCreateRegulations = () => {
  const mutation = useMutation((formData) => {
    return api.post("/muestra/normativas/", formData);
  });
  return mutation;
};
export default useCreateRegulations;
