import React, { useEffect, useState } from "react";
import useGetRegulations from "./hooks/useGetRegulations";
import useDeleteRegulations from "./hooks/useDeleteRegulations";
import useCreateRegulations from "./hooks/useCreateRegulations";
import useUpdateRegulations from "./hooks/useUpdateRegulations";
import { Container, Flex, Text } from "../../styles/common";
import Table from "../../components/Table";
import { Button } from "../../components/Button";
import { theme } from "../../styles/theme";
import Skeleton from "../../components/Skeleton";
import deleteIcon from "../../assets/icons/delete.svg";
import editIcon from "../../assets/icons/edit.svg";
import toast from "react-hot-toast";
import { Tooltip } from "antd";
import { useForm } from "react-hook-form";
import { ModalRegulations } from "./components/ModalRegulations";
import useGetTest from "../ServiceOrder/hook/useGetTests";

export const Regulations = () => {
  const [pages, setPages] = useState({ current: 1, pageSize: 7 });
  const [totalItems, setTotalItems] = useState(7);
  const [dataTable, setDataTable] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const createRegulations = useCreateRegulations();
  const { data, isLoading, isSuccess, refetch } = useGetRegulations();
  const { data: testData, isSuccess: isSuccessTest } = useGetTest();
  const deleteRegulations = useDeleteRegulations();
  const [editingRegulations, setEditingRegulations] = useState("");
  const [testOptions, setTestOptions] = useState([]);
  const updateRegulations = useUpdateRegulations(editingRegulations.id);

  const defaultValues = {
    nombre: "",
    linea_trabajo: "Línea de concretos",
    descripcion: "",
    ensayo: "",
  };

  const { control, watch, reset, formState } = useForm({
    mode: "onTouched",
    defaultValues,
  });

  const handleUpdate = (id) => {
    setOpenModal(true);
    setIsEditing(true);
    const regulationsToUpdate =
      data && data.data.find((spec) => spec.id === id);
    const ensayoArray =
      regulationsToUpdate.ensayo && regulationsToUpdate.ensayo.split(";");
    if (regulationsToUpdate) {
      setEditingRegulations(regulationsToUpdate);
      reset({
        nombre: regulationsToUpdate.nombre,
        linea_trabajo: regulationsToUpdate.linea_trabajo,
        descripcion: regulationsToUpdate.descripcion,
        ensayo: ensayoArray,
      });
    } else {
      reset(defaultValues);
    }
  };

  const handleDelete = (id) => {
    deleteRegulations.reset();
    deleteRegulations.mutate(id, {
      onSuccess: () => {
        toast.success(`Normativa eliminada con éxito`);
        refetch();
      },
    });
  };

  const handleChangeTable = (pagination) => {
    setTotalItems(pagination.total);
    setPages({ current: pagination.current, pageSize: pagination.pageSize });
  };

  const handleClose = () => {
    setOpenModal(false);
    reset();
  };

  const handleCreate = () => {
    setOpenModal(true);
    setIsEditing(false);
    reset(defaultValues);
  };

  useEffect(() => {
    if (
      isSuccess &&
      data?.data.length > 0 &&
      isSuccessTest &&
      testData?.data.length > 0
    ) {
      const dataTable = data?.data.map((ele, i) => {
        const ensayoCodes = ele.ensayo ? ele.ensayo.split(";") : [];
        const matchingTests = testData.data.filter((test) =>
          ensayoCodes.includes(test.codigo)
        );
        const ensayoValues = matchingTests.map(
          (matchingTest) => matchingTest.nombre
        );
        return {
          ...ele,
          ensayoValue:
            ensayoValues.length > 0
              ? `- ${ensayoValues.join("\n- ")}`
              : ensayoValues.join("\n"),
          acciones: [
            {
              key: `1${i}`,
              tooltip: "Editar",
              icon: editIcon,
              onClick: () => handleUpdate(ele.id),
              disabled: false,
            },
            {
              key: `1${i}`,
              tooltip: "Eliminar",
              icon: deleteIcon,
              onClick: () => handleDelete(ele.id),
              disabled: false,
            },
          ],
        };
      });

      setDataTable(dataTable);
      setTotalItems(dataTable.length);
    } else {
      setDataTable([]);
    }
  }, [data, isSuccess, testData, isSuccessTest]);

  const onSubmit = (payload) => {
    if (isEditing && editingRegulations) {
      updateRegulations.mutate(
        {
          ...payload,
        },
        {
          onSuccess: () => {
            toast.success("Normativa actualizada con éxito");
            setOpenModal(false);
            reset();
            refetch();
          },
        }
      );
    } else {
      createRegulations.mutate(payload, {
        onSuccess: () => {
          toast.success("Normativa creada con éxito");
          setOpenModal(false);
          reset();
          refetch();
        },
      });
    }
  };

  const columns = [
    {
      title: "Id de normativa",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "nombre",
    },
    {
      title: "Línea de trabajo",
      dataIndex: "linea_trabajo",
      key: "linea_trabajo",
    },
    {
      title: "Descripción",
      dataIndex: "descripcion",
      key: "descripcion",
    },
    {
      title: "Tipos de ensayos",
      dataIndex: "ensayoValue",
      key: "ensayoValue",
      render: (text) => <span style={{ whiteSpace: "pre-line" }}>{text}</span>,
    },
    {
      title: "Acciones",
      dataIndex: "acciones",
      key: "acciones",
      render: (actions) => (
        <Flex gap={16} justify={"space-around"}>
          {actions.map((action, i) => (
            <Tooltip title={action.tooltip} color={theme.colors.orange}>
              <Button
                icon={
                  <img
                    alt={`${action.icon}`}
                    src={action.icon}
                    width={"24px"}
                  />
                }
                onClick={() => action.onClick()}
                disabled={action.disabled}
                style={{
                  background: "transparent",
                  border: "none",
                  boxShadow: "none",
                }}
              />
            </Tooltip>
          ))}
        </Flex>
      ),
    },
  ];

  useEffect(() => {
    if (isSuccessTest) {
      setTestOptions(
        testData?.data
          .filter((ele) => ele.linea_trabajo === "Línea de concretos")
          .map((ele) => ({
            label: ele.nombre,
            value: ele.codigo,
          }))
      );
    }
  }, [isSuccessTest]);

  return (
    <Container>
      <ModalRegulations
        openModal={openModal}
        handleClose={handleClose}
        onSubmit={onSubmit}
        formState={formState}
        control={control}
        watch={watch}
        isLoading={createRegulations.isLoading}
        isEditing={isEditing}
        testOptions={testOptions}
      />
      <Flex justify="space-between">
        <Text
          size={theme.fonts.size.h4}
          weight={theme.fonts.weight.bold}
          color={theme.colors.dark}
          style={{ textAlign: "left" }}
          mb="40px"
        >
          Normativas
        </Text>
        <Button
          background={theme.colors.orange}
          hover={theme.colors.orangeHover}
          onClick={handleCreate}
        >
          Crear normativa
        </Button>
      </Flex>
      <Table
        columns={columns}
        data={dataTable}
        sizerLabels={["Mostrando", "por página"]}
        pagination={{
          ...pages,
          total: totalItems,
          showSizeChanger: true,
          pageSizeOptions: ["7", "45", "70"],
          locale: {
            items_per_page: "",
          },
        }}
        onChange={handleChangeTable}
        locale={{
          emptyText: isLoading ? (
            <Flex justify="center" style={{ gap: 16 }}>
              {[...Array(9)].map((v, idx) => (
                <Skeleton
                  title={false}
                  paragraph={{
                    requests: 10,
                    width: "100%",
                  }}
                  loading
                  key={idx}
                />
              ))}
            </Flex>
          ) : (
            "No hay datos"
          ),
        }}
      />
    </Container>
  );
};
