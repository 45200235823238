import api from "../../../core/api";
import { useQuery } from "react-query";

const useGetSamples = (id) => {
  const data = useQuery(["getSamples", id], () =>
    api.get(`/muestra/muestras_json/?id_orden=${id}`)
  );

  return data;
};

export default useGetSamples;
