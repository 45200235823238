import api from "../../../core/api";
import { useQuery } from "react-query";

const useGetGeneralBinnacle = (id) => {
  const data = useQuery(
    ["getGeneralBinnacle", id],
    () => api.get(`/orden_servicio/bitacora_cliente_proyecto/?id_orden=${id}`),
    { enabled: !!id }
  );

  return data;
};

export default useGetGeneralBinnacle;
