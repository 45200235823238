import React from "react";
import PropTypes from "prop-types";

import { StyledAntModal } from "./styles";
import close from "../../assets/icons/close.svg";

import ModalHeader from "./Header";
import ModalBody from "./Body";
import ModalFooter from "./Footer";

const Modal = ({
  width,
  padding,
  align,
  isOpen,
  onClose,
  children,
  ...rest
}) => {
  return (
    <StyledAntModal
      width={width}
      padding={padding}
      align={align}
      open={isOpen}
      onCancel={onClose}
      centered
      closeIcon={onClose ? <img alt="close" src={close} /> : null}
      footer={null}
      {...rest}
    >
      {children}
    </StyledAntModal>
  );
};

Modal.propTypes = {
  width: PropTypes.number,
  padding: PropTypes.number,
  align: PropTypes.oneOf(["left", "center", "right"]),
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node.isRequired,
};

// Set subcomponents
Modal.Header = ModalHeader;
Modal.Body = ModalBody;
Modal.Footer = ModalFooter;

export default Modal;
