import { Button } from "../components/Button";
import { getToken } from "../core/api";
import { Flex } from "../styles/common";
import jwtDecode from "jwt-decode";
import { Tooltip } from "antd";
import { theme } from "../styles/theme";

export const getUser = () => {
  const client = getToken();
  const decoded = client && jwtDecode(client);
  const dataClient = decoded;

  const infoUser = dataClient
    ? {
        name: dataClient.nombres,
        lastName: dataClient.apellidos,
        email: dataClient.email,
        id: dataClient.id,
        role: dataClient.rol || "hello",
        permissions: dataClient.permisos,
      }
    : {
        name: "",
        lastname: "",
        email: "",
        id: "",
        role: "",
        permissions: [],
      };

  return infoUser;
};

export const formatHour = (value) => {
  const d = new Date(value);
  const hour = d.getHours();
  const minute = d.getMinutes();

  const formattedHour = String(hour).padStart(2, "0");
  const formattedMinute = String(minute).padStart(2, "0");

  const timeHHmm = `${formattedHour}:${formattedMinute}`;

  return timeHHmm;
};

export const dateFormatHour = (value) => {
  const d = new Date(value);
  const t = d.toLocaleTimeString("en-CO");
  let month = d.getMonth() + 1;
  let day = d.getDate();
  const year = d.getFullYear();

  if (month.toString().length < 2) month = `0${month}`;
  if (day.toString().length < 2) day = `0${day}`;

  return `${[day, month, year].join("/")} ${t}`;
};

export const statusName = (status) => {
  switch (status) {
    case "pendiente_diligenciar":
      return "Pendiente por diligenciar";
    case "VoBo_laboratorio":
      return "Enviado al laboratorio";
    case "pendiente ensayos":
      return "Pendiente por ensayos";
    default:
      return status;
  }
};

export const concreteColumns = [
  {
    title: "Ensayo",
    dataIndex: "ensayo",
    key: "ensayo",
    width: "45%",
  },
  {
    title: "Normativa",
    dataIndex: "normativa",
    key: "normativa",
    width: "10%",
  },
  {
    title: "Cantidad",
    dataIndex: "cantidad_fallo",
    key: "cantidad_fallo",
    width: "10%",
  },
  {
    title: "Edad de falla",
    dataIndex: "edad_fallo",
    key: "edad_fallo",
    width: "10%",
  },
  {
    title: "Unidad de tiempo",
    dataIndex: "dia_hora",
    key: "dia_hora",
    width: "10%",
  },
  {
    title: "Testigo",
    dataIndex: "testigo_fallo",
    key: "testigo_fallo",
    width: "10%",
  },
  {
    title: "Observación",
    dataIndex: "observacion",
    key: "observacion",
    width: "25%",
  },
  {
    title: "Estado",
    dataIndex: "descripcion_estado",
    key: "descripcion_estado",
    width: "10%",
  },
];

export const concreteColumnsAction = [
  {
    title: "Ensayo",
    dataIndex: "ensayo",
    key: "ensayo",
    width: "45%",
  },
  {
    title: "Normativa",
    dataIndex: "normativa",
    key: "normativa",
    width: "10%",
  },
  {
    title: "Cantidad",
    dataIndex: "cantidad_fallo",
    key: "cantidad_fallo",
    width: "10%",
  },
  {
    title: "Edad de falla",
    dataIndex: "edad_fallo",
    key: "edad_fallo",
    width: "10%",
  },
  {
    title: "Unidad de tiempo",
    dataIndex: "dia_hora",
    key: "dia_hora",
    width: "10%",
  },
  {
    title: "Testigo",
    dataIndex: "testigo_fallo",
    key: "testigo_fallo",
    width: "10%",
  },
  {
    title: "Observación",
    dataIndex: "observacion",
    key: "observacion",
    width: "25%",
  },
  {
    title: "Acciones",
    dataIndex: "acciones",
    key: "acciones",
    render: (actions) => (
      <Flex gap={16} justify={"space-around"}>
        {actions.map((action, i) => (
          <Tooltip title={action.tooltip} color={theme.colors.orange}>
            <Button
              icon={
                <img alt={`${action.icon}`} src={action.icon} width={"24px"} />
              }
              onClick={() => action.onClick()}
              disabled={action.disabled}
              style={{
                background: "transparent",
                border: "none",
                boxShadow: "none",
              }}
            />
          </Tooltip>
        ))}
      </Flex>
    ),
  },
];

export const concreteColumnsActionDetail = [
  {
    title: "Ensayo",
    dataIndex: "ensayo",
    key: "ensayo",
    width: "45%",
  },
  {
    title: "Normativa",
    dataIndex: "normativa",
    key: "normativa",
    width: "10%",
  },
  {
    title: "Espécimen",
    dataIndex: "cantidad_fallo",
    key: "cantidad_fallo",
    width: "10%",
  },
  {
    title: "Edad de falla",
    dataIndex: "edad_fallo",
    key: "edad_fallo",
    width: "10%",
  },
  {
    title: "Unidad de tiempo",
    dataIndex: "dia_hora",
    key: "dia_hora",
    width: "10%",
  },
  {
    title: "Testigo",
    dataIndex: "testigo_fallo",
    key: "testigo_fallo",
    width: "10%",
  },
  {
    title: "Observación",
    dataIndex: "observacion",
    key: "observacion",
    width: "25%",
  },
  {
    title: "Estado",
    dataIndex: "descripcion_estado",
    key: "descripcion_estado",
    width: "10%",
  },
  // {
  //   title: "Acciones",
  //   dataIndex: "acciones",
  //   key: "acciones",
  //   render: (actions) => (
  //     <Flex gap={16} justify={"space-around"}>
  //       {actions.map((action, i) => (
  //         <Tooltip title={action.tooltip} color={theme.colors.orange}>
  //           <Button
  //             icon={
  //               <img alt={`${action.icon}`} src={action.icon} width={"24px"} />
  //             }
  //             onClick={() => action.onClick()}
  //             disabled={action.disabled}
  //             style={{
  //               background: "transparent",
  //               border: "none",
  //               boxShadow: "none",
  //             }}
  //           />
  //         </Tooltip>
  //       ))}
  //     </Flex>
  //   ),
  // },
];

export const concreteColumnsDetail = [
  {
    title: "Ensayo",
    dataIndex: "ensayo",
    key: "ensayo",
    width: "45%",
  },
  {
    title: "Normativa",
    dataIndex: "normativa",
    key: "normativa",
    width: "10%",
  },
  {
    title: "Cantidad",
    dataIndex: "cantidad_fallo",
    key: "cantidad_fallo",
    width: "10%",
  },
  {
    title: "Edad de falla",
    dataIndex: "edad_fallo",
    key: "edad_fallo",
    width: "10%",
  },
  {
    title: "Unidad de tiempo",
    dataIndex: "dia_hora",
    key: "dia_hora",
    width: "10%",
  },
  {
    title: "Testigo",
    dataIndex: "testigo_fallo",
    key: "testigo_fallo",
    width: "10%",
  },
  {
    title: "Observación",
    dataIndex: "observacion",
    key: "observacion",
    width: "25%",
  },
  {
    title: "Estado",
    dataIndex: "descripcion_estado",
    key: "descripcion_estado",
    width: "10%",
  },
];

export const columns = [
  {
    title: "Ensayo",
    dataIndex: "ensayo",
    key: "ensayo",
    width: "50%",
  },
  {
    title: "Cantidad",
    dataIndex: "cantidad_fallo",
    key: "cantidad_fallo",
    width: "10%",
  },
  {
    title: "Observación",
    dataIndex: "observacion",
    key: "observacion",
    width: "40%",
  },
];

export const columnsAction = [
  {
    title: "Ensayo",
    dataIndex: "ensayo",
    key: "ensayo",
    width: "50%",
  },
  {
    title: "Cantidad",
    dataIndex: "cantidad_fallo",
    key: "cantidad_fallo",
    width: "10%",
  },
  {
    title: "Observación",
    dataIndex: "observacion",
    key: "observacion",
    width: "40%",
  },
  {
    title: "Acciones",
    dataIndex: "acciones",
    key: "acciones",
    render: (actions) => (
      <Flex gap={16} justify={"space-around"}>
        {actions.map((action, i) => (
          <Button
            icon={
              <img alt={`${action.icon}`} src={action.icon} width={"24px"} />
            }
            onClick={() => action.onClick()}
            style={{
              background: "transparent",
              border: "none",
              boxShadow: "none",
            }}
          />
        ))}
      </Flex>
    ),
  },
];

export const generateCylinderCompressionLines = (amount) => {
  let newArray = [];

  for (let i = 0; i < amount; i++) {
    newArray.push({
      key: i,
      fecha_rotura: "",
      diametro: null,
      dato_1_diametro: null,
      dato_2_diametro: null,
      longitud: null,
      dato_1_longitud: null,
      dato_2_longitud: null,
      masa_cilindro: null,
      carga_maxima: null,
      tipo_falla_1: false,
      tipo_falla_2: false,
      tipo_falla_3: false,
      tipo_falla_4: false,
      tipo_falla_5: false,
      tipo_falla_6: false,
      temperatura: null,
      humedad: null,
      observacion: "",
      id: 0,
    });
  }

  return newArray;
};

export const generateSplitTractionLines = (amount) => {
  let newArray = [];

  for (let i = 0; i < amount; i++) {
    newArray.push({
      key: i,
      fecha_rotura: "",
      diametro: null,
      dato_1_diametro: null,
      dato_2_diametro: null,
      longitud: null,
      dato_1_longitud: null,
      dato_2_longitud: null,
      masa_cilindro: null,
      carga_maxima: null,
      tipo_falla_1: false,
      tipo_falla_2: false,
      tipo_falla_3: false,
      tipo_falla_4: false,
      // temperatura: null,
      // humedad: null,
      observacion: "",
      id: 0,
    });
  }

  return newArray;
};

export const generateConcreteflexingLines = (amount) => {
  let newArray = [];

  for (let i = 0; i < amount; i++) {
    newArray.push({
      key: i,
      fecha_rotura: "",
      altura: null,
      ancho: null,
      largo: null,
      longitud: null,
      longitud_apoyos: null,
      distancia_fractura_soporte: null,
      carga_maxima: null,
      tipo_falla_A: false,
      tipo_falla_B: false,
      // temperatura: null,
      // humedad: null,
      observacion: "",
      id: 0,
    });
  }

  return newArray;
};

export const generateCubesCompressionLines = (amount) => {
  let newArray = [];

  for (let i = 0; i < amount; i++) {
    newArray.push({
      key: i,
      fecha_rotura: "",
      altura: null,
      ancho: null,
      largo: null,
      masa_especimen: null,
      carga_maxima: null,
      velocidad_carga: null,
      // temperatura: null,
      // humedad: null,
      observacion: "",
      id: 0,
    });
  }

  return newArray;
};

//Columnas resultados
export const columnsCylinderCompressionResults = () => [
  {
    title: "Fecha de toma",
    dataIndex: "fecha_toma",
    key: "fecha_toma",
    render: (text) => dateFormatHour(text),
  },
  {
    title: "Fecha de rotura",
    dataIndex: "fecha_rotura",
    key: "fecha_rotura",
    render: (text) => dateFormatHour(text),
  },
  {
    title: "Edad de falla",
    dataIndex: "edad_fallo",
    key: "edad_fallo",
  },
  {
    title: "Unidad de tiempo",
    dataIndex: "dia_hora_fallo",
    key: "dia_hora_fallo",
  },
  {
    title: "Longitud\n[mm]",
    dataIndex: "longitud",
    key: "longitud",
  },
  {
    title: "Díametro\n[mm]",
    dataIndex: "diametro",
    key: "diametro",
  },
  {
    title: "Masa del cilindro\n[g]",
    dataIndex: "masa_cilindro",
    key: "masa_cilindro",
  },
  {
    title: "Area sección transv.\n[Cm2]",
    dataIndex: "area_seccion_transv",
    key: "area_seccion_transv",
  },
  {
    title: "Densidad\n[Kg/m3]",
    dataIndex: "densidad",
    key: "densidad",
  },
  {
    title: "Relación\n[L/D]",
    dataIndex: "relacion_LD",
    key: "relacion_LD",
  },
  {
    title: "Carga máxima\n[kN]",
    dataIndex: "carga_maxima",
    key: "carga_maxima",
  },
  {
    title: "Resistencia a la compresión\n[MPa]",
    dataIndex: "MPa",
    key: "MPa",
  },
  {
    title: "Resistencia a la compresión corregida\n[MPa]",
    dataIndex: "resistencia_corregida",
    key: "resistencia_corregida",
  },
  // {
  //   title: "Temperatura de la muestra\n[°C]",
  //   dataIndex: "temperatura",
  //   key: "temperatura",
  // },
  // {
  //   title: "Humedad\n[%]",
  //   dataIndex: "humedad",
  //   key: "humedad",
  // },
  {
    title: "Porcentaje de desarrollo\n[%]",
    dataIndex: "desarrollo",
    key: "desarrollo",
  },
  {
    title: "Tipo de falla",
    dataIndex: "tipo_falla",
    key: "tipo_falla",
  },
  {
    title: "Observaciones",
    dataIndex: "observaciones",
    key: "observaciones",
  },
];

export const columnsSplitTractionResults = () => [
  {
    title: "Fecha de toma",
    dataIndex: "fecha_toma",
    key: "fecha_toma",
    render: (text) => dateFormatHour(text),
  },
  {
    title: "Fecha de rotura",
    dataIndex: "fecha_rotura",
    key: "fecha_rotura",
    render: (text) => dateFormatHour(text),
  },
  {
    title: "Edad de falla",
    dataIndex: "edad_fallo",
    key: "edad_fallo",
  },
  {
    title: "Unidad de tiempo",
    dataIndex: "dia_hora_fallo",
    key: "dia_hora_fallo",
  },
  {
    title: "Longitud\n[mm]",
    dataIndex: "longitud",
    key: "longitud",
  },
  {
    title: "Díametro\n[mm]",
    dataIndex: "diametro",
    key: "diametro",
  },
  {
    title: "Masa del cilindro\n[Kg]",
    dataIndex: "masa_cilindro",
    key: "masa_cilindro",
  },
  {
    title: "Area sección transv.\n[Cm2]",
    dataIndex: "area_seccion_transv",
    key: "area_seccion_transv",
  },
  {
    title: "Densidad\n[Kg/m3]",
    dataIndex: "densidad",
    key: "densidad",
  },
  {
    title: "Relación\n[L/D]",
    dataIndex: "relacion_LD",
    key: "relacion_LD",
  },
  {
    title: "Carga máxima\n[kN]",
    dataIndex: "carga_maxima",
    key: "carga_maxima",
  },
  {
    title: "Resistencia a la tracción\n[MPa]",
    dataIndex: "MPa",
    key: "MPa",
  },
  // {
  //   title: "Temperatura de la muestra\n[°C]",
  //   dataIndex: "temperatura",
  //   key: "temperatura",
  // },
  // {
  //   title: "Humedad\n[%]",
  //   dataIndex: "humedad",
  //   key: "humedad",
  // },
  {
    title: "Porcentaje de desarrollo\n[%]",
    dataIndex: "desarrollo",
    key: "desarrollo",
  },
  {
    title: "Tipo de falla",
    dataIndex: "tipo_falla",
    key: "tipo_falla",
  },
  {
    title: "Observaciones",
    dataIndex: "observaciones",
    key: "observaciones",
  },
];

export const columnsConcreteflexingResults = () => [
  {
    title: "Fecha de toma",
    dataIndex: "fecha_toma",
    key: "fecha_toma",
    render: (text) => dateFormatHour(text),
  },
  {
    title: "Fecha de rotura",
    dataIndex: "fecha_rotura",
    key: "fecha_rotura",
    render: (text) => dateFormatHour(text),
  },
  {
    title: "Edad de falla",
    dataIndex: "edad_fallo",
    key: "edad_fallo",
  },
  {
    title: "Unidad de tiempo",
    dataIndex: "dia_hora_fallo",
    key: "dia_hora_fallo",
  },
  {
    title: "Dimensiones [mm]",
    children: [
      {
        title: "Largo",
        dataIndex: "largo",
        key: "largo",
      },
      {
        title: "Ancho",
        dataIndex: "ancho",
        key: "ancho",
      },
      {
        title: "Altura",
        dataIndex: "altura",
        key: "altura",
      },
    ],
  },
  {
    title: "Carga máxima\n[kN]",
    dataIndex: "carga_maxima",
    key: "carga_maxima",
  },
  {
    title: "Modulo de rotura\n[MPa]",
    dataIndex: "modulo_rotura",
    key: "modulo_rotura",
  },
  {
    title: "Tipo de falla",
    dataIndex: "tipo_falla",
    key: "tipo_falla",
  },
  // {
  //   title: "Temperatura de la muestra\n[°C]",
  //   dataIndex: "temperatura",
  //   key: "temperatura",
  // },
  // {
  //   title: "Humedad relativa\n[%]",
  //   dataIndex: "humedad",
  //   key: "humedad",
  // },
  {
    title: "Porcentaje de desarrollo\n[%]",
    dataIndex: "desarrollo",
    key: "desarrollo",
  },
  {
    title: "Observaciones",
    dataIndex: "observaciones",
    key: "observaciones",
  },
];

export const columnsCubesCompressionResults = () => [
  {
    title: "Fecha de toma",
    dataIndex: "fecha_toma",
    key: "fecha_toma",
    render: (text) => dateFormatHour(text),
  },
  {
    title: "Fecha de rotura",
    dataIndex: "fecha_rotura",
    key: "fecha_rotura",
    render: (text) => dateFormatHour(text),
  },
  {
    title: "Edad de falla",
    dataIndex: "edad_fallo",
    key: "edad_fallo",
  },
  {
    title: "Unidad de tiempo",
    dataIndex: "dia_hora_fallo",
    key: "dia_hora_fallo",
  },
  {
    title: "Dimensiones [mm]",
    children: [
      {
        title: "Largo",
        dataIndex: "largo",
        key: "largo",
      },
      {
        title: "Ancho",
        dataIndex: "ancho",
        key: "ancho",
      },
      {
        title: "Altura",
        dataIndex: "altura",
        key: "altura",
      },
    ],
  },
  {
    title: "Área total [cm2]",
    dataIndex: "area",
    key: "area",
  },
  {
    title: "Carga máxima de rotura\n[kN]",
    dataIndex: "carga_maxima",
    key: "carga_maxima",
  },
  {
    title: "Resistencia a la compresión\n[MPa]",
    dataIndex: "resistencia_compresion",
    key: "resistencia_compresion",
  },
  {
    title: "Promedio resistencia\n[MPa]",
    dataIndex: "promedio_resistencia",
    key: "promedio_resistencia",
  },
  {
    title: "Porcentaje de desarrollo\n[%]",
    dataIndex: "desarrollo",
    key: "desarrollo",
  },
  {
    title: "Observaciones",
    dataIndex: "observaciones",
    key: "observaciones",
  },
];

export const columnsLabelsReportCylinderCompression = {
  fecha_toma: "Fecha de toma",
  fecha_rotura: "Fecha de rotura",
  edad_fallo: "Edad de falla\n[Días/Horas]",
  longitud: "Longitud\n[mm]",
  diametro: "Díametro\n[mm]",
  masa_cilindro: "Masa del cilindro\n[g]",
  area_seccion_transv: "Area sección transv.\n[Cm2]",
  densidad: "Densidad\n[Kg/m3]",
  relacion_LD: "Relación\n[L/D]",
  carga_maxima: "Carga máxima\n[kN]",
  MPa: "Resistencia a la compresión\n[MPa]",
  resistencia_corregida: "Resistencia a la compresión corregida\n[MPa]",
  // temperatura: "Temperatura de la muestra\n[°C]",
  // humedad: "Humedad relativa\n[%]",
  desarrollo: "Porcentaje de desarrollo\n[%]",
  promedio_desarrollo: "Promedio porcentaje de desarrollo",
  tipo_falla: "Tipo de falla",
  observaciones: "Observaciones",
};

export const columnsLabelsReportSplitTraction = {
  fecha_toma: "Fecha de toma",
  fecha_rotura: "Fecha de rotura",
  edad_fallo: "Edad de falla\n[Días/Horas]",
  longitud: "Longitud\n[mm]",
  diametro: "Díametro\n[mm]",
  masa_cilindro: "Masa del cilindro\n[g]",
  area_seccion_transv: "Area sección transv.\n[Cm2]",
  densidad: "Densidad\n[Kg/m3]",
  relacion_LD: "Relación\n[L/D]",
  carga_maxima: "Carga máxima\n[kN]",
  MPa: "Resistencia a la tracción\n[MPa]",
  // temperatura: "Temperatura de la muestra\n[°C]",
  // humedad: "Humedad relativa\n[%]",
  desarrollo: "Porcentaje de desarrollo\n[%]",
  promedio_desarrollo: "Promedio porcentaje de desarrollo",
  tipo_falla: "Tipo de falla",
  observaciones: "Observaciones",
};

export const columnsLabelsReportConcreteflexing = {
  fecha_toma: "Fecha de toma",
  fecha_rotura: "Fecha de rotura",
  edad_fallo: "Edad de falla\n[Días/Horas]",
  largo: "Largo\n[mm]",
  ancho: "Ancho\n[mm]",
  altura: "Altura\n[mm]",
  carga_maxima: "Carga máxima\n[kN]",
  modulo_rotura: "Modulo de rotura\n[MPa]",
  tipo_falla: "Tipo de falla",
  // temperatura: "Temperatura de la muestra\n[°C]",
  // humedad: "Humedad relativa\n[%]",
  desarrollo: "Porcentaje de desarrollo\n[%]",
  promedio_desarrollo: "Promedio porcentaje de desarrollo",
  observaciones: "Observaciones",
};

export const columnsLabelsReportCubesCompression = {
  fecha_toma: "Fecha de toma",
  fecha_rotura: "Fecha de rotura",
  edad_fallo: "Edad de falla\n[Días/Horas]",
  largo: "Largo\n[mm]",
  ancho: "Ancho\n[mm]",
  altura: "Altura\n[mm]",
  area: "Área\n[mm2]",
  carga_maxima: "Carga máxima\n[kN]",
  resistencia_compresion: "Resistencia a la compresión\n[MPa]",
  promedio_resistencia: "Promedio resistencia\n[MPa]",
  desarrollo: "Porcentaje de desarrollo\n[%]",
  promedio_desarrollo: "Promedio porcentaje de desarrollo",
  observaciones: "Observaciones",
};

export const columnsDataReportCylinderCompression = [
  "fecha_toma",
  "fecha_rotura",
  "edad_fallo",
  "longitud",
  "diametro",
  "masa_cilindro",
  "area_seccion_transv",
  "densidad",
  "relacion_LD",
  "carga_maxima",
  "MPa",
  "resistencia_corregida",
  // "temperatura",
  // "humedad",
  "desarrollo",
  "promedio_desarrollo",
  "tipo_falla",
  "observaciones",
];

export const columnsDataReportSplitTraction = [
  "fecha_toma",
  "fecha_rotura",
  "edad_fallo",
  "longitud",
  "diametro",
  "masa_cilindro",
  "area_seccion_transv",
  "densidad",
  "relacion_LD",
  "carga_maxima",
  "MPa",
  // "temperatura",
  // "humedad",
  "desarrollo",
  "promedio_desarrollo",
  "tipo_falla",
  "observaciones",
];

export const columnsDataReportConcreteflexing = [
  "fecha_toma",
  "fecha_rotura",
  "edad_fallo",
  "largo",
  "ancho",
  "altura",
  "carga_maxima",
  "modulo_rotura",
  "tipo_falla",
  // "temperatura",
  // "humedad",
  "desarrollo",
  "promedio_desarrollo",
  "observaciones",
];

export const columnsDataReportCubesCompression = [
  "fecha_toma",
  "fecha_rotura",
  "edad_fallo",
  "largo",
  "ancho",
  "altura",
  "area",
  "carga_maxima",
  "resistencia_compresion",
  "promedio_resistencia",
  "desarrollo",
  "promedio_desarrollo",
  "observaciones",
];
