import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "common",
  initialState: {
    specificationsList: null,
    specimensList: null,
    providersList: null,
    testList: null,
  },
  reducers: {
    setSpecificationsList: (state, action) => {
      return {
        ...state,
        specificationsList: action.payload,
      };
    },
    setSpecimensList: (state, action) => {
      return {
        ...state,
        specimensList: action.payload,
      };
    },
    setProvidersList: (state, action) => {
      return {
        ...state,
        providersList: action.payload,
      };
    },
    setTestList: (state, action) => {
      return {
        ...state,
        testList: action.payload,
      };
    },
  },
});

export default slice.reducer;

export const {
  setSpecificationsList,
  setSpecimensList,
  setProvidersList,
  setTestList,
} = slice.actions;
