import styled from "styled-components";
import { Collapse } from "antd";
import { theme } from "../../styles/theme";

export const CustomCollapse = styled(Collapse)`
  .ant-collapse-item {
    .ant-collapse-content {
      height: min-content;
      padding: 15px 0px;

      .ant-collapse-content-box {
        padding-top: 0px;
      }
    }

    .ant-collapse-header {
      color: ${theme.colors.dark};
      font-size: ${theme.fonts.size.default};
      font-weight: ${theme.fonts.weight.medium};
      align-items: center;

      .ant-collapse-arrow {
        font-size: 14px;
      }

      .ant-collapse-header-text {
        text-align-last: left;
      }
    }
  }
`;
