import { useMutation } from "react-query";
import api from "../../../core/api";

const useSaveGeneralInfo = () => {
  const mutation = useMutation((formData) => {
    return api.post("/orden_servicio/ordenes_servicio/", formData);
  });
  return mutation;
};
export default useSaveGeneralInfo;
