/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { Container, Flex, Text } from "../../styles/common";
import Table from "../../components/Table";
import { Button } from "../../components/Button";
import { Link } from "../../components/Link";
import useGetList from "./hooks/useGetList";
import useGetListFilter from "./hooks/useGetListFilter";
import useGetGeneralBinnacle from "./hooks/useGetGeneralBinnacle";
import view from "../../assets/icons/view.svg";
import deleteIcon from "../../assets/icons/delete.svg";
import edit from "../../assets/icons/edit.svg";
import editDisable from "../../assets/icons/edit-disable.svg";
import file from "../../assets/icons/file.svg";
import fileDisable from "../../assets/icons/file-disable.svg";
import Skeleton from "../../components/Skeleton";
import { useNavigate } from "react-router-dom";
import { dateFormatHour, getUser, statusName } from "../../utilities/helpers";
import { theme } from "../../styles/theme";
import Modal from "../../components/Modal";
import { Steps, Tooltip } from "antd";
import ReactLoading from "react-loading";
import { useQueryClient } from "react-query";
import useGetTracking from "./hooks/useGetTracking";
import { useMediaQuery } from "react-responsive";
import { DatePicker } from "antd";
import { Select } from "../../components/Select";
import useGetStatus from "../ServiceOrder/hook/useGetStatus";
import { Input } from "../../components/Input";

export const ServiceOrderList = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.sm})`,
  });
  const userId = getUser().id;
  const role = getUser().role;
  const trackingRole = role === "Admin" || role === "Coordinador";
  const queryClient = useQueryClient();

  const navigate = useNavigate();
  const ordersList = useGetList(userId);
  const { data, isSuccess, isLoading } = ordersList;
  const [totalItems, setTotalItems] = useState(7);
  const [orderId, setOrderId] = useState(null);
  const binnacle = useGetGeneralBinnacle(orderId);
  const {
    data: dataBinnacle,
    isSuccess: isSuccessBinnacle,
    isLoading: isLoadingBinnacle,
  } = binnacle;

  const tracking = useGetTracking(orderId, trackingRole);
  const {
    data: dataTrackingInfo,
    isSuccess: isSuccessTrackingInfo,
    isLoading: isLoadingTrackingInfo,
  } = tracking;

  const [pages, setPages] = useState({ current: 1, pageSize: 7 });
  const [dataTable, setDataTable] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openModalTracking, setOpenModalTracking] = useState(false);
  const [binnacleInfo, setBinnacleInfo] = useState([]);
  const [trackingInfo, setTrackingInfo] = useState([]);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [statusOptions, setStatusOptions] = useState([]);
  const status = useGetStatus();
  const { data: statusData, isSuccess: isSuccessStatus } = status;

  const [start_date, setStart_date] = useState(null);
  const [end_date, setEnd_date] = useState(null);
  const [status_order, setStatus_order] = useState(null);
  const [id_order, setId_order] = useState(null);

  const ordersListFilter = useGetListFilter(
    start_date,
    end_date,
    status_order,
    id_order
  );
  const { data: ordersListFilterData, isSuccess: isSuccessOrdersListFilter } =
    ordersListFilter;

  const handleChangeSelect = (e) => {
    if (e) {
      setStatus_order(`"${e}"`);
    } else {
      setStatus_order(null);
    }
  };

  const handleChangeInput = (e) => {
    if (e.target.value) {
      setId_order(e.target.value);
    } else {
      setId_order(null);
    }
  };

  const handleChangeDateStart = (e) => {
    if (e) {
      const year = e.$y;
      const month = e.$M + 1;
      const day = e.$D;
      const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
        .toString()
        .padStart(2, "0")}`;
      setStart_date(`"${formattedDate}"`);
    } else {
      setStart_date(null);
    }
  };

  const handleChangeDateEnd = (e) => {
    if (e) {
      const year = e.$y;
      const month = e.$M + 1;
      const day = e.$D;
      const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
        .toString()
        .padStart(2, "0")}`;
      setEnd_date(`"${formattedDate}"`);
    } else {
      setEnd_date(null);
    }
  };

  useEffect(() => {
    if (isSuccessStatus) {
      setStatusOptions(
        statusData?.data.map((ele) => ({
          label: ele.descripcion,
          value: ele.codigo,
        }))
      );
    }
  }, [isSuccessStatus]);

  const handleDetail = (id, status, line) => {
    navigate(`/service-order/detail/${id}?status=${status}&workLine=${line}`);
  };

  const handleEdit = (id) => {
    navigate(`/service-order?id=${id}`);
  };

  const handleModal = (id) => {
    setOrderId(id);
    setOpenModalTracking(true);
    setForceUpdate(!forceUpdate);
  };

  const stepsStatus = (action, date) => {
    if (action.includes("Rechazado") && date) {
      return "error";
    } else if (date === null) {
      return "wait";
    } else {
      return "finish";
    }
  };

  const binnacleTitle = (title, name, date, observation) => {
    const colorText =
      title.includes("Rechazado") && date
        ? theme.colors.red2
        : theme.colors.dark;

    return (
      <Flex direction="column">
        <Text color={colorText} weight={theme.fonts.weight.medium}>
          {title}
        </Text>
        <Text color={colorText}>{name}</Text>
        <Flex>
          <Text color={colorText} size={theme.fonts.size.sm}>
            {observation ? `Observación: ${observation}` : ""}
          </Text>
        </Flex>
      </Flex>
    );
  };

  const trackingTitle = (title, name, email, date, observation) => {
    const colorText =
      title.includes("Rechazado") && date
        ? theme.colors.red2
        : theme.colors.dark;

    return (
      <Flex direction="column">
        <Text color={colorText} weight={theme.fonts.weight.medium}>
          {title}
        </Text>
        <Flex gap="5px">
          <Text color={colorText}>{name}</Text>-
          <Text color={colorText}>{email}</Text>
        </Flex>
        <Flex>
          <Text color={colorText} size={theme.fonts.size.sm}>
            {observation ? `Observación: ${observation}` : ""}
          </Text>
        </Flex>
      </Flex>
    );
  };

  useEffect(() => {
    if (isSuccessTrackingInfo && dataTrackingInfo) {
      setTrackingInfo(
        dataTrackingInfo.data?.map((ele) => ({
          title: trackingTitle(
            ele.accion_laboratorio?.descripcion,
            ele.nombre,
            ele.usuario,
            ele.fecha_creacion,
            ele.observacion
          ),
          date: ele.fecha_creacion,
          status: stepsStatus(
            ele.accion_laboratorio?.descripcion,
            ele.fecha_creacion
          ),
        }))
      );
      setForceUpdate(!forceUpdate);
    }
  }, [isSuccessTrackingInfo, dataTrackingInfo, trackingRole]);

  useEffect(() => {
    if (isSuccessBinnacle && dataBinnacle) {
      setBinnacleInfo(
        dataBinnacle.data?.map((ele) => ({
          title: binnacleTitle(
            ele.accion_cliente_proyecto?.descripcion,
            ele.nombre,
            ele.fecha_creacion,
            ele.observacion
          ),
          date: ele.fecha_creacion,
          status: stepsStatus(
            ele.accion_cliente_proyecto?.descripcion,
            ele.fecha_creacion
          ),
        }))
      );
    }
  }, [isSuccessBinnacle, dataBinnacle]);

  useEffect(() => {
    if (
      start_date === null &&
      end_date === null &&
      id_order === null &&
      status_order === null
    ) {
      if (isSuccess && data?.data.length > 0) {
        const newdataTable = data?.data.map((ele, i) => ({
          ...ele,
          acciones: [
            {
              key: `1${i}`,
              tooltip: "Detalle",
              icon: view,
              onClick: () =>
                handleDetail(ele.id, ele.estado_orden, ele.linea_trabajo),
              disabled: false,
            },
            {
              key: `1${i}`,
              tooltip: "Editar",
              icon:
                ele.estado_orden === "pendiente_diligenciar" &&
                ele.usuario === userId
                  ? edit
                  : editDisable,
              onClick: () => handleEdit(ele.id),
              disabled:
                ele.estado_orden !== "pendiente_diligenciar" ||
                ele.usuario !== userId,
            },
            {
              key: `1${i}`,
              tooltip: "Seguimiento",
              icon: trackingRole ? file : fileDisable,
              onClick: () => handleModal(ele.id),
              disabled: role !== "Admin" && role !== "Coordinador",
            },
          ],
        }));

        setDataTable(newdataTable);
        setTotalItems(newdataTable.length);
      } else {
        setDataTable([]);
      }
    }
  }, [data, isSuccess]);

  useEffect(() => {
    if (start_date || end_date || id_order || status_order) {
      ordersListFilter.refetch();
    } else {
      setDataTable([]);
      ordersList.refetch();
    }
  }, [start_date, end_date, id_order, status_order]);

  useEffect(() => {
    if (start_date || end_date || id_order || status_order) {
      if (isSuccessOrdersListFilter && ordersListFilterData?.data.length > 0) {
        const newDataTable = ordersListFilterData?.data.map((ele, i) => ({
          ...ele,
          acciones: [
            {
              key: `1${i}`,
              tooltip: "Detalle",
              icon: view,
              onClick: () =>
                handleDetail(ele.id, ele.estado_orden, ele.linea_trabajo),
              disabled: false,
            },
            {
              key: `1${i}`,
              tooltip: "Editar",
              icon:
                ele.estado_orden === "pendiente_diligenciar" &&
                ele.usuario === userId
                  ? edit
                  : editDisable,
              onClick: () => handleEdit(ele.id),
              disabled:
                ele.estado_orden !== "pendiente_diligenciar" ||
                ele.usuario !== userId,
            },
            {
              key: `1${i}`,
              tooltip: "Seguimiento",
              icon: trackingRole ? file : fileDisable,
              onClick: () => handleModal(ele.id),
              disabled: role !== "Admin" && role !== "Coordinador",
            },
          ],
        }));

        setDataTable(newDataTable);
        setTotalItems(newDataTable.length);
      } else {
        setDataTable([]);
      }
    }
  }, [ordersListFilterData, isSuccessOrdersListFilter]);

  const handleStatus = (id) => {
    setOpenModal(true);
    setOrderId(id);
  };

  const columns = [
    {
      title: "N° orden",
      dataIndex: "id",
      key: "id",
      width: 90,
    },
    {
      title: "Línea de trabajo",
      dataIndex: "linea_trabajo",
      key: "linea_trabajo",
    },
    {
      title: "Cliente",
      dataIndex: "cliente_proyecto",
      key: "cliente_proyecto",
    },
    {
      title: "Transporte",
      dataIndex: "transporte",
      key: "transporte",
    },
    {
      title: "Fecha de creación",
      dataIndex: "fecha_creacion",
      key: "fecha_creacion",
      render: (text) => dateFormatHour(text),
    },
    {
      title: "Fecha de modificación",
      dataIndex: "fecha_modificacion",
      key: "fecha_modificacion",
      render: (text) => dateFormatHour(text),
    },
    {
      title: "Estado de la orden",
      dataIndex: "estado_orden",
      key: "estado_orden",
      render: (text, item) => (
        <Flex width="160px">
          <Link
            color={theme.colors.orange}
            onClick={() => handleStatus(item.id)}
          >
            {statusName(text)}
          </Link>
        </Flex>
      ),
    },
    {
      title: "Acciones",
      dataIndex: "acciones",
      key: "acciones",
      render: (actions) => (
        <Flex gap={16} justify={"space-around"}>
          {actions.map((action, i) => (
            <Tooltip title={action.tooltip} color={theme.colors.orange}>
              <Button
                icon={
                  <img
                    alt={`${action.icon}`}
                    src={action.icon}
                    width={"24px"}
                  />
                }
                onClick={() => action.onClick()}
                disabled={action.disabled}
                style={{
                  background: "transparent",
                  border: "none",
                  boxShadow: "none",
                }}
              />
            </Tooltip>
          ))}
        </Flex>
      ),
    },
  ];

  const handleChangeTable = (pagination) => {
    setTotalItems(pagination.total);
    setPages({ current: pagination.current, pageSize: pagination.pageSize });
  };

  const handleClose = () => {
    queryClient.invalidateQueries("getGeneralBinnacle");
    queryClient.invalidateQueries("getTrackingInfo");
    setOpenModal(false);
    setOpenModalTracking(false);
    setForceUpdate(!forceUpdate);
  };

  return (
    <Container>
      <Modal
        align="center"
        isOpen={openModalTracking}
        onClose={handleClose}
        padding={36}
        width={"500px"}
      >
        <Modal.Header
          padding="0 0 16px 0"
          title={`Seguimiento de la orden de servicio #${orderId}`}
          size={theme.fonts.size.h5}
        />
        <Modal.Body margin="16px 0 24px 0" minHeight={700}>
          {isLoadingTrackingInfo ? (
            <Flex
              justify="center"
              align="center"
              height={"100%"}
              width={"100%"}
            >
              <ReactLoading
                type={"spin"}
                color={theme.colors.orange}
                height={"40px"}
                width={"40px"}
              />
            </Flex>
          ) : (
            <Steps
              size="small"
              direction="vertical"
              items={trackingInfo.map((ele) => ({
                title: ele.title,
                description: ele.date ? dateFormatHour(ele.date) : "",
                status: ele.status,
              }))}
            />
          )}
        </Modal.Body>
        <Modal.Footer gap={16} justify="center">
          <Button
            background={theme.colors.orange}
            hover={theme.colors.orangeHover}
            onClick={handleClose}
          >
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        align="center"
        isOpen={openModal}
        onClose={handleClose}
        padding={36}
        width={"500px"}
      >
        <Modal.Header
          padding="0 0 16px 0"
          title={`Bitácora de la orden de servicio #${orderId}`}
          size={theme.fonts.size.h5}
        />
        <Modal.Body margin="16px 0 24px 0" minHeight={700}>
          {isLoadingBinnacle ? (
            <Flex
              justify="center"
              align="center"
              height={"100%"}
              width={"100%"}
            >
              <ReactLoading
                type={"spin"}
                color={theme.colors.orange}
                height={"40px"}
                width={"40px"}
              />
            </Flex>
          ) : (
            <Steps
              size="small"
              direction="vertical"
              items={binnacleInfo.map((ele) => ({
                title: ele.title,
                description: ele.date ? dateFormatHour(ele.date) : "",
                status: ele.status,
              }))}
            />
          )}
        </Modal.Body>
        <Modal.Footer gap={16} justify="center">
          <Button
            background={theme.colors.orange}
            hover={theme.colors.orangeHover}
            onClick={handleClose}
          >
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
      <Text
        size={theme.fonts.size.h4}
        weight={theme.fonts.weight.bold}
        color={theme.colors.dark}
        style={{ textAlign: "left" }}
        mb="40px"
      >
        Ordenes de servicio
      </Text>
      <Flex
        gap={isMobile ? "20px" : "60px"}
        direction={isMobile ? "column" : "row"}
        mb="8px"
      >
        <Flex direction="column">
          <Input
            label={"N° orden"}
            placeholder={"Ingrese el N° orden"}
            id="id_order"
            onChange={(e) => handleChangeInput(e)}
            width={"200px"}
            type="number"
            min={1}
          />
        </Flex>
        <Flex direction="column">
          <Select
            label={"Estado"}
            placeholder={"Seleccione"}
            options={statusOptions}
            onChange={(e) => handleChangeSelect(e)}
            style={{ height: "40px" }}
            width={"200px"}
            id="status_order"
          />
        </Flex>
        <Flex direction="column">
          <Text
            size={theme.fonts.size.sm}
            color={theme.colors.dark}
            weight={theme.fonts.weight.medium}
            mb="8px"
            style={{ textAlign: "start" }}
          >
            Fecha de creación
          </Text>
          <Flex direction="row">
            <DatePicker
              id="start_date"
              onChange={(e) => handleChangeDateStart(e)}
              placeholder="Desde"
              style={{ width: "150px", height: "40px" }}
            />
            <DatePicker
              id="end_date"
              onChange={(e) => handleChangeDateEnd(e)}
              placeholder="Hasta"
              style={{ width: "150px", height: "40px", marginLeft: "60px" }}
            />
          </Flex>
        </Flex>
      </Flex>
      <br></br>
      <Table
        columns={columns}
        data={dataTable}
        sizerLabels={["Mostrando", "por página"]}
        pagination={{
          ...pages,
          total: totalItems,
          showSizeChanger: true,
          pageSizeOptions: ["7", "45", "70"],
          locale: {
            items_per_page: "",
          },
        }}
        onChange={handleChangeTable}
        locale={{
          emptyText: isLoading ? (
            <Flex justify="center" style={{ gap: 16 }}>
              {[...Array(9)].map((v, idx) => (
                <Skeleton
                  title={false}
                  paragraph={{
                    requests: 10,
                    width: "100%",
                  }}
                  loading
                  key={idx}
                />
              ))}
            </Flex>
          ) : (
            "No hay datos"
          ),
        }}
      />
    </Container>
  );
};
