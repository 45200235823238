import React from "react";
import PropTypes from "prop-types";
import { Flex } from "../../../styles/common";

const ModalFooter = ({ children, ...rest }) => {
  return <Flex {...rest}>{children}</Flex>;
};

ModalFooter.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ModalFooter;
