import React from "react";
import { Navigate } from "react-router-dom";
import { getToken } from "../../core/api";

export { PrivateRoute };

function PrivateRoute({ children }) {
  const isAuth = !!getToken();
  return isAuth ? children : <Navigate to="/" />;
}
