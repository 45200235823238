import api from "../../../core/api";
import { useQuery } from "react-query";

const useGetOperationalAssistants = () => {
  const data = useQuery(["getOperationalAssistants"], () =>
    api.get(`/ensayo/auxiliar_operativo`)
  );

  return data;
};

export default useGetOperationalAssistants;
