import React from "react";
import { Wrapper } from "./styles";
import { Text } from "../../../styles/common";
import { theme } from "../../../styles/theme";

const ModalHeader = ({
  iconName,
  iconColor,
  iconSize,
  title,
  align,
  margin,
  padding,
  hasBorder,
  color,
  size,
  ...rest
}) => {
  return (
    <Wrapper
      align={align}
      margin={margin}
      padding={padding}
      hasBorder={hasBorder}
      {...rest}
    >
      {iconName && (
        <img alt="alert" src={iconName} height={iconSize} width={iconSize} />
      )}
      {title && (
        <Text color={color} size={size} weight={theme.fonts.weight.bold}>
          {title}
        </Text>
      )}
    </Wrapper>
  );
};

export default ModalHeader;
