/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Container, Flex, Text } from "../../styles/common";
import { theme } from "../../styles/theme";
import { Steps } from "antd";
import { Step1 } from "./components/Step1";
import { Step2 } from "./components/Step2";
import useSaveSamples from "./hook/useSaveSamples";
import {
  validateAsphaltSamplesInfo,
  validateConcreteSamplesInfo,
  validateGranularSamplesInfo,
} from "../../utilities/validations";
import { Step3 } from "./components/Step3";
import moment from "moment";
import toast from "react-hot-toast";
import { formatHour } from "../../utilities/helpers";
import { useLocation, useNavigate } from "react-router-dom";
import useGetSamples from "./hook/useGetSamples";
import useGetSpecifications from "./hook/useGetSpecifications";
import useGetSpecimens from "./hook/useGetSpecimens";
import useGetProviders from "./hook/useGetProviders";
import { useDispatch } from "react-redux";
import {
  setProvidersList,
  setSpecificationsList,
  setSpecimensList,
} from "../../core/store/common";
import useUpdateSamples from "./hook/useUpdateSamples";

export const ServiceOrder = () => {
  const location = useLocation();
  const { search } = location;
  const searchParams = new URLSearchParams(search);
  const id = searchParams.get("id");
  const dispatch = useDispatch();
  const save = useSaveSamples();
  const { isLoading } = save;
  const update = useUpdateSamples(id);
  const { isLoading: isLoadingUpdate } = update;
  const navigate = useNavigate();

  const [step, setStep] = useState(0);
  const samples = useGetSamples(id, step);
  const { data, isSuccess } = samples;
  const [noSamples, setNoSamples] = useState(false);
  const specifications = useGetSpecifications(step);
  const { data: specificationsData, isSuccess: isSuccessSpecifications } =
    specifications;
  const specimens = useGetSpecimens(step);
  const { data: specimensData, isSuccess: isSuccessSpecimens } = specimens;
  const providers = useGetProviders(step);
  const { data: providersData, isSuccess: isSuccessProviders } = providers;
  console.log(data);

  const defaultValues = {
    proveedor: null,
    especificacion: null,
    especimen: null,
    codigo_laboratorio: "",
    fecha_toma: "",
    hora_toma: "",
    codigo_mezcla: "",
    descripcion_mezcla: "",
    resistencia_nominal: null,
    slum: null,
    temperatura: null,
    numero_remision: "",
    georeferenciacion: "",
    descripcion_georeferenciacion: "",
    cantidad_especimen: null,
    locacion: "",
    observacion: "",
    ensayos_realizar: [],
  };

  const defaultErrorValues = {
    proveedor: { error: false, message: "" },
    especificacion: { error: false, message: "" },
    especimen: { error: false, message: "" },
    codigo_laboratorio: { error: false, message: "" },
    fecha_toma: { error: false, message: "" },
    hora_toma: { error: false, message: "" },
    codigo_mezcla: { error: false, message: "" },
    descripcion_mezcla: { error: false, message: "" },
    resistencia_nominal: { error: false, message: "" },
    slum: { error: false, message: "" },
    temperatura: { error: false, message: "" },
    numero_remision: { error: false, message: "" },
    georeferenciacion: { error: false, message: "" },
    descripcion_georeferenciacion: { error: false, message: "" },
    cantidad_especimen: { error: false, message: "" },
    locacion: { error: false, message: "" },
    observacion: { error: false, message: "" },
    ensayos_realizar: { error: false, message: "" },
  };

  const [samplesArray, setSamplesArray] = useState([]);
  const [samplesInfo, setSamplesInfo] = useState({});
  const [errorForm, setErrorForm] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [workLine, setWorkLine] = useState(null);
  const [currentSample, setCurrentSample] = useState(null);
  const [orderId, setOrderId] = useState(null);
  const [forceUpdate, setForceUpdate] = useState(false);

  useEffect(() => {
    if (id === null) {
      setStep(0);
    }
  }, [id]);

  //Carga las especificaciones a listar
  useEffect(() => {
    if (isSuccessSpecifications) {
      dispatch(
        setSpecificationsList(
          specificationsData?.data.map((ele) => ({
            label: ele.nombre,
            value: ele.id,
          }))
        )
      );
      setForceUpdate(!forceUpdate);
    }
  }, [isSuccessSpecifications]);

  //Carga las especificaciones a listar
  useEffect(() => {
    if (workLine && isSuccessSpecimens) {
      dispatch(
        setSpecimensList(
          specimensData?.data
            .filter((elem) => elem.linea_trabajo === workLine)
            .map((ele) => ({
              label: ele.nombre,
              value: ele.id,
              test: ele.ensayo,
            }))
        )
      );
      setForceUpdate(!forceUpdate);
    }
  }, [isSuccessSpecimens, workLine]);

  //Carga los proveedores a listar
  useEffect(() => {
    if (workLine && isSuccessProviders) {
      dispatch(
        setProvidersList(
          providersData?.data
            .filter((elem) => elem.linea_trabajo.includes(workLine))
            .map((ele) => ({
              label: ele.nombre,
              value: ele.id,
            }))
        )
      );
      setForceUpdate(!forceUpdate);
    }
  }, [isSuccessProviders, workLine]);

  useEffect(() => {
    if (isSuccess) {
      const newSamplesArray = data?.data.map((ele) => ({
        ...ele,
        fecha_toma: ele.fecha_toma !== null ? moment(ele.fecha_toma) : null,
        hora_toma:
          ele.hora_toma !== null ? moment(ele.hora_toma, "HH:mm:ss") : null,
      }));
      setSamplesArray(newSamplesArray);
      const samples = newSamplesArray.length === 0 ? true : false;
      setNoSamples(samples);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (currentSample !== null) {
      const sample = samplesArray.find((ele, indx) => indx === currentSample);
      setSamplesInfo(sample);
    } else {
      setSamplesInfo(defaultValues);
      setErrorForm(defaultErrorValues);
    }
  }, [currentSample]);

  const reset = () => {
    setSamplesInfo(defaultValues);
    setErrorForm(defaultErrorValues);
    setForceUpdate(!forceUpdate);
  };

  const formType = (line) => {
    switch (line) {
      case "Línea de concretos":
        return validateConcreteSamplesInfo;
      case "Línea de asfaltos":
        return validateAsphaltSamplesInfo;
      case "Línea de granulares":
        return validateGranularSamplesInfo;

      default:
        break;
    }
  };

  const handleAdd = () => {
    const validation = formType(workLine).validate(
      {
        ...samplesInfo,
      },
      {
        abortEarly: false,
      }
    );

    if (validation.error) {
      const newErrorForm = errorForm;
      validation.error.details.forEach((ele) => {
        newErrorForm[ele.context.label].error = true;
        newErrorForm[ele.context.label].message =
          "Campo requerido o formato inválido";
        setErrorForm(newErrorForm);
        setForceUpdate(!forceUpdate);
      });
    } else if (currentSample !== null) {
      const newSamplesArray = samplesArray.filter(
        (ele, indx) => indx !== currentSample
      );
      const editedSample = { ...samplesInfo };
      setSamplesArray([...newSamplesArray, editedSample]);
      toast.success("Muestra añadida con exito");
      setSamplesInfo(defaultValues);
      setCurrentSample(null);
      if (id) {
        setStep(step + 1);
      }
    } else {
      const newSample = {
        ...samplesInfo,
        orden_servicio: Number(orderId),
      };
      setSamplesArray([...samplesArray, newSample]);
      toast.success("Muestra añadida con exito");
      setStep(step + 1);
      setSamplesInfo(defaultValues);
      setCurrentSample(null);
    }
  };

  const handleNext = () => {
    setStep(step + 1);
    setCurrentSample(null);
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  const handleUpdate = () => {
    const newSamplesArray = samplesArray.map((ele) => ({
      ...ele,
      fecha_toma:
        ele.fecha_toma !== null
          ? moment(ele.fecha_toma).format("YYYY-MM-DD")
          : null,
      hora_toma: ele.hora_toma !== null ? formatHour(ele.hora_toma) : null,
    }));

    save.reset();
    save.mutate(
      {
        id_orden_servicio: Number(orderId),
        data: [...newSamplesArray],
      },
      {
        onSuccess: () => {
          reset();
          toast.success("Muestras guardadas con exito");
          navigate("/service-order/list");
        },
        onError: () => {
          setErrorMessage(
            "Ha ocurrido un error, por favor comunicate con nuestra mesa de ayuda mesaayuda@conconcreto.com"
          );
        },
      }
    );
  };

  const handleSave = () => {
    const newSamplesArray = samplesArray.map((ele) => ({
      ...ele,
      fecha_toma:
        ele.fecha_toma !== ""
          ? moment(ele.fecha_toma).format("YYYY-MM-DD")
          : null,
      hora_toma: ele.hora_toma !== "" ? formatHour(ele.hora_toma) : null,
    }));

    save.reset();
    save.mutate(
      {
        id_orden_servicio: Number(orderId),
        data: [...newSamplesArray],
      },
      {
        onSuccess: () => {
          reset();
          toast.success("Muestras guardadas con exito");
          navigate("/service-order/list");
        },
        onError: () => {
          setErrorMessage(
            "Ha ocurrido un error, por favor comunicate con nuestra mesa de ayuda mesaayuda@conconcreto.com"
          );
        },
      }
    );
  };

  const handleEdit = (i) => {
    setCurrentSample(i);
    setStep(step - 1);
    setForceUpdate(!forceUpdate);
  };

  return (
    <Container style={{ justifyContent: "space-between" }}>
      <Flex direction="column">
        <Text
          size={theme.fonts.size.h4}
          weight={theme.fonts.weight.bold}
          color={theme.colors.dark}
          style={{ textAlign: "left" }}
        >
          {id ? "Editar orden de servicio" : "Crear orden de servicio"}
        </Text>
        <Flex m="30px 0 0 0" justify="center" p="0px 80px">
          <Steps
            current={step}
            size="small"
            items={[
              {
                title: "Información general",
              },
              {
                title: "Ingreso de muestras y ensayos",
              },
              {
                title: "Resumen",
              },
            ]}
          />
        </Flex>
        {step === 0 && (
          <Step1
            step={step}
            setStep={setStep}
            setWorkLine={setWorkLine}
            setOrderId={setOrderId}
            noSamples={noSamples}
          />
        )}
        {step === 1 && (
          <Step2
            samplesInfo={samplesInfo}
            setSamplesInfo={setSamplesInfo}
            errorForm={errorForm}
            setErrorForm={setErrorForm}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            isLoading={isLoading}
            handleAdd={handleAdd}
            orderId={orderId}
            workLine={workLine}
            id={id}
            noSamples={noSamples}
            samplesArray={samplesArray}
            handleNext={handleNext}
          />
        )}
        {step === 2 && (
          <Step3
            isLoading={isLoading}
            handleSave={handleSave}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            samplesArray={samplesArray}
            handleBack={handleBack}
            setSamplesArray={setSamplesArray}
            handleEdit={handleEdit}
            isLoadingUpdate={isLoadingUpdate}
            handleUpdate={handleUpdate}
            noSamples={noSamples}
          />
        )}
      </Flex>
    </Container>
  );
};
