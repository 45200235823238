import api from "../../../core/api";
import { useQuery } from "react-query";

const useWorkLine = () => {
  const data = useQuery(["getWorkLine"], () =>
    api.get(`/orden_servicio/linea_trabajo/`)
  );

  return data;
};

export default useWorkLine;
