/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Flex, Text } from "../../styles/common";
import logo from "../../assets/images/logo.png";
import microsoft from "../../assets/icons/microsoft.svg";
import logoccc from "../../assets/images/logo-ccc.png";
import { Button } from "../../components/Button";
import { CardLogin, WrapperLogin, WrapperLogo } from "./styles";
import useAuth from "./hook/useAuth";
import useLogin from "./hook/useAuthExt";
import Modal from "../../components/Modal";
import { theme } from "../../styles/theme";
import errorIcon from "../../assets/icons/error-warning.svg";
import { useMediaQuery } from "react-responsive";
import { Input } from "../../components/Input";
import { Link } from "../../components/Link";
import { Password } from "../../components/Password";
import user from "../../assets/icons/userLogin.svg";
import lock from "../../assets/icons/lock.svg";
import { passwordPattern } from "../../utilities/validations";
import { setToken } from "../../core/api";

export const Login = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.sm})`,
  });

  const [login, setLogin] = useState(false);
  const [openError, setOpenError] = useState(false);
  const authentication = useAuth(login);
  const { isSuccess, isLoading, data, isError } = authentication;
  const authExt = useLogin();
  const { isLoading: isLoadingLogin } = authExt;

  const defaultValues = {
    username: "",
    password: "",
  };

  const { control, handleSubmit, watch, formState } = useForm({
    mode: "onTouched",
    defaultValues,
  });
  const { isDirty, isValid, errors } = formState;

  useEffect(() => {
    if (isError) {
      setLogin(false);
      setOpenError(true);
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      setLogin(false);
      const a = document.createElement("a");
      a.href = `${data?.data?.azure_url}`;
      document.body.appendChild(a);
      a.click();
    }
  }, [isSuccess]);

  const handleMfaLogin = () => {
    setLogin(true);
  };

  const handleClose = () => {
    setOpenError(false);
  };

  const onSubmit = (payload) => {
    authExt.reset();
    authExt.mutate(
      { ...payload },
      {
        onSuccess: (data) => {
          setToken(data?.data?.token);
          navigate("/home");
        },
      }
    );
  };

  return (
    <WrapperLogin>
      <Modal
        align="center"
        isOpen={openError}
        onClose={handleClose}
        padding={36}
        style={{ width: "100px" }}
      >
        <Modal.Header
          padding="0 0 16px 0"
          title="Parece que presentas inconvenientes con el acceso"
          color={theme.colors.red}
          size={theme.fonts.size.h5}
          iconName={errorIcon}
          iconSize={"40px"}
        />
        <Modal.Body margin="16px 0 24px 0" minHeight={600}>
          <Text>
            Por favor comunicate con nuestra Mesa de Ayuda
            mesaayuda@conconcreto.com
          </Text>
        </Modal.Body>
      </Modal>
      <CardLogin>
        <Flex m="0 0 60px 0">
          <img alt="logo" src={logo} height={isMobile ? "300px" : "400px"} />
        </Flex>
        <Flex direction="column" m="10px 0 30px 0" align="center" gap="10px">
          <Link loading={isLoading} onClick={handleMfaLogin}>
            <img
              alt="microsoft"
              src={microsoft}
              style={{ marginRight: "5px" }}
            />
            Inicia sesión con tu cuenta corporativa
          </Link>
          <Flex m="10px 0 0 0">
            <hr
              style={{
                width: "120px",
                backgroundColor: theme.colors.gray2,
                border: `1px solid ${theme.colors.gray2}`,
              }}
            />
            <Text style={{ margin: "0px 10px 0px 10px" }}>o</Text>
            <hr
              style={{
                width: "120px",
                backgroundColor: theme.colors.gray2,
                border: `1px solid ${theme.colors.gray2}`,
              }}
            />
          </Flex>
        </Flex>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Flex gap="28px" align="center" m="0 0 10px 0">
            <img alt="user" src={user} />
            <Controller
              name="username"
              control={control}
              rules={{ required: true, maxLength: 20 }}
              render={({ field }) => (
                <Input
                  id="username"
                  placeholder={"Nombre de usuario"}
                  error={!!errors.username}
                  helper={
                    errors?.username && errors?.username?.type === "required"
                      ? "Campo requerido"
                      : ""
                  }
                  {...field}
                />
              )}
            />
          </Flex>
          <Flex gap="28px" align="center" m="0 0 10px 0">
            <img alt="password" src={lock} />
            <Controller
              name="password"
              control={control}
              rules={{ required: true, pattern: passwordPattern }}
              render={({ field }) => (
                <Password
                  id="password"
                  placeholder={"Contraseña"}
                  error={!!errors.password}
                  helper={
                    errors?.password && errors?.password?.type === "required"
                      ? "Campo requerido"
                      : errors?.password && errors?.password?.type === "pattern"
                      ? "Formato inválido"
                      : ""
                  }
                  {...field}
                />
              )}
            />
          </Flex>
          <Button
            type="submit"
            disabled={!isDirty || !isValid}
            isLoading={isLoadingLogin}
            onClick={() =>
              onSubmit({
                username: watch("username"),
                password: watch("password"),
              })
            }
            style={{ marginTop: "30px" }}
          >
            Iniciar sesión
          </Button>
        </form>
      </CardLogin>
      <WrapperLogo>
        <img alt="logoccc" src={logoccc} />
      </WrapperLogo>
    </WrapperLogin>
  );
};
