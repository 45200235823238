import { useMutation } from "react-query";
import api from "../../../core/api";

const useDeleteSpecimens = () => {
  const mutation = useMutation((id) => {
    return api.delete(`/muestra/especimenes/${id}`);
  });
  return mutation;
};

export default useDeleteSpecimens;
