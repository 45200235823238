import React, { useEffect, useState } from "react";
import useGetProviders from "./hooks/useGetProviders";
import useDeleteProviders from "./hooks/useDeleteProviders";
import useCreateProviders from "./hooks/useCreateProviders";
import useGetTypeOfContact from "./hooks/useGetTypeOfContact";
import useUpdateProviders from "./hooks/useUpdateProviders";
import useWorkLine from "../ServiceOrder/hook/useWorkLine";
import { Container, Flex, Text } from "../../styles/common";
import Table from "../../components/Table";
import { Button } from "../../components/Button";
import { theme } from "../../styles/theme";
import Skeleton from "../../components/Skeleton";
import deleteIcon from "../../assets/icons/delete.svg";
import editIcon from "../../assets/icons/edit.svg";
import toast from "react-hot-toast";
import { Tooltip } from "antd";
import { useForm } from "react-hook-form";
import { ModalProviders } from "./components/ModalProviders";

export const Providers = () => {
  const [pages, setPages] = useState({ current: 1, pageSize: 7 });
  const [totalItems, setTotalItems] = useState(7);
  const [dataTable, setDataTable] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editingProviders, setEditingProviders] = useState("");
  const [workLineOptions, setWorkLineOptions] = useState([]);
  const [typeOfContactOptions, setTypeOfContactOptions] = useState([]);
  const { data: workLineData, isSuccess: isSuccessWorkLine } = useWorkLine();
  const { data: typeOfContactData, isSuccess: isSuccessTypeOfContact } =
    useGetTypeOfContact();
  const createProviders = useCreateProviders();
  const { data, isLoading, isSuccess, refetch } = useGetProviders();
  const deleteProviders = useDeleteProviders();
  const updateProviders = useUpdateProviders(editingProviders.id);

  const defaultValues = {
    nit: "",
    nombre: "",
    direccion: "",
    linea_trabajo: "",
    numero_contacto: "",
    tipo_numero_contacto: "",
  };

  const { control, watch, reset, formState } = useForm({
    mode: "onTouched",
    defaultValues,
  });

  const handleUpdate = (id) => {
    setOpenModal(true);
    setIsEditing(true);
    const providersToUpdate = data && data.data.find((spec) => spec.id === id);
    const lineaTrabajoArray =
      providersToUpdate.linea_trabajo &&
      providersToUpdate.linea_trabajo.split(";");
    if (providersToUpdate) {
      setEditingProviders(providersToUpdate);
      reset({
        nit: providersToUpdate.nit,
        nombre: providersToUpdate.nombre,
        direccion: providersToUpdate.direccion,
        linea_trabajo: lineaTrabajoArray,
        numero_contacto: providersToUpdate.numero_contacto,
        tipo_numero_contacto: providersToUpdate.tipo_numero_contacto,
      });
    } else {
      reset(defaultValues);
    }
  };

  const handleDelete = (id) => {
    deleteProviders.reset();
    deleteProviders.mutate(id, {
      onSuccess: () => {
        toast.success(`Proveedor eliminado con éxito`);
        refetch();
      },
    });
  };

  const handleChangeTable = (pagination) => {
    setTotalItems(pagination.total);
    setPages({ current: pagination.current, pageSize: pagination.pageSize });
  };

  const handleClose = () => {
    setOpenModal(false);
    reset();
  };

  const handleCreate = () => {
    setOpenModal(true);
    setIsEditing(false);
    reset(defaultValues);
  };

  useEffect(() => {
    if (isSuccess && data?.data.length > 0) {
      const dataTable = data?.data.map((ele, i) => {
        const lineaTrabajoArray = ele.linea_trabajo
          ? ele.linea_trabajo.split(";")
          : [];

        return {
          ...ele,
          lineaTrabajoValue:
            lineaTrabajoArray.length > 0
              ? `- ${lineaTrabajoArray.join("\n- ")}`
              : lineaTrabajoArray.join("\n"),
          tipo_numero_contacto: typeOfContactData?.data.find(
            (elem) => elem.id === ele.tipo_numero_contacto
          )?.descripcion,
          acciones: [
            {
              key: `1${i}`,
              tooltip: "Editar",
              icon: editIcon,
              onClick: () => handleUpdate(ele.id),
              disabled: false,
            },
            {
              key: `1${i}`,
              tooltip: "Eliminar",
              icon: deleteIcon,
              onClick: () => handleDelete(ele.id),
              disabled: false,
            },
          ],
        };
      });

      setDataTable(dataTable);
      setTotalItems(dataTable.length);
    } else {
      setDataTable([]);
    }
  }, [data, isSuccess, typeOfContactData, isSuccessTypeOfContact]);

  const onSubmit = (payload) => {
    if (isEditing && editingProviders) {
      updateProviders.mutate(
        {
          ...payload,
        },
        {
          onSuccess: () => {
            toast.success("Proveedor actualizado con éxito");
            setOpenModal(false);
            reset();
            refetch();
          },
        }
      );
    } else {
      createProviders.mutate(payload, {
        onSuccess: () => {
          toast.success("Proveedor creado con éxito");
          setOpenModal(false);
          reset();
          refetch();
        },
      });
    }
  };

  useEffect(() => {
    if (isSuccessTypeOfContact) {
      setTypeOfContactOptions(
        typeOfContactData?.data.map((ele) => ({
          label: ele.descripcion,
          value: ele.id,
        }))
      );
    }
  }, [isSuccessTypeOfContact]);

  useEffect(() => {
    if (isSuccessWorkLine) {
      setWorkLineOptions(
        workLineData?.data.map((ele) => ({
          label: ele.nombre,
          value: ele.nombre,
        }))
      );
    }
  }, [isSuccessWorkLine]);

  const columns = [
    {
      title: "Id de proveedor",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Nit",
      dataIndex: "nit",
      key: "nit",
    },
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "nombre",
    },
    {
      title: "Dirección",
      dataIndex: "direccion",
      key: "direccion",
    },
    {
      title: "Número de contacto",
      dataIndex: "numero_contacto",
      key: "numero_contacto",
    },
    {
      title: "Tipo de contacto",
      dataIndex: "tipo_numero_contacto",
      key: "tipo_numero_contacto",
    },
    {
      title: "Línea de trabajo",
      dataIndex: "lineaTrabajoValue",
      key: "lineaTrabajoValue",
      render: (text) => <span style={{ whiteSpace: "pre-line" }}>{text}</span>,
    },
    {
      title: "Acciones",
      dataIndex: "acciones",
      key: "acciones",
      render: (actions) => (
        <Flex gap={16} justify={"space-around"}>
          {actions.map((action, i) => (
            <Tooltip title={action.tooltip} color={theme.colors.orange}>
              <Button
                icon={
                  <img
                    alt={`${action.icon}`}
                    src={action.icon}
                    width={"24px"}
                  />
                }
                onClick={() => action.onClick()}
                disabled={action.disabled}
                style={{
                  background: "transparent",
                  border: "none",
                  boxShadow: "none",
                }}
              />
            </Tooltip>
          ))}
        </Flex>
      ),
    },
  ];

  return (
    <Container>
      <ModalProviders
        openModal={openModal}
        handleClose={handleClose}
        onSubmit={onSubmit}
        formState={formState}
        control={control}
        watch={watch}
        isLoading={createProviders.isLoading}
        isEditing={isEditing}
        workLineOptions={workLineOptions}
        typeOfContactOptions={typeOfContactOptions}
      />
      <Flex justify="space-between">
        <Text
          size={theme.fonts.size.h4}
          weight={theme.fonts.weight.bold}
          color={theme.colors.dark}
          style={{ textAlign: "left" }}
          mb="40px"
        >
          Proveedores
        </Text>
        <Button
          background={theme.colors.orange}
          hover={theme.colors.orangeHover}
          onClick={handleCreate}
        >
          Crear proveedor
        </Button>
      </Flex>
      <Table
        columns={columns}
        data={dataTable}
        sizerLabels={["Mostrando", "por página"]}
        pagination={{
          ...pages,
          total: totalItems,
          showSizeChanger: true,
          pageSizeOptions: ["7", "45", "70"],
          locale: {
            items_per_page: "",
          },
        }}
        onChange={handleChangeTable}
        locale={{
          emptyText: isLoading ? (
            <Flex justify="center" style={{ gap: 16 }}>
              {[...Array(9)].map((v, idx) => (
                <Skeleton
                  title={false}
                  paragraph={{
                    requests: 10,
                    width: "100%",
                  }}
                  loading
                  key={idx}
                />
              ))}
            </Flex>
          ) : (
            "No hay datos"
          ),
        }}
      />
    </Container>
  );
};
