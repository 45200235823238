import axios from "axios";

const api = axios.create({
  baseURL: "https://solab.conconcreto.com/back",
  headers: {
    "Content-Type": "application/json",
  },
});

const getToken = () => {
  const token = localStorage.getItem("user");

  return token;
};

api.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const { response } = error;
    const status = response ? response.status : 400;
    if (status === 401) {
      removeToken();
      if (typeof window !== "undefined") {
        return (window.location.href = "/");
      }
    }
    if (status === 403 && typeof window !== "undefined") {
      return (window.location.href = "/");
    }
    return Promise.reject(error);
  }
);

const setToken = (token) => {
  localStorage.setItem("user", token);
};

const removeToken = () => {
  localStorage.removeItem("user");
};

export { setToken, removeToken, getToken };
export default api;
