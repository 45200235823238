import api from "../../../core/api";
import { useQuery } from "react-query";

const useGetPendingSamples = () => {
  const data = useQuery(["useGetPendingSamples"], () =>
    api.get(`/muestra/muestras_pendientes_PDF/`)
  );

  return data;
};

export default useGetPendingSamples;
