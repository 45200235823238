import { useMutation } from "react-query";
import api from "../../../core/api";

const useSendReportEmail = (id_muestra) => {
  const mutation = useMutation((formData) => {
    return api.put(`/muestra/enviar_informe_correo/${id_muestra}/`, formData);
  });
  return mutation;
};
export default useSendReportEmail;
