import { useMutation } from "react-query";
import api from "../../../core/api";

const useDeleteSpecifications = () => {
  const mutation = useMutation((id) => {
    return api.delete(`/muestra/especificaciones/${id}`);
  });
  return mutation;
};

export default useDeleteSpecifications;
