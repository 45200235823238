/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Container, Flex, Text } from "../../styles/common";
import { theme } from "../../styles/theme";
import Table from "../../components/Table";
import Skeleton from "../../components/Skeleton";
import useGetOperationalAssistants from "./hooks/useGetOperationalAssistants";
import { Tooltip } from "antd";
import { Button } from "../../components/Button";
import deleteIcon from "../../assets/icons/delete.svg";
import useDeleteUser from "./hooks/useDeleteUser";
import toast from "react-hot-toast";
import { ModalUser } from "./components/ModalOperationalAssistants";
import useCreateUser from "./hooks/useCreateUser";
import { useForm } from "react-hook-form";
import editIcon from "../../assets/icons/edit.svg";
import useUpdateOperationalAssistants from "./hooks/useUpdateOperationalAssistants";

export const OperationalAssistants = () => {
  const create = useCreateUser();
  const { data, isLoading, isSuccess, refetch } = useGetOperationalAssistants();
  const deleteUser = useDeleteUser();
  const [pages, setPages] = useState({ current: 1, pageSize: 7 });
  const [totalItems, setTotalItems] = useState(7);
  const [dataTable, setDataTable] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editingOperationalAssistants, setEditingOperationalAssistants] =
    useState("");
  const updateUsers = useUpdateOperationalAssistants(
    editingOperationalAssistants.id
  );

  const defaultValues = {
    nombres: "",
    apellidos: "",
    username: "",
    password: "",
    firma_imagen: "",
  };

  const { control, watch, reset, formState, setValue } = useForm({
    mode: "onTouched",
    defaultValues,
  });

  const onSubmit = (payload) => {
    if (isEditing && editingOperationalAssistants) {
      updateUsers.mutate(
        {
          ...payload,
        },
        {
          onSuccess: () => {
            toast.success("Usuario actualizado con éxito");
            setOpenModal(false);
            reset();
            refetch();
          },
        }
      );
    } else {
      create.reset();
      create.mutate(
        { ...payload },
        {
          onSuccess: () => {
            toast.success("Auxiliar operativo creado con exito");
            setOpenModal(false);
            reset();
            refetch();
          },
        }
      );
    }
  };

  const handleUpdate = (id) => {
    setOpenModal(true);
    setIsEditing(true);
    const operationalAssistantsToUpdate =
      data && data.data.find((spec) => spec.id === id);
    if (operationalAssistantsToUpdate) {
      setEditingOperationalAssistants(operationalAssistantsToUpdate);
      reset({
        nombres: operationalAssistantsToUpdate.nombres,
        username: operationalAssistantsToUpdate.username,
        apellidos: operationalAssistantsToUpdate.apellidos,
        password: operationalAssistantsToUpdate.password,
        firma_imagen: operationalAssistantsToUpdate.firma_imagen,
      });
    } else {
      reset(defaultValues);
    }
  };

  const handleDelete = (id) => {
    deleteUser.reset();
    deleteUser.mutate(id, {
      onSuccess: () => {
        toast.success(`Auxiliar operativo eliminado con éxito`);
        refetch();
      },
    });
  };
  useEffect(() => {
    if (isSuccess && data?.data.length > 0) {
      const dataTable = data?.data.map((ele, i) => ({
        ...ele,
        acciones: [
          {
            key: `1${i}`,
            tooltip: "Editar",
            icon: editIcon,
            onClick: () => handleUpdate(ele.id),
            disabled: false,
          },
          {
            key: `1${i}`,
            tooltip: "Eliminar",
            icon: deleteIcon,
            onClick: () => handleDelete(ele.id),
            disabled: false,
          },
        ],
      }));
      setDataTable(dataTable);
      setTotalItems(dataTable.length);
    } else {
      setDataTable([]);
    }
  }, [data, isSuccess]);
  const columns = [
    {
      title: "Id de usuario",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Usuario",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Nombres",
      dataIndex: "nombres",
      key: "nombres",
    },
    {
      title: "Apellidos",
      dataIndex: "apellidos",
      key: "apellidos",
    },
    {
      title: "Firma",
      dataIndex: "firma_imagen",
      key: "firma_imagen",
      render: (imageBase64) =>
        imageBase64 ? (
          <img
            src={`data:image/png;base64,${imageBase64}`}
            alt="Firma"
            style={{ width: "100px", height: "auto" }}
          />
        ) : (
          "Sin firma"
        ),
    },
    {
      title: "Acciones",
      dataIndex: "acciones",
      key: "acciones",
      render: (actions) => (
        <Flex gap={16} justify={"space-around"}>
          {actions.map((action, i) => (
            <Tooltip title={action.tooltip} color={theme.colors.orange}>
              <Button
                icon={
                  <img
                    alt={`${action.icon}`}
                    src={action.icon}
                    width={"24px"}
                  />
                }
                onClick={() => action.onClick()}
                disabled={action.disabled}
                style={{
                  background: "transparent",
                  border: "none",
                  boxShadow: "none",
                }}
              />
            </Tooltip>
          ))}
        </Flex>
      ),
    },
  ];
  const handleChangeTable = (pagination) => {
    setTotalItems(pagination.total);
    setPages({ current: pagination.current, pageSize: pagination.pageSize });
  };
  const handleClose = () => {
    setOpenModal(false);
    reset();
  };
  const handleCreate = () => {
    setOpenModal(true);
    setIsEditing(false);
    reset(defaultValues);
  };
  return (
    <Container>
      <ModalUser
        openModal={openModal}
        handleClose={handleClose}
        onSubmit={onSubmit}
        formState={formState}
        control={control}
        watch={watch}
        isLoading={create.isLoading}
        isEditing={isEditing}
        signatureImage={editingOperationalAssistants.firma_imagen}
        setValue={setValue}
      />
      <Flex justify="space-between">
        <Text
          size={theme.fonts.size.h4}
          weight={theme.fonts.weight.bold}
          color={theme.colors.dark}
          style={{ textAlign: "left" }}
          mb="40px"
        >
          Auxiliares operativos
        </Text>
        <Button
          background={theme.colors.orange}
          hover={theme.colors.orangeHover}
          onClick={handleCreate}
        >
          Crear auxiliar operativo
        </Button>
      </Flex>
      <Table
        columns={columns}
        data={dataTable}
        sizerLabels={["Mostrando", "por página"]}
        pagination={{
          ...pages,
          total: totalItems,
          showSizeChanger: true,
          pageSizeOptions: ["7", "45", "70"],
          locale: {
            items_per_page: "",
          },
        }}
        onChange={handleChangeTable}
        locale={{
          emptyText: isLoading ? (
            <Flex justify="center" style={{ gap: 16 }}>
              {[...Array(9)].map((v, idx) => (
                <Skeleton
                  title={false}
                  paragraph={{
                    requests: 10,
                    width: "100%",
                  }}
                  loading
                  key={idx}
                />
              ))}
            </Flex>
          ) : (
            "No hay datos"
          ),
        }}
      />
    </Container>
  );
};
