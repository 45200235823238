import React from "react";
import { Navigate } from "react-router-dom";
import { getToken } from "../../core/api";

export { PublicRoute };

function PublicRoute({ children }) {
  const isAuth = !!getToken();
  return isAuth ? <Navigate to="/home" /> : children;
}
