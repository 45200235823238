import styled from "styled-components";
import { Button } from "antd";
import { theme } from "../../styles/theme";

export const CustomButton = styled(Button)`
  height: ${({ height }) => height || "38px"};
  background: ${({ background }) => background || theme.colors.green} 0% 0%
    no-repeat padding-box;
  border-radius: 12px;
  color: ${({ color }) => color || theme.colors.white};
  font-size: ${({ size }) => size || theme.fonts.size.default};
  font-weight: ${({ weight }) => weight || theme.fonts.weight.regular};
  border: 1px solid;
  border-color: ${({ border }) => border || "transparent"};
  padding: 0px 30px;

  && {
    &.ant-btn-link {
      &:hover {
        color: ${({ color }) => color || theme.colors.white};
      }
    }

    &:disabled {
      border-color: ${theme.colors.gray};
      color: #000000;
      background-color: ${theme.colors.gray};
      cursor: not-allowed;
      border-color: ${({ border }) => border || "transparent"};
      color: ${({ color }) => color || theme.colors.white};
    }

    &.ant-btn-default {
      &:hover {
        color: ${({ color }) => color || theme.colors.white};
        border: 1px solid;
        border-color: ${({ border }) => border || "transparent"};
        background: ${({ hover }) => hover || theme.colors.greenHover};
      }
    }
  }
`;
