import api from "../../../core/api";
import { useQuery } from "react-query";

const useGetProviders = (step) => {
  const data = useQuery(
    ["getProviders", step],
    () => api.get(`/muestra/proveedores/`),
    { enabled: step === 0 }
  );

  return data;
};

export default useGetProviders;
