import styled, { css } from "styled-components";
import { theme } from "../../../styles/theme";

export const Wrapper = styled.div`
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  text-align: ${(props) => props.align};

  ${(props) =>
    props.hasBorder &&
    css`
      border-bottom: 1px solid ${theme.colors.light};
    `}

  h4 {
    margin-bottom: 0;
  }
`;
