import api from "../../../core/api";
import { useQuery } from "react-query";

const useGetRegulations = () => {
  const data = useQuery(["useGetRegulations"], () =>
    api.get(`/muestra/normativas`)
  );

  return data;
};

export default useGetRegulations;
