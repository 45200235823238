import api from "../../../core/api";
import { useQuery } from "react-query";

const useGetOrder = (id) => {
  const data = useQuery(
    ["getServiceOrder", id],
    () => api.get(`/orden_servicio/orden_servicio_usuario/${id}`),
    { enabled: !!id }
  );

  return data;
};

export default useGetOrder;
