import api from "../../../core/api";
import { useQuery } from "react-query";

const useAuth = (login) => {
  const data = useQuery(
    ["authentication", login],
    () => api.get(`/login/login/`),
    {
      enabled: login,
    }
  );

  return data;
};

export default useAuth;
