import { useMutation } from "react-query";
import api from "../../../core/api";

const useUpdateOperationalAssistants = (id) => {
  const mutation = useMutation((formData) => {
    return api.put(`/ensayo/auxiliar_operativo/${id}/`, formData);
  });
  return mutation;
};
export default useUpdateOperationalAssistants;
