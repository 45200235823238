import api from "../../../core/api";
import { useQuery } from "react-query";

const useGetProviders = () => {
  const data = useQuery(["useGetProviders"], () =>
    api.get(`/muestra/proveedores`)
  );

  return data;
};

export default useGetProviders;
