import api from "../../../core/api";
import { useQuery } from "react-query";

const useGetSpecifications = () => {
  const data = useQuery(["useGetSpecifications"], () =>
    api.get(`/muestra/especificaciones`)
  );

  return data;
};

export default useGetSpecifications;
