import api from "../../../core/api";
import { useMutation } from "react-query";

const useCreateSpecifications = () => {
  const mutation = useMutation((formData) => {
    return api.post("/muestra/especificaciones/", formData);
  });
  return mutation;
};
export default useCreateSpecifications;
