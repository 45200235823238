import { useMutation } from "react-query";
import api from "../../../core/api";

const useUpdateRegulations = (id) => {
  const mutation = useMutation((formData) => {
    return api.put(`/muestra/normativas/${id}/`, formData);
  });
  return mutation;
};
export default useUpdateRegulations;
