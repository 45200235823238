import api from "../../../core/api";
import { useQuery } from "react-query";

const useGetToken = (cod) => {
  const data = useQuery(
    ["getToken", cod],
    () => api.get(`/login/autenticacion-login/${cod}`),
    { enabled: !!cod }
  );

  return data;
};

export default useGetToken;
