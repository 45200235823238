import api from "../../../core/api";
import { useQuery } from "react-query";

const useGetSpecimens = (step) => {
  const data = useQuery(
    ["getSpecimens", step],
    () => api.get(`/muestra/especimenes/`),
    { enabled: step === 0, refetchOnWindowFocus: false }
  );

  return data;
};

export default useGetSpecimens;
