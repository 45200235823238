import api from "../../../core/api";
import { useQuery } from "react-query";

const useGetTransport = () => {
  const data = useQuery(
    ["getTransport"],
    () => api.get(`/orden_servicio/transportes/`),
    { refetchOnWindowFocus: false }
  );

  return data;
};

export default useGetTransport;
