/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Flex } from "../../styles/common";
import { Menu } from "./Menu";
import { useLocation, useNavigate } from "react-router-dom";
import useGetToken from "../../pages/Login/hook/useGetToken";
import { removeToken, setToken } from "../../core/api";
import ReactLoading from "react-loading";
import { theme } from "../../styles/theme";
import { WrapperMenu } from "./Menu/styles";
import { useMediaQuery } from "react-responsive";
import { MenuMobile } from "./MenuMobile";
import user from "../../assets/icons/user.svg";
import briefcase from "../../assets/icons/briefcase.svg";
import draft from "../../assets/icons/draft.svg";
import caravan from "../../assets/icons/caravan.svg";
import dpf from "../../assets/icons/filetype-pdf.svg";
import team from "../../assets/icons/team.svg";
import stack from "../../assets/icons/stack.svg";
import flask from "../../assets/icons/flask.svg";
import survey from "../../assets/icons/survey.svg";
import { getUser } from "../../utilities/helpers";
import useLogout from "../../pages/Login/hook/useLogout";
import logout from "../../assets/icons/logout.svg";
import regulation from "../../assets/icons/regulation.svg";

export const Layout = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { search } = location;
  const searchParams = new URLSearchParams(search);
  const cod = searchParams.get("thor");
  const getToken = useGetToken(cod);
  const { data, isSuccess, isLoading } = getToken;
  const [forceUpdate, setForceUpdate] = useState(false);
  const [isLogout, setIsLogout] = useState(false);
  const logoutFunc = useLogout(isLogout);
  const {
    isSuccess: isSuccessLogout,
    isLoading: isLoadingLogout,
    data: logoutData,
  } = logoutFunc;
  const [openMenu, setOpenMenu] = useState(false);

  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.sm})`,
  });

  useEffect(() => {
    if (isSuccess) {
      setToken(data.data?.token);
      navigate("/home");
      setForceUpdate(!forceUpdate);
    }
  }, [isSuccess]);

  const options = [
    {
      key: "1",
      label: "Usuarios",
      icon: user,
      options: [
        {
          label: "Gestionar usuarios",
          onClick: () => {
            navigate("users");
            setOpenMenu(false);
          },
          permission: getUser().permissions.includes(29),
        },
        {
          label: "Gestionar auxiliares operativos",
          onClick: () => {
            navigate("operational-assistants");
            setOpenMenu(false);
          },
          permission: getUser().permissions.includes(17),
        },
      ],
      permission:
        getUser().permissions.includes(17) ||
        getUser().permissions.includes(29),
    },
    {
      key: "2",
      label: "Ensayos",
      icon: flask,
      options: [
        {
          label: "Gestionar ensayos",
          onClick: () => {
            navigate("test");
            setOpenMenu(false);
          },
          permission: getUser().permissions.includes(19),
        },
      ],
      permission: getUser().permissions.includes(19),
    },
    {
      key: "3",
      label: "Informes",
      icon: dpf,
      options: [
        {
          label: "Gestionar informes",
          onClick: () => {
            navigate("test/pending-reports");
            setOpenMenu(false);
          },
          permission:
            getUser().role === "Admin" || getUser().role === "Coordinador",
        },
      ],
      permission:
        getUser().role === "Admin" || getUser().role === "Coordinador",
    },
    {
      key: "4",
      label: "Ordenes de servicio",
      icon: draft,
      options: [
        {
          label: "Crear orden de servicio",
          onClick: () => {
            navigate("service-order");
            setOpenMenu(false);
          },
          permission: getUser().permissions.includes(2),
        },
        {
          label: "Gestionar ordenes de servicio",
          onClick: () => {
            navigate("service-order/list");
            setOpenMenu(false);
          },
          permission: getUser().permissions.includes(7),
        },
      ],
      permission: getUser().role !== "Auxiliar Operativo",
    },
    // {
    //   key: "5",
    //   label: "Transportes",
    //   icon: caravan,
    //   options: [],
    //   permission: getUser().role === "Admin",
    // },
    {
      key: "6",
      label: "Especificaciones",
      icon: survey,
      options: [
        {
          label: "Gestionar especificaciones",
          onClick: () => {
            navigate("Specifications");
            setOpenMenu(false);
          },
          permission: getUser().role === "Admin",
        },
      ],
      permission: getUser().role === "Admin",
    },
    {
      key: "7",
      label: "Proveedores",
      icon: team,
      options: [
        {
          label: "Gestionar proveedores",
          onClick: () => {
            navigate("Providers");
            setOpenMenu(false);
          },
          permission: getUser().role === "Admin",
        },
      ],
      permission: getUser().role === "Admin",
    },
    {
      key: "8",
      label: "Especímenes",
      icon: stack,
      options: [
        {
          label: "Gestionar especímenes",
          onClick: () => {
            navigate("Specimens");
            setOpenMenu(false);
          },
          permission: getUser().role === "Admin",
        },
      ],
      permission: getUser().role === "Admin",
    },
    {
      key: "9",
      label: "Normativas",
      icon: regulation,
      options: [
        {
          label: "Gestionar normativas",
          onClick: () => {
            navigate("Regulations");
            setOpenMenu(false);
          },
          permission: getUser().role === "Admin",
        },
      ],
      permission: getUser().role === "Admin",
    },
  ];

  const handleLogout = () => {
    setIsLogout(true);
    setForceUpdate(!forceUpdate);
  };

  useEffect(() => {
    if (isSuccessLogout) {
      const a = document.createElement("a");
      a.href = `${logoutData?.data?.azure_url}`;
      document.body.appendChild(a);
      a.click();
      removeToken();
      setForceUpdate(!forceUpdate);
    }
  }, [isSuccessLogout]);

  return (
    <>
      {isLoading ? (
        <Flex justify="center" align="center" height={"100%"} width={"100%"}>
          <ReactLoading
            type={"spin"}
            color={theme.colors.orange}
            height={"40px"}
            width={"40px"}
          />
        </Flex>
      ) : isMobile ? (
        <Flex direction="column">
          <MenuMobile
            options={options}
            handleLogout={handleLogout}
            isLoading={isLoadingLogout}
            logout={logout}
            openMenu={openMenu}
            setOpenMenu={setOpenMenu}
          />
          <Flex>{children}</Flex>
        </Flex>
      ) : (
        <Flex direction="row">
          <Flex width="20%">
            <Menu
              options={options}
              handleLogout={handleLogout}
              isLoading={isLoadingLogout}
              logout={logout}
            />
          </Flex>
          <WrapperMenu elevation={2} radius={8} p="12px" width="80%">
            {children}
          </WrapperMenu>
        </Flex>
      )}
    </>
  );
};
