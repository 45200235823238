import React from "react";
import PropTypes from "prop-types";

import TableSkeleton from "./Skeleton";

import { Wrapper, StyledTable } from "./styles";

const Table = ({ columns, data, sizerLabels, ...rest }) => {
  return (
    <Wrapper>
      <StyledTable
        columns={columns}
        dataSource={data}
        sizerLabels={sizerLabels}
        {...rest}
      />
    </Wrapper>
  );
};

Table.defaultProps = {
  sizerLabels: ["Mostrando", "solicitudes"],
};

Table.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      dataIndex: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
    })
  ),
  data: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
    })
  ),
  rowSelection: PropTypes.shape({
    type: PropTypes.oneOf(["checkbox", "radio"]),
  }),
  sizerLabels: PropTypes.array,
};

Table.Skeleton = TableSkeleton;

export default Table;
