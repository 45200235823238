import api from "../../../core/api";
import { useQuery } from "react-query";

const useGetStatus = () => {
  const data = useQuery(
    ["getStatus"],
    () => api.get(`/orden_servicio/estado_orden_servicio/`),
    { refetchOnWindowFocus: false }
  );

  return data;
};

export default useGetStatus;
