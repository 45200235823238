import { useMutation } from "react-query";
import api from "../../../core/api";

const useSaveResponsible = () => {
  const mutation = useMutation((formData) => {
    return api.post("/ensayo/auxiliar_operativo_ensayo/", formData);
  });
  return mutation;
};
export default useSaveResponsible;
