import styled from "styled-components";
import { Input } from "antd";
import { theme } from "../../styles/theme";

export const CustomInputPassword = styled(Input.Password)`
  width: ${({ width }) => width || "300px"};
  height: ${({ height }) => height || "40px"};
  border: 1px solid ${theme.colors.light2};
  border-radius: ${({ radius }) => radius || "8px"};
  padding: 0 16px;
  opacity: 1;
  background: ${theme.colors.white};

  && {
    &.ant-input-affix-wrapper {
      .ant-input {
        color: ${theme.colors.grey};
        size: 16px;

        &::placeholder {
          color: ${theme.colors.gray2};
        }
      }

      &-focused {
        border: 1px solid ${theme.colors.light2} !important;
        box-shadow: initial;
      }

      &:hover {
        border: 1px solid ${theme.colors.light2} !important;
      }

      .anticon.ant-input-password-icon {
        color: ${theme.colors.light2};

        svg {
          width: 22px;
          height: 18px;
        }
      }

      .anticon.ant-input-password-icon {
        &:hover {
          color: ${theme.colors.light2};
        }
      }
    }
  }
`;
