import { styled } from "styled-components";
import background from "../../assets/images/login-background.png";
import { theme } from "../../styles/theme";

export const WrapperLogin = styled.div`
  display: flex;
  flex-direction: column;
  background-image: url(${background});
  background-size: cover;
  max-height: 100vh;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
`;

export const WrapperLogo = styled.div`
  display: flex;
  position: absolute;
  bottom: 5vh;
  right: 5vw;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    bottom: 3vh;
    right: 3vw;
  }
`;

export const CardLogin = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${theme.colors.gray};
  position: absolute;
  top: 50%;
  right: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 15px;
  width: 400px;
  justify-content: center;
  align-items: center;
  padding: 30px 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 300px;
    padding: 30px 0;
    gap: 60px;
  }
`;
