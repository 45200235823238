import React from "react";
import { Flex, Text } from "../../styles/common";
import { CustomInputPassword } from "./styles";
import { theme } from "../../styles/theme";
import { Tooltip } from "antd";
import info from "../../assets/icons/info.svg";

export const Password = ({
  placeholder,
  suffix,
  helper,
  error,
  disabled,
  onChange,
  onPressEnter,
  width,
  label,
  colorLabel,
  sizeLabel,
  weightLabel,
  tooltip,
  ...rest
}) => {
  return (
    <div style={{ alignSelf: "start" }}>
      {label && (
        <Flex align="center" gap="10px" m="0 0 8px 0">
          <Text
            size={sizeLabel || theme.fonts.size.sm}
            color={colorLabel || theme.colors.dark}
            weight={weightLabel || theme.fonts.weight.medium}
            style={{ textAlign: "start" }}
          >
            {label}
          </Text>
          {tooltip && (
            <Tooltip title={tooltip} color={theme.colors.gray}>
              <img alt="info" src={info} height={"16px"} />
            </Tooltip>
          )}
        </Flex>
      )}
      <CustomInputPassword
        placeholder={placeholder}
        suffix={suffix}
        status={error ? "error" : ""}
        disabled={disabled}
        onChange={onChange}
        onPressEnter={onPressEnter}
        {...rest}
      />
      {helper && (
        <Text size={theme.fonts.size.sm} color={theme.colors.red} mt="2px">
          {helper}
        </Text>
      )}
    </div>
  );
};
