import { styled } from "styled-components";
import { theme } from "../../../styles/theme";

export const WrapperMenuMobile = styled.div`
  display: flex;
  width: 100%;
  height: 70px;
  padding: 0px 30px;
  background-color: ${theme.colors.white};
  box-shadow: ${(props) => theme.elevation[`z${props.elevation}`]};
  border-radius: ${(props) => props.radius}px;
  justify-content: space-between;
  align-items: center;
`;
