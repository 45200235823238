import React from "react";
import PropTypes from "prop-types";
import { Skeleton as AntSkeleton } from "antd";

import SkeletonTable from "./Table";

const Skeleton = ({
  loading,
  active,
  round,
  title,
  paragraph,
  children,
  ...rest
}) => {
  return (
    <AntSkeleton
      loading={loading}
      active={active}
      round={round}
      title={title}
      paragraph={paragraph}
      {...rest}
    >
      {children}
    </AntSkeleton>
  );
};

Skeleton.defaultProps = {
  loading: false,
  active: true,
  round: false,
  title: true,
};

Skeleton.propTypes = {
  loading: PropTypes.bool,
  active: PropTypes.bool,
  round: PropTypes.bool,
  title: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  ]),
  paragraph: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      rows: PropTypes.number,
      width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  ]),
  children: PropTypes.node,
};

Skeleton.Table = SkeletonTable;

export default Skeleton;
