import styled from "styled-components";
import { Modal } from "antd";
import { theme } from "../../styles/theme";

export const StyledAntModal = styled(Modal)`
  width: ${(props) => props.width || "520px"};

  &&& {
    .ant-modal {
      &-content {
        text-align: ${(props) => props.align};
        border-radius: 16px;
      }

      &-close {
        right: 26px;

        &-x {
          color: ${theme.colors.green};
          width: 30px;
          height: 30px;
        }

        &:hover {
          background: transparent;
        }
      }

      &-body {
        padding: ${(props) => props.padding};
      }
    }
  }
`;
