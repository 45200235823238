import styled from "styled-components";
import { Button } from "antd";
import { theme } from "../../styles/theme";

export const CustomLink = styled(Button)`
  display: flex;
  padding: 0px;
  color: ${({ color }) => color || theme.colors.black};
  font-size: ${({ size }) => size || theme.fonts.size.default};
  align-items: center;

  && {
    &.ant-btn-link {
      &:hover {
        color: ${({ color }) => color || theme.colors.black};
      }
    }

    &.ant-btn-default {
      &:hover {
        color: ${({ color }) => color || theme.colors.black};
      }
    }
  }
`;
