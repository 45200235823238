/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Select } from "../../../components/Select";
import { Alert, DatePicker } from "antd";
import { Flex, Text } from "../../../styles/common";
import { theme } from "../../../styles/theme";
import { WrapperForm } from "../styles";
import { Button } from "../../../components/Button";
import { validateGeneralInfo } from "../../../utilities/validations";
import useSaveGeneralInfo from "../hook/useSaveGeneralInfo";
import moment from "moment";
import useWorkLine from "../hook/useWorkLine";
import useGetTransport from "../hook/useGetTransport";
import useGetStatus from "../hook/useGetStatus";
import useGetOrder from "../hook/useGetOrder";
import { useLocation } from "react-router-dom";
import useGetClients from "../hook/getClients";
import { getUser } from "../../../utilities/helpers";
import { useMediaQuery } from "react-responsive";

export const Step1 = ({
  step,
  setStep,
  setWorkLine,
  setOrderId,
  noSamples,
}) => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.sm})`,
  });

  const location = useLocation();
  const { search } = location;
  const searchParams = new URLSearchParams(search);
  const id = searchParams.get("id");
  const [idOrder, setIdOrder] = useState(null);
  const serviceOrder = useGetOrder(idOrder);
  const { data: dataOrder, isSuccess: isSuccessOrder } = serviceOrder;
  const [orderInfo, setOrderInfo] = useState({});
  const workLine = useWorkLine();
  const { data, isSuccess } = workLine;
  const [workLineOptions, setWorkLineOptions] = useState([]);
  const transport = useGetTransport();
  const { data: transportData, isSuccess: isSuccessTransport } = transport;
  const [transportOptions, setTransportOptions] = useState([]);
  const status = useGetStatus();
  const { data: statusData, isSuccess: isSuccessStatus } = status;
  const [statusOptions, setStatusOptions] = useState([]);
  const clients = useGetClients();
  const { data: clientsData, isSuccess: isSuccessClients } = clients;
  const [clientsOptions, setClientsOptions] = useState([]);
  const save = useSaveGeneralInfo();
  const { isLoading } = save;

  const defaultValues = {
    linea_trabajo: null,
    transporte: null,
    cliente_proyecto: null,
    fecha_ingreso_muestras: null,
  };

  const defaultErrorValues = {
    linea_trabajo: { error: false, message: "" },
    transporte: { error: false, message: "" },
    cliente_proyecto: { error: false, message: "" },
    fecha_ingreso_muestras: { error: false, message: "" },
  };

  const [generalInfo, setGeneralInfo] = useState({});
  const [errorForm, setErrorForm] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [forceUpdate, setForceUpdate] = useState(false);

  useEffect(() => {
    if (isSuccessOrder && dataOrder?.data) {
      setOrderInfo(dataOrder?.data);
      setForceUpdate(!forceUpdate);
    }
  }, [isSuccessOrder, dataOrder]);

  useEffect(() => {
    if (isSuccess) {
      setWorkLineOptions(
        data?.data.map((ele) => ({
          label: ele.nombre,
          value: ele.id,
        }))
      );
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isSuccessTransport) {
      setTransportOptions(
        transportData?.data.map((ele) => ({
          label: ele.quien_transporta,
          value: ele.id,
        }))
      );
    }
  }, [isSuccessTransport]);

  useEffect(() => {
    if (isSuccessClients) {
      setClientsOptions(
        clientsData?.data.map((ele) => ({
          label: ele.proyecto,
          value: ele.id,
        }))
      );
    }
  }, [isSuccessClients]);

  useEffect(() => {
    if (isSuccessStatus) {
      setStatusOptions(statusData?.data);
    }
  }, [isSuccessStatus]);

  useEffect(() => {
    if (id) {
      setIdOrder(id);
      setForceUpdate(!forceUpdate);
    } else {
      setIdOrder(null);
      setForceUpdate(!forceUpdate);
    }
  }, [id]);

  useEffect(() => {
    if (idOrder) {
      setGeneralInfo({
        linea_trabajo: orderInfo.linea_trabajo,
        transporte: orderInfo.transporte,
        cliente_proyecto: orderInfo.cliente_proyecto,
        fecha_ingreso_muestras: moment(orderInfo.fecha_ingreso_muestras),
      });
      setOrderId(id);
      const workLine = workLineOptions.find(
        (ele) => ele.value === orderInfo?.linea_trabajo
      )?.label;
      setWorkLine(workLine);
      setErrorForm(defaultErrorValues);
      setForceUpdate(!forceUpdate);
    } else {
      setGeneralInfo(defaultValues);
      setErrorForm(defaultErrorValues);
      setForceUpdate(!forceUpdate);
    }
  }, [idOrder, orderInfo]);

  const handleChangeSelect = (e, id) => {
    const newForm = { ...generalInfo };
    const newErrorForm = { ...errorForm };

    newForm[id] = e;
    newErrorForm[id].error = false;
    newErrorForm[id].message = "";

    setGeneralInfo(newForm);
    setErrorForm(newErrorForm);
    setForceUpdate(!forceUpdate);
  };

  const reset = () => {
    setGeneralInfo(defaultValues);
    setErrorForm(defaultErrorValues);
    setForceUpdate(!forceUpdate);
  };

  const handleUpdate = () => {
    if (noSamples) {
      setStep(step + 1);
    } else {
      setStep(step + 2);
    }
  };

  const handleSave = () => {
    const validation = validateGeneralInfo.validate(
      { ...generalInfo },
      { abortEarly: false }
    );

    if (validation.error) {
      const newErrorForm = errorForm;
      validation.error.details.forEach((ele) => {
        newErrorForm[ele.context.label].error = true;
        newErrorForm[ele.context.label].message =
          "Campo requerido o formato inválido";
        setErrorForm(newErrorForm);
        setForceUpdate(!forceUpdate);
      });
    } else {
      save.reset();
      const status = statusOptions.find(
        (ele) => ele.descripcion === "pendiente_diligenciar"
      ).id;
      save.mutate(
        {
          ...generalInfo,
          estado_orden: status,
          usuario: getUser().id,
        },
        {
          onSuccess: (data) => {
            setOrderId(data?.data?.id);
            const workLine = workLineOptions.find(
              (ele) => ele.value === data?.data?.linea_trabajo
            ).label;
            setWorkLine(workLine);
            reset();
            setStep(step + 1);
            setForceUpdate(!forceUpdate);
          },
          onError: () => {
            setErrorMessage(
              "Ha ocurrido un error, por favor comunicate con nuestra mesa de ayuda mesaayuda@conconcreto.com"
            );
          },
        }
      );
    }
  };

  const handleClose = () => {
    setErrorMessage("");
  };

  const disabledDate = (current) => {
    return current && current < moment().subtract(1, "days");
  };

  return (
    <>
      <WrapperForm>
        <Flex direction="column" gap="30px">
          <Text
            weight={theme.fonts.weight.bold}
            size={theme.fonts.size.h6}
            style={{
              textAlign: "start",
              marginTop: isMobile ? "20px" : "70px",
            }}
          >
            Información general
          </Text>
          <Flex
            gap={isMobile ? "20px" : "60px"}
            direction={isMobile ? "column" : "row"}
          >
            <Select
              label={"Línea de trabajo"}
              value={generalInfo.linea_trabajo}
              options={workLineOptions}
              onChange={(e) => handleChangeSelect(e, "linea_trabajo")}
              placeholder={"Selecciona la línea de trabajo"}
              error={errorForm.linea_trabajo?.error}
              helper={
                errorForm.linea_trabajo?.error
                  ? errorForm.linea_trabajo?.message
                  : ""
              }
              disabled={idOrder}
            />
            <Select
              label={"Responsable del transporte"}
              value={generalInfo.transporte}
              options={transportOptions}
              onChange={(e) => handleChangeSelect(e, "transporte")}
              placeholder={"Selecciona el responsable del transporte"}
              error={errorForm.transporte?.error}
              helper={
                errorForm.transporte?.error ? errorForm.transporte?.message : ""
              }
              disabled={idOrder}
            />
          </Flex>
          <Flex
            gap={isMobile ? "20px" : "60px"}
            direction={isMobile ? "column" : "row"}
          >
            <Select
              label={"Cliente/Proyecto"}
              value={generalInfo.cliente_proyecto}
              options={clientsOptions}
              placeholder={"Ingrese el nombre del proyecto"}
              onChange={(e) => handleChangeSelect(e, "cliente_proyecto")}
              error={errorForm.cliente_proyecto?.error}
              helper={
                errorForm.cliente_proyecto?.error
                  ? errorForm.cliente_proyecto?.message
                  : ""
              }
              disabled={idOrder}
            />
            <Flex direction="column">
              <Text
                size={theme.fonts.size.sm}
                color={theme.colors.dark}
                weight={theme.fonts.weight.medium}
                mb="8px"
                style={{ textAlign: "start" }}
              >
                Fecha de envío al laboratorio
              </Text>
              <DatePicker
                id="fecha_ingreso_muestras"
                value={generalInfo.fecha_ingreso_muestras}
                onChange={(e) =>
                  handleChangeSelect(e, "fecha_ingreso_muestras")
                }
                status={errorForm.fecha_ingreso_muestras?.error ? "error" : ""}
                disabledDate={disabledDate}
                style={{ width: "300px", height: "40px" }}
                disabled={idOrder}
              />
              {errorForm.fecha_ingreso_muestras?.error && (
                <Text
                  size={theme.fonts.size.sm}
                  color={theme.colors.red}
                  mt="2px"
                  style={{ textAlign: "-webkit-left" }}
                >
                  {errorForm.fecha_ingreso_muestras?.message}
                </Text>
              )}
            </Flex>
          </Flex>
        </Flex>
      </WrapperForm>
      {errorMessage !== "" && (
        <Flex m="30px 0 0 0" justify="flex-end">
          <Alert
            closable
            message={`${errorMessage}`}
            type="error"
            onClose={handleClose}
          />
        </Flex>
      )}
      <Flex
        m={errorMessage !== "" ? "10px 0 0 0" : "50px 0 0 0"}
        justify="flex-end"
      >
        <Button
          background={theme.colors.orange}
          hover={theme.colors.orangeHover}
          loading={isLoading}
          onClick={idOrder ? handleUpdate : handleSave}
          style={{ width: "150px" }}
        >
          Siguiente
        </Button>
      </Flex>
    </>
  );
};
