import api from "../../../core/api";
import { useQuery } from "react-query";

const useGetTracking = (id, role) => {
  const data = useQuery(
    ["getTrackingInfo", id],
    () => api.get(`/orden_servicio/bitacora_laboratorio/?id_orden=${id}`),
    { enabled: !!id && role }
  );

  return data;
};

export default useGetTracking;
