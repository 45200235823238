import React, { useEffect, useState } from "react";
import logoccc from "../../../assets/images/logo-ccc.png";
import CylinderCompressionType1 from "../../../assets/images/CylinderCompressionType1.png";
import CylinderCompressionType2 from "../../../assets/images/CylinderCompressionType2.png";
import CylinderCompressionType3 from "../../../assets/images/CylinderCompressionType3.png";
import CylinderCompressionType4 from "../../../assets/images/CylinderCompressionType4.png";
import CylinderCompressionType5 from "../../../assets/images/CylinderCompressionType5.png";
import CylinderCompressionType6 from "../../../assets/images/CylinderCompressionType6.png";
import SplitTractionType1 from "../../../assets/images/SplitTractionType1.png";
import SplitTractionType2 from "../../../assets/images/SplitTractionType2.png";
import SplitTractionType3 from "../../../assets/images/SplitTractionType3.png";
import SplitTractionType4 from "../../../assets/images/SplitTractionType4.png";
import ConcreteflexingTypeA from "../../../assets/images/ConcreteflexingTypeA.png";
import ConcreteflexingTypeB from "../../../assets/images/ConcreteflexingTypeB.png";
import { dateFormatHour } from "../../../utilities/helpers";
import {
  columnsLabelsReportCylinderCompression,
  columnsLabelsReportSplitTraction,
  columnsLabelsReportConcreteflexing,
  columnsLabelsReportCubesCompression,
  columnsDataReportCylinderCompression,
  columnsDataReportSplitTraction,
  columnsDataReportConcreteflexing,
  columnsDataReportCubesCompression,
} from "../../../utilities/helpers";

import {
  Document,
  Page,
  View,
  Text,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";

export const Pdf = ({ reportData, dateTimeNowFormatted }) => {
  const styles = StyleSheet.create({
    container: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
    containerFirmasAuxiliares: {
      flexDirection: "row",
    },
    containerFirmasCoordinadorDirector: {
      margin: "20 0 0 0",
    },
    logo: {
      maxWidth: "140px",
      maxHeight: "120px",
      margin: "0 80 0 0",
    },
    text: {
      fontSize: 8,
      textAlign: "center",
      textTransform: "uppercase",
    },
    tittle: {
      marginBottom: 15,
      fontSize: 8,
      textAlign: "center",
      textTransform: "uppercase",
    },
    tittleLeft: {
      marginBottom: 15,
      fontSize: 8,
      textAlign: "start",
      textTransform: "uppercase",
    },
    subTableContainer: {
      margin: "0 0 0 auto",
      width: "15%",
      border: "1px solid #000",
      fontSize: 8,
    },
    subTableRow: {
      flexDirection: "row",
      border: "1px solid #000",
    },
    subTableColumnTittle: {
      textAlign: "center",
      width: "50%",
      border: "1px solid #000",
      padding: 3,
      fontSize: 8,
      backgroundColor: "#dee2e6",
    },
    subTableColumnText: {
      textAlign: "center",
      width: "50%",
      border: "1px solid #000",
      padding: 3,
      fontSize: 8,
    },
    centeredTableContainerEquipment: {
      width: "30%",
      fontSize: 8,
    },
    centeredTableContainer: {
      width: "100%",
      fontSize: 8,
      margin: "0 auto 15 auto",
    },
    centeredTableRow: {
      flexDirection: "row",
      border: "1px solid #000",
    },
    centeredTableColumn: {
      textAlign: "center",
      width: "50%",
      border: "1px solid #000",
      padding: 3,
      fontSize: 8,
      textTransform: "uppercase",
    },
    centeredTableColumnHeader: {
      textAlign: "center",
      width: "50%",
      border: "1px solid #000",
      padding: 3,
      backgroundColor: "#dee2e6",
      fontSize: 8,
      textTransform: "uppercase",
    },
    imageTipoFalla: {
      width: 100,
      height: 100,
      margin: 5,
    },
    imageFirma: {
      textAlign: "center",
      width: 150,
      height: 50,
      border: "1px solid #000",
      padding: 3,
      margin: 5,
    },
    textFirma: { fontSize: 8, textTransform: "uppercase" },
    pageNumber: {
      position: "absolute",
      bottom: 10,
      right: 10,
      fontSize: 10,
    },
    page: {
      padding: "20 20 0 20",
    },
  });

  const tiposFallaRenderizadas = new Set();
  const firmasRenderizadas = new Set();

  return (
    <Document>
      <Page size="LEGAL" orientation="landscape" style={styles.page}>
        {reportData &&
          Object.keys(reportData).map((tipoEnsayo) => (
            <View key={tipoEnsayo}>
              {tipoEnsayo === "NC1" && (
                <>
                  {reportData[tipoEnsayo].map((ensayo) => (
                    <>
                      <View wrap={false}>
                        <View style={styles.container}>
                          <Image
                            src={logoccc}
                            alt="Logo CC"
                            style={styles.logo}
                          />
                          <View style={styles.subTableContainer}>
                            <View style={styles.subTableRow}>
                              <Text style={styles.subTableColumnTittle}>
                                Formato
                              </Text>
                              <Text style={styles.subTableColumnText}>
                                F-LAB-300
                              </Text>
                            </View>
                            <View style={styles.subTableRow}>
                              <Text style={styles.subTableColumnTittle}>
                                Version
                              </Text>
                              <Text style={styles.subTableColumnText}>04</Text>
                            </View>
                            <View style={styles.subTableRow}>
                              <Text style={styles.subTableColumnTittle}>
                                Fecha
                              </Text>
                              <Text style={styles.subTableColumnText}>
                                09/06/2023
                              </Text>
                            </View>
                          </View>
                        </View>
                        <View>
                          <Text style={styles.tittle}>
                            INFORME DE{" "}
                            {
                              ensayo.data_calculos.campos_ensayo[0]
                                .nombre_ensayo
                            }
                          </Text>
                          <Text style={styles.tittle}>
                            PROYECTO:{" "}
                            {ensayo.data_calculos.campos_ensayo[0].proyecto}
                          </Text>
                          <Text style={styles.tittleLeft}>
                            DATOS SUMINISTRADOS POR EL CLIENTE
                          </Text>
                        </View>
                        <View style={styles.centeredTableContainer}>
                          <View style={styles.centeredTableRow}>
                            <Text style={styles.centeredTableColumnHeader}>
                              NORMATIVA:
                            </Text>
                            <Text style={styles.centeredTableColumn}>
                              {ensayo.data_calculos.campos_ensayo[0].normativa}
                            </Text>
                            <Text style={styles.centeredTableColumnHeader}>
                              ORDEN DE SERVICIO:
                            </Text>
                            <Text style={styles.centeredTableColumn}>
                              {
                                ensayo.data_calculos.campos_ensayo[0]
                                  .id_orden_servicio
                              }
                            </Text>
                            <Text style={styles.centeredTableColumnHeader}>
                              REMISION N°:
                            </Text>
                            <Text style={styles.centeredTableColumn}>
                              82569233
                            </Text>
                          </View>
                          <View style={styles.centeredTableRow}>
                            <Text style={styles.centeredTableColumnHeader}>
                              DESCRIPCION:
                            </Text>
                            <Text style={styles.centeredTableColumn}>
                              {
                                ensayo.data_calculos.campos_ensayo[0]
                                  .descripcion_orden
                              }
                            </Text>
                            <Text style={styles.centeredTableColumnHeader}>
                              CODIGO DE DISEÑO:
                            </Text>
                            <Text style={styles.centeredTableColumn}>
                              {
                                ensayo.data_calculos.campos_ensayo[0]
                                  .codigo_diseño_mezcla
                              }
                            </Text>
                            <Text style={styles.centeredTableColumnHeader}>
                              FUENTE:
                            </Text>
                            <Text style={styles.centeredTableColumn}>
                              {ensayo.data_calculos.campos_ensayo[0].proveedor}
                            </Text>
                          </View>
                        </View>
                        <View style={styles.centeredTableContainer}>
                          <View style={styles.centeredTableRow}>
                            <Text style={styles.centeredTableColumnHeader}>
                              MUESTRA:
                            </Text>
                            <Text style={styles.centeredTableColumn}>
                              {ensayo.data_calculos.codigo_mezcla}
                            </Text>
                            <Text style={styles.centeredTableColumnHeader}>
                              ELEMENTO:
                            </Text>
                            <Text style={styles.centeredTableColumn}>
                              {ensayo.data_calculos.numero_remision}
                            </Text>
                            <Text style={styles.centeredTableColumnHeader}>
                              SLUMP:
                            </Text>
                            <Text style={styles.centeredTableColumn}>
                              {ensayo.data_calculos.campos_ensayo[0].slump}
                            </Text>
                          </View>
                          <View style={styles.centeredTableRow}>
                            <Text style={styles.centeredTableColumnHeader}>
                              RESISTENCIA NOMINAL:
                            </Text>
                            <Text style={styles.centeredTableColumn}>
                              {
                                ensayo.data_calculos.campos_ensayo[0]
                                  .resistencia_nominal
                              }
                            </Text>
                            <Text style={styles.centeredTableColumnHeader}>
                              TEMPERATURA TOMA DE MUESTRAS:
                            </Text>
                            <Text style={styles.centeredTableColumn}>
                              {
                                ensayo.data_calculos.campos_ensayo[0]
                                  .temperatura_OS
                              }
                            </Text>
                            <Text style={styles.centeredTableColumn}></Text>
                            <Text style={styles.centeredTableColumn}></Text>
                          </View>
                        </View>
                      </View>
                      <View>
                        <Text style={styles.tittleLeft}>
                          DATOS SUMINISTRADOS POR EL LABORATORIO
                        </Text>
                      </View>
                      <View style={styles.centeredTableContainer}>
                        <View style={styles.centeredTableRow}>
                          {columnsDataReportCylinderCompression.map(
                            (columna) => (
                              <Text
                                key={columna}
                                style={styles.centeredTableColumnHeader}
                              >
                                {
                                  columnsLabelsReportCylinderCompression[
                                    columna
                                  ]
                                }
                              </Text>
                            )
                          )}
                        </View>
                        {ensayo.data_calculos.campos_ensayo.map(
                          (ensayo, index) => (
                            <View
                              key={index}
                              style={styles.centeredTableRow}
                              wrap={false}
                            >
                              {columnsDataReportCylinderCompression.map(
                                (columna) => (
                                  <Text
                                    key={columna}
                                    style={styles.centeredTableColumn}
                                  >
                                    {columna === "fecha_rotura" ||
                                    columna === "fecha_toma"
                                      ? dateFormatHour(ensayo[columna])
                                      : ensayo[columna]}
                                  </Text>
                                )
                              )}
                            </View>
                          )
                        )}
                      </View>
                      <View wrap={false}>
                        <Text style={styles.tittleLeft}>
                          RELACIÓN EQUIPOS EMPLEADOS / ID EQUIPO
                        </Text>
                        <View style={styles.centeredTableContainerEquipment}>
                          <View style={styles.centeredTableRow}>
                            <Text style={styles.centeredTableColumnHeader}>
                              NOMBRE
                            </Text>
                            <Text style={styles.centeredTableColumnHeader}>
                              CÓDIGO
                            </Text>
                          </View>
                          {ensayo.data_calculos.equipos.map((equipo) => (
                            <View style={styles.centeredTableRow}>
                              <Text style={styles.centeredTableColumn}>
                                {equipo.nombre}
                              </Text>
                              <Text style={styles.centeredTableColumn}>
                                {equipo.codigo}
                              </Text>
                            </View>
                          ))}
                        </View>
                      </View>
                    </>
                  ))}
                </>
              )}
              {tipoEnsayo === "NC2" && (
                <>
                  {reportData[tipoEnsayo].map((ensayo) => (
                    <>
                      <View wrap={false}>
                        <View style={styles.container}>
                          <Image
                            src={logoccc}
                            alt="Logo CC"
                            style={styles.logo}
                          />
                          <View style={styles.subTableContainer}>
                            <View style={styles.subTableRow}>
                              <Text style={styles.subTableColumnTittle}>
                                Formato
                              </Text>
                              <Text style={styles.subTableColumnText}>
                                F-LAB-199
                              </Text>
                            </View>
                            <View style={styles.subTableRow}>
                              <Text style={styles.subTableColumnTittle}>
                                Version
                              </Text>
                              <Text style={styles.subTableColumnText}>05</Text>
                            </View>
                            <View style={styles.subTableRow}>
                              <Text style={styles.subTableColumnTittle}>
                                Fecha
                              </Text>
                              <Text style={styles.subTableColumnText}>
                                09/06/2023
                              </Text>
                            </View>
                          </View>
                        </View>
                        <View>
                          <Text style={styles.tittle}>
                            INFORME DE{" "}
                            {
                              ensayo.data_calculos.campos_ensayo[0]
                                .nombre_ensayo
                            }
                          </Text>
                          <Text style={styles.tittle}>
                            PROYECTO:{" "}
                            {ensayo.data_calculos.campos_ensayo[0].proyecto}
                          </Text>
                          <Text style={styles.tittleLeft}>
                            DATOS SUMINISTRADOS POR EL CLIENTE
                          </Text>
                        </View>
                        <View style={styles.centeredTableContainer}>
                          <View style={styles.centeredTableRow}>
                            <Text style={styles.centeredTableColumnHeader}>
                              NORMATIVA:
                            </Text>
                            <Text style={styles.centeredTableColumn}>
                              {ensayo.data_calculos.campos_ensayo[0].normativa}
                            </Text>
                            <Text style={styles.centeredTableColumnHeader}>
                              ORDEN DE SERVICIO:
                            </Text>
                            <Text style={styles.centeredTableColumn}>
                              {
                                ensayo.data_calculos.campos_ensayo[0]
                                  .id_orden_servicio
                              }
                            </Text>
                            <Text style={styles.centeredTableColumnHeader}>
                              REMISION N°:
                            </Text>
                            <Text style={styles.centeredTableColumn}>
                              82569233
                            </Text>
                          </View>
                          <View style={styles.centeredTableRow}>
                            <Text style={styles.centeredTableColumnHeader}>
                              DESCRIPCION:
                            </Text>
                            <Text style={styles.centeredTableColumn}>
                              {
                                ensayo.data_calculos.campos_ensayo[0]
                                  .descripcion_orden
                              }
                            </Text>
                            <Text style={styles.centeredTableColumnHeader}>
                              CODIGO DE DISEÑO:
                            </Text>
                            <Text style={styles.centeredTableColumn}>
                              {
                                ensayo.data_calculos.campos_ensayo[0]
                                  .codigo_diseño_mezcla
                              }
                            </Text>
                            <Text style={styles.centeredTableColumnHeader}>
                              FUENTE:
                            </Text>
                            <Text style={styles.centeredTableColumn}>
                              {ensayo.data_calculos.campos_ensayo[0].proveedor}
                            </Text>
                          </View>
                        </View>
                        <View style={styles.centeredTableContainer}>
                          <View style={styles.centeredTableRow}>
                            <Text style={styles.centeredTableColumnHeader}>
                              MUESTRA:
                            </Text>
                            <Text style={styles.centeredTableColumn}>
                              {ensayo.data_calculos.codigo_mezcla}
                            </Text>
                            <Text style={styles.centeredTableColumnHeader}>
                              ELEMENTO:
                            </Text>
                            <Text style={styles.centeredTableColumn}>
                              {ensayo.data_calculos.numero_remision}
                            </Text>
                            <Text style={styles.centeredTableColumnHeader}>
                              SLUMP:
                            </Text>
                            <Text style={styles.centeredTableColumn}>
                              {ensayo.data_calculos.campos_ensayo[0].slump}
                            </Text>
                          </View>
                          <View style={styles.centeredTableRow}>
                            <Text style={styles.centeredTableColumnHeader}>
                              RESISTENCIA NOMINAL:
                            </Text>
                            <Text style={styles.centeredTableColumn}>
                              {
                                ensayo.data_calculos.campos_ensayo[0]
                                  .resistencia_nominal
                              }
                            </Text>
                            <Text style={styles.centeredTableColumnHeader}>
                              TEMPERATURA TOMA DE MUESTRAS:
                            </Text>
                            <Text style={styles.centeredTableColumn}>
                              {
                                ensayo.data_calculos.campos_ensayo[0]
                                  .temperatura_OS
                              }
                            </Text>
                            <Text style={styles.centeredTableColumn}></Text>
                            <Text style={styles.centeredTableColumn}></Text>
                          </View>
                        </View>
                      </View>
                      <View>
                        <Text style={styles.tittleLeft}>
                          DATOS SUMINISTRADOS POR EL LABORATORIO
                        </Text>
                      </View>
                      <View style={styles.centeredTableContainer}>
                        <View style={styles.centeredTableRow}>
                          {columnsDataReportSplitTraction.map((columna) => (
                            <Text
                              key={columna}
                              style={styles.centeredTableColumnHeader}
                            >
                              {columnsLabelsReportSplitTraction[columna]}
                            </Text>
                          ))}
                        </View>
                        {ensayo.data_calculos.campos_ensayo.map(
                          (ensayo, index) => (
                            <View
                              key={index}
                              style={styles.centeredTableRow}
                              wrap={false}
                            >
                              {columnsDataReportSplitTraction.map((columna) => (
                                <Text
                                  key={columna}
                                  style={styles.centeredTableColumn}
                                >
                                  {columna === "fecha_rotura" ||
                                  columna === "fecha_toma"
                                    ? dateFormatHour(ensayo[columna])
                                    : ensayo[columna]}
                                </Text>
                              ))}
                            </View>
                          )
                        )}
                      </View>
                      <View wrap={false}>
                        <Text style={styles.tittleLeft}>
                          RELACIÓN EQUIPOS EMPLEADOS / ID EQUIPO
                        </Text>
                        <View style={styles.centeredTableContainerEquipment}>
                          <View style={styles.centeredTableRow}>
                            <Text style={styles.centeredTableColumnHeader}>
                              NOMBRE
                            </Text>
                            <Text style={styles.centeredTableColumnHeader}>
                              CÓDIGO
                            </Text>
                          </View>
                          {ensayo.data_calculos.equipos.map((equipo) => (
                            <View style={styles.centeredTableRow}>
                              <Text style={styles.centeredTableColumn}>
                                {equipo.nombre}
                              </Text>
                              <Text style={styles.centeredTableColumn}>
                                {equipo.codigo}
                              </Text>
                            </View>
                          ))}
                        </View>
                      </View>
                    </>
                  ))}
                </>
              )}
              {tipoEnsayo === "NC3" && (
                <>
                  {reportData[tipoEnsayo].map((ensayo) => (
                    <>
                      <View wrap={false}>
                        <View style={styles.container}>
                          <Image
                            src={logoccc}
                            alt="Logo CC"
                            style={styles.logo}
                          />
                          <View style={styles.subTableContainer}>
                            <View style={styles.subTableRow}>
                              <Text style={styles.subTableColumnTittle}>
                                Formato
                              </Text>
                              <Text style={styles.subTableColumnText}>
                                F-LAB-325
                              </Text>
                            </View>
                            <View style={styles.subTableRow}>
                              <Text style={styles.subTableColumnTittle}>
                                Version
                              </Text>
                              <Text style={styles.subTableColumnText}>04</Text>
                            </View>
                            <View style={styles.subTableRow}>
                              <Text style={styles.subTableColumnTittle}>
                                Fecha
                              </Text>
                              <Text style={styles.subTableColumnText}>
                                09/06/2023
                              </Text>
                            </View>
                          </View>
                        </View>
                        <View>
                          <Text style={styles.tittle}>
                            INFORME DE{" "}
                            {
                              ensayo.data_calculos.campos_ensayo[0]
                                .nombre_ensayo
                            }
                          </Text>
                          <Text style={styles.tittle}>
                            PROYECTO:{" "}
                            {ensayo.data_calculos.campos_ensayo[0].proyecto}
                          </Text>
                          <Text style={styles.tittleLeft}>
                            DATOS SUMINISTRADOS POR EL CLIENTE
                          </Text>
                        </View>
                        <View style={styles.centeredTableContainer}>
                          <View style={styles.centeredTableRow}>
                            <Text style={styles.centeredTableColumnHeader}>
                              NORMATIVA:
                            </Text>
                            <Text style={styles.centeredTableColumn}>
                              {ensayo.data_calculos.campos_ensayo[0].normativa}
                            </Text>
                            <Text style={styles.centeredTableColumnHeader}>
                              ORDEN DE SERVICIO:
                            </Text>
                            <Text style={styles.centeredTableColumn}>
                              {
                                ensayo.data_calculos.campos_ensayo[0]
                                  .id_orden_servicio
                              }
                            </Text>
                            <Text style={styles.centeredTableColumnHeader}>
                              REMISION N°:
                            </Text>
                            <Text style={styles.centeredTableColumn}>
                              82569233
                            </Text>
                          </View>
                          <View style={styles.centeredTableRow}>
                            <Text style={styles.centeredTableColumnHeader}>
                              DESCRIPCION:
                            </Text>
                            <Text style={styles.centeredTableColumn}>
                              {
                                ensayo.data_calculos.campos_ensayo[0]
                                  .descripcion_orden
                              }
                            </Text>
                            <Text style={styles.centeredTableColumnHeader}>
                              CODIGO DE DISEÑO:
                            </Text>
                            <Text style={styles.centeredTableColumn}>
                              {
                                ensayo.data_calculos.campos_ensayo[0]
                                  .codigo_diseño_mezcla
                              }
                            </Text>
                            <Text style={styles.centeredTableColumnHeader}>
                              FUENTE:
                            </Text>
                            <Text style={styles.centeredTableColumn}>
                              {ensayo.data_calculos.campos_ensayo[0].proveedor}
                            </Text>
                          </View>
                        </View>
                        <View style={styles.centeredTableContainer}>
                          <View style={styles.centeredTableRow}>
                            <Text style={styles.centeredTableColumnHeader}>
                              MUESTRA:
                            </Text>
                            <Text style={styles.centeredTableColumn}>
                              {ensayo.data_calculos.codigo_mezcla}
                            </Text>
                            <Text style={styles.centeredTableColumnHeader}>
                              ELEMENTO:
                            </Text>
                            <Text style={styles.centeredTableColumn}>
                              {ensayo.data_calculos.numero_remision}
                            </Text>
                            <Text style={styles.centeredTableColumnHeader}>
                              SLUMP:
                            </Text>
                            <Text style={styles.centeredTableColumn}>
                              {ensayo.data_calculos.campos_ensayo[0].slump}
                            </Text>
                          </View>
                          <View style={styles.centeredTableRow}>
                            <Text style={styles.centeredTableColumnHeader}>
                              RESISTENCIA NOMINAL:
                            </Text>
                            <Text style={styles.centeredTableColumn}>
                              {
                                ensayo.data_calculos.campos_ensayo[0]
                                  .resistencia_nominal
                              }
                            </Text>
                            <Text style={styles.centeredTableColumnHeader}>
                              TEMPERATURA TOMA DE MUESTRAS:
                            </Text>
                            <Text style={styles.centeredTableColumn}>
                              {
                                ensayo.data_calculos.campos_ensayo[0]
                                  .temperatura_OS
                              }
                            </Text>
                            <Text style={styles.centeredTableColumn}></Text>
                            <Text style={styles.centeredTableColumn}></Text>
                          </View>
                        </View>
                      </View>
                      <View>
                        <Text style={styles.tittleLeft}>
                          DATOS SUMINISTRADOS POR EL LABORATORIO
                        </Text>
                      </View>
                      <View style={styles.centeredTableContainer}>
                        <View style={styles.centeredTableRow}>
                          {columnsDataReportConcreteflexing.map((columna) => (
                            <Text
                              key={columna}
                              style={styles.centeredTableColumnHeader}
                            >
                              {columnsLabelsReportConcreteflexing[columna]}
                            </Text>
                          ))}
                        </View>
                        {ensayo.data_calculos.campos_ensayo.map(
                          (ensayo, index) => (
                            <View
                              key={index}
                              style={styles.centeredTableRow}
                              wrap={false}
                            >
                              {columnsDataReportConcreteflexing.map(
                                (columna) => (
                                  <Text
                                    key={columna}
                                    style={styles.centeredTableColumn}
                                  >
                                    {columna === "fecha_rotura" ||
                                    columna === "fecha_toma"
                                      ? dateFormatHour(ensayo[columna])
                                      : ensayo[columna]}
                                  </Text>
                                )
                              )}
                            </View>
                          )
                        )}
                      </View>
                      <View wrap={false}>
                        <Text style={styles.tittleLeft}>
                          RELACIÓN EQUIPOS EMPLEADOS / ID EQUIPO
                        </Text>
                        <View style={styles.centeredTableContainerEquipment}>
                          <View style={styles.centeredTableRow}>
                            <Text style={styles.centeredTableColumnHeader}>
                              NOMBRE
                            </Text>
                            <Text style={styles.centeredTableColumnHeader}>
                              CÓDIGO
                            </Text>
                          </View>
                          {ensayo.data_calculos.equipos.map((equipo) => (
                            <View style={styles.centeredTableRow}>
                              <Text style={styles.centeredTableColumn}>
                                {equipo.nombre}
                              </Text>
                              <Text style={styles.centeredTableColumn}>
                                {equipo.codigo}
                              </Text>
                            </View>
                          ))}
                        </View>
                      </View>
                    </>
                  ))}
                </>
              )}
              {tipoEnsayo === "NC4" && (
                <>
                  {reportData[tipoEnsayo].map((ensayo) => (
                    <>
                      <View wrap={false}>
                        <View style={styles.container}>
                          <Image
                            src={logoccc}
                            alt="Logo CC"
                            style={styles.logo}
                          />
                          <View style={styles.subTableContainer}>
                            <View style={styles.subTableRow}>
                              <Text style={styles.subTableColumnTittle}>
                                Formato
                              </Text>
                              <Text style={styles.subTableColumnText}>
                                F-LAB-335
                              </Text>
                            </View>
                            <View style={styles.subTableRow}>
                              <Text style={styles.subTableColumnTittle}>
                                Version
                              </Text>
                              <Text style={styles.subTableColumnText}>02</Text>
                            </View>
                            <View style={styles.subTableRow}>
                              <Text style={styles.subTableColumnTittle}>
                                Fecha
                              </Text>
                              <Text style={styles.subTableColumnText}>
                                16/06/2023
                              </Text>
                            </View>
                          </View>
                        </View>
                        <View>
                          <Text style={styles.tittle}>
                            INFORME DE{" "}
                            {
                              ensayo.data_calculos.campos_ensayo[0]
                                .nombre_ensayo
                            }
                          </Text>
                          <Text style={styles.tittle}>
                            PROYECTO:{" "}
                            {ensayo.data_calculos.campos_ensayo[0].proyecto}
                          </Text>
                          <Text style={styles.tittleLeft}>
                            DATOS SUMINISTRADOS POR EL CLIENTE
                          </Text>
                        </View>
                        <View style={styles.centeredTableContainer}>
                          <View style={styles.centeredTableRow}>
                            <Text style={styles.centeredTableColumnHeader}>
                              NORMATIVA:
                            </Text>
                            <Text style={styles.centeredTableColumn}>
                              {ensayo.data_calculos.campos_ensayo[0].normativa}
                            </Text>
                            <Text style={styles.centeredTableColumnHeader}>
                              ORDEN DE SERVICIO:
                            </Text>
                            <Text style={styles.centeredTableColumn}>
                              {
                                ensayo.data_calculos.campos_ensayo[0]
                                  .id_orden_servicio
                              }
                            </Text>
                            <Text style={styles.centeredTableColumnHeader}>
                              REMISION N°:
                            </Text>
                            <Text style={styles.centeredTableColumn}>
                              82569233
                            </Text>
                          </View>
                          <View style={styles.centeredTableRow}>
                            <Text style={styles.centeredTableColumnHeader}>
                              DESCRIPCION:
                            </Text>
                            <Text style={styles.centeredTableColumn}>
                              {
                                ensayo.data_calculos.campos_ensayo[0]
                                  .descripcion_orden
                              }
                            </Text>
                            <Text style={styles.centeredTableColumnHeader}>
                              CODIGO DE DISEÑO:
                            </Text>
                            <Text style={styles.centeredTableColumn}>
                              {
                                ensayo.data_calculos.campos_ensayo[0]
                                  .codigo_diseño_mezcla
                              }
                            </Text>
                            <Text style={styles.centeredTableColumnHeader}>
                              FUENTE:
                            </Text>
                            <Text style={styles.centeredTableColumn}>
                              {ensayo.data_calculos.campos_ensayo[0].proveedor}
                            </Text>
                          </View>
                        </View>
                        <View style={styles.centeredTableContainer}>
                          <View style={styles.centeredTableRow}>
                            <Text style={styles.centeredTableColumnHeader}>
                              MUESTRA:
                            </Text>
                            <Text style={styles.centeredTableColumn}>
                              {ensayo.data_calculos.codigo_mezcla}
                            </Text>
                            <Text style={styles.centeredTableColumnHeader}>
                              ELEMENTO:
                            </Text>
                            <Text style={styles.centeredTableColumn}>
                              {ensayo.data_calculos.numero_remision}
                            </Text>
                            <Text style={styles.centeredTableColumnHeader}>
                              SLUMP:
                            </Text>
                            <Text style={styles.centeredTableColumn}>
                              {ensayo.data_calculos.campos_ensayo[0].slump}
                            </Text>
                          </View>
                          <View style={styles.centeredTableRow}>
                            <Text style={styles.centeredTableColumnHeader}>
                              RESISTENCIA NOMINAL:
                            </Text>
                            <Text style={styles.centeredTableColumn}>
                              {
                                ensayo.data_calculos.campos_ensayo[0]
                                  .resistencia_nominal
                              }
                            </Text>
                            <Text style={styles.centeredTableColumnHeader}>
                              TEMPERATURA TOMA DE MUESTRAS:
                            </Text>
                            <Text style={styles.centeredTableColumn}>
                              {
                                ensayo.data_calculos.campos_ensayo[0]
                                  .temperatura_OS
                              }
                            </Text>
                            <Text style={styles.centeredTableColumn}></Text>
                            <Text style={styles.centeredTableColumn}></Text>
                          </View>
                        </View>
                      </View>
                      <View>
                        <Text style={styles.tittleLeft}>
                          DATOS SUMINISTRADOS POR EL LABORATORIO
                        </Text>
                      </View>
                      <View style={styles.centeredTableContainer}>
                        <View style={styles.centeredTableRow}>
                          {columnsDataReportCubesCompression.map((columna) => (
                            <Text
                              key={columna}
                              style={styles.centeredTableColumnHeader}
                            >
                              {columnsLabelsReportCubesCompression[columna]}
                            </Text>
                          ))}
                        </View>
                        {ensayo.data_calculos.campos_ensayo.map(
                          (ensayo, index) => (
                            <View
                              key={index}
                              style={styles.centeredTableRow}
                              wrap={false}
                            >
                              {columnsDataReportCubesCompression.map(
                                (columna) => (
                                  <Text
                                    key={columna}
                                    style={styles.centeredTableColumn}
                                  >
                                    {columna === "fecha_rotura" ||
                                    columna === "fecha_toma"
                                      ? dateFormatHour(ensayo[columna])
                                      : ensayo[columna]}
                                  </Text>
                                )
                              )}
                            </View>
                          )
                        )}
                      </View>
                      <View wrap={false}>
                        <Text style={styles.tittleLeft}>
                          RELACIÓN EQUIPOS EMPLEADOS / ID EQUIPO
                        </Text>
                        <View style={styles.centeredTableContainerEquipment}>
                          <View style={styles.centeredTableRow}>
                            <Text style={styles.centeredTableColumnHeader}>
                              NOMBRE
                            </Text>
                            <Text style={styles.centeredTableColumnHeader}>
                              CÓDIGO
                            </Text>
                          </View>
                          {ensayo.data_calculos.equipos.map((equipo) => (
                            <View style={styles.centeredTableRow}>
                              <Text style={styles.centeredTableColumn}>
                                {equipo.nombre}
                              </Text>
                              <Text style={styles.centeredTableColumn}>
                                {equipo.codigo}
                              </Text>
                            </View>
                          ))}
                        </View>
                      </View>
                    </>
                  ))}
                </>
              )}
            </View>
          ))}
        <View wrap={false}>
          {(reportData["NC1"] || reportData["NC2"] || reportData["NC3"]) && (
            <Text style={styles.tittle}>ESQUEMAS DE TIPOS DE FALLA</Text>
          )}
          {reportData &&
            Object.keys(reportData).map((tipoEnsayo) => (
              <View key={tipoEnsayo}>
                {/* Verificar si el tipo de ensayo ya fue procesado */}
                {!tiposFallaRenderizadas.has(tipoEnsayo) && (
                  <>
                    {tipoEnsayo === "NC1" && (
                      <>
                        <Text style={styles.text}>
                          ENSAYO DE RESISTENCIA A LA COMPRESIÓN DE CILINDROS,
                          NÚCLEOS Y MORTEROS DE CONCRETO
                        </Text>
                        <View style={styles.container}>
                          <Image
                            src={CylinderCompressionType1}
                            style={styles.imageTipoFalla}
                          />
                          <Image
                            src={CylinderCompressionType2}
                            style={styles.imageTipoFalla}
                          />
                          <Image
                            src={CylinderCompressionType3}
                            style={styles.imageTipoFalla}
                          />
                          <Image
                            src={CylinderCompressionType4}
                            style={styles.imageTipoFalla}
                          />
                          <Image
                            src={CylinderCompressionType5}
                            style={styles.imageTipoFalla}
                          />
                          <Image
                            src={CylinderCompressionType6}
                            style={styles.imageTipoFalla}
                          />
                        </View>
                      </>
                    )}
                    {tipoEnsayo === "NC2" && (
                      <>
                        <Text style={styles.text}>
                          ENSAYO DE TRACCIÓN POR HENDIMIENTO (TRACCIÓN
                          INDIRECTA) DE CILINDROS DE CONCRETO 4´´ Y 6´´
                        </Text>
                        <View style={styles.container}>
                          <Image
                            src={SplitTractionType1}
                            style={styles.imageTipoFalla}
                          />
                          <Image
                            src={SplitTractionType2}
                            style={styles.imageTipoFalla}
                          />
                          <Image
                            src={SplitTractionType3}
                            style={styles.imageTipoFalla}
                          />
                          <Image
                            src={SplitTractionType4}
                            style={styles.imageTipoFalla}
                          />
                        </View>
                      </>
                    )}
                    {tipoEnsayo === "NC3" && (
                      <>
                        <Text style={styles.text}>
                          ENSAYO DE RESISTENCIA A LA FLEXIÓN DEL CONCRETO USANDO
                          UNA VIGA SIMPLEMENTE APOYADA Y CARGADA EN LOS TERCIOS
                          DE LA LUZ LIBRE
                        </Text>
                        <View style={styles.container}>
                          <Image
                            src={ConcreteflexingTypeA}
                            style={styles.imageTipoFalla}
                          />
                          <Image
                            src={ConcreteflexingTypeB}
                            style={styles.imageTipoFalla}
                          />
                        </View>
                      </>
                    )}
                  </>
                )}
                {tiposFallaRenderizadas.add(tipoEnsayo)}
              </View>
            ))}
          {(reportData["NC1"] || reportData["NC2"] || reportData["NC3"]) && (
            <Text style={styles.tittle}>
              SE REALIZA EL CURADO DE ACUERDO A LO ESTABLECIDO EN LA NORMATIVA
              NTC 550, NUMERAL 9.2.3 QUE DETERMINA UNA TEMPERATURA DE 23°C ±
              2°C.
            </Text>
          )}
        </View>

        {/* FIRMAS AUXILIARES */}
        <View style={styles.containerFirmasAuxiliares}>
          {reportData &&
            Object.keys(reportData).map((tipoEnsayo) => (
              <View key={tipoEnsayo}>
                {(tipoEnsayo === "NC1" ||
                  tipoEnsayo === "NC2" ||
                  tipoEnsayo === "NC3" ||
                  tipoEnsayo === "NC4") && (
                  <>
                    {reportData[tipoEnsayo].map((ensayo) => (
                      <>
                        {ensayo.data.realizado_por && (
                          <>
                            {!firmasRenderizadas.has(
                              ensayo.data.realizado_por.nombre
                            ) && (
                              <View wrap={false}>
                                <Text style={styles.textFirma}>REALIZÓ</Text>
                                <Image
                                  src={
                                    "data:image/png;base64," +
                                    ensayo.data.realizado_por.firma
                                  }
                                  style={styles.imageFirma}
                                />
                                <Text style={styles.textFirma}>
                                  {ensayo.data.realizado_por.nombre}
                                </Text>
                                <Text style={styles.textFirma}>AUXILIAR</Text>
                              </View>
                            )}
                            {firmasRenderizadas.add(
                              ensayo.data.realizado_por.nombre
                            )}
                          </>
                        )}
                      </>
                    ))}
                  </>
                )}
              </View>
            ))}
        </View>

        {/* FIRMA COORDINADOR/DIRECTOR */}
        <View style={styles.containerFirmasCoordinadorDirector}>
          <View wrap={false}>
            <Text style={styles.textFirma}>APROBÓ</Text>
            {reportData && reportData["firma_autoriza"] && (
              <Image
                src={"data:image/png;base64," + reportData["firma_autoriza"]}
                style={styles.imageFirma}
              />
            )}
            <Text style={styles.textFirma}>
              {reportData["nombre_autoriza"]}
            </Text>
            <Text style={styles.textFirma}>COORDINADOR/DIRECTOR</Text>
          </View>
        </View>
        <Text style={styles.pageNumber}>
          FECHA DE EMISION: {dateTimeNowFormatted}
        </Text>
      </Page>
    </Document>
  );
};
