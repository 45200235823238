import React, { useEffect } from "react";
import Modal from "../../../components/Modal";
import { theme } from "../../../styles/theme";
import { Button } from "../../../components/Button";
import { Flex, Text } from "../../../styles/common";
import { Controller } from "react-hook-form";
import { Input } from "../../../components/Input";
import { Select } from "../../../components/Select";

export const ModalProviders = ({
  openModal,
  handleClose,
  onSubmit,
  formState,
  control,
  watch,
  isLoading,
  isEditing,
  workLineOptions,
  typeOfContactOptions,
}) => {
  const { isDirty, isValid, errors } = formState;

  return (
    <Modal
      align="center"
      isOpen={openModal}
      onClose={handleClose}
      padding={36}
      width={"min-content"}
    >
      <Modal.Header
        padding="0 0 16px 0"
        title={isEditing ? "Actualizar proveedor" : "Crear proveedor"}
        size={theme.fonts.size.h5}
      />
      <Modal.Body margin="16px 0 24px 0" minHeight={600}>
        <Flex direction="column" justify="center" gap="18px">
          <Controller
            name="nit"
            control={control}
            rules={{ required: true, maxLength: 20 }}
            render={({ field }) => (
              <Input
                id="nit"
                placeholder={"Nit"}
                error={!!errors.nit}
                helper={
                  errors?.nit && errors?.nit?.type === "required"
                    ? "Campo requerido"
                    : ""
                }
                {...field}
              />
            )}
          />
          <Controller
            name="nombre"
            control={control}
            rules={{ required: true, maxLength: 20 }}
            render={({ field }) => (
              <Input
                id="nombre"
                placeholder={"Nombre"}
                error={!!errors.nombre}
                helper={
                  errors?.nombre && errors?.nombre?.type === "required"
                    ? "Campo requerido"
                    : ""
                }
                {...field}
              />
            )}
          />
          <Controller
            name="direccion"
            control={control}
            rules={{ required: true, maxLength: 20 }}
            render={({ field }) => (
              <Input
                id="direccion"
                placeholder={"Dirección"}
                error={!!errors.direccion}
                helper={
                  errors?.direccion && errors?.direccion?.type === "required"
                    ? "Campo requerido"
                    : ""
                }
                {...field}
              />
            )}
          />
          <Controller
            name="numero_contacto"
            control={control}
            rules={{ required: true, maxLength: 20 }}
            render={({ field }) => (
              <Input
                id="numero_contacto"
                placeholder={"Número de contacto"}
                error={!!errors.numero_contacto}
                helper={
                  errors?.numero_contacto &&
                  errors?.numero_contacto?.type === "required"
                    ? "Campo requerido"
                    : ""
                }
                {...field}
              />
            )}
          />
          <Controller
            name="tipo_numero_contacto"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                id="tipo_numero_contacto"
                error={!!errors.tipo_numero_contacto}
                placeholder={"Tipo de contacto"}
                helper={
                  errors?.tipo_numero_contacto &&
                  errors?.tipo_numero_contacto?.type === "required"
                    ? "Campo requerido"
                    : ""
                }
                {...field}
              >
                <option value="" disabled hidden>
                  Tipo de contacto
                </option>
                {typeOfContactOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Select>
            )}
          />
          <Text
            size={theme.fonts.size.x}
            color={theme.colors.dark}
            style={{ textAlign: "start" }}
          >
            Línea de trabajo:
          </Text>
          <Controller
            name="linea_trabajo"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                id="linea_trabajo"
                error={!!errors.linea_trabajo}
                mode="multiple"
                helper={
                  errors?.linea_trabajo &&
                  errors?.linea_trabajo?.type === "required"
                    ? "Campo requerido"
                    : ""
                }
                {...field}
              >
                <option value="" disabled hidden>
                  Línea de trabajo
                </option>
                {workLineOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Select>
            )}
          />
        </Flex>
        <Flex m="30px 0 0 0" align="center" justify="center" gap={"24px"}>
          <Button
            background={theme.colors.white}
            hover={theme.colors.light3}
            color={theme.colors.dark}
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <Button
            background={theme.colors.orange}
            hover={
              !isDirty || !isValid
                ? theme.colors.gray
                : theme.colors.orangeHover
            }
            disabled={!isDirty || !isValid}
            isLoading={isLoading}
            onClick={() => {
              const linea_trabajo =
                watch("linea_trabajo") && watch("linea_trabajo").length > 0
                  ? watch("linea_trabajo").join(";")
                  : "";
              onSubmit({
                nit: watch("nit"),
                nombre: watch("nombre"),
                direccion: watch("direccion"),
                numero_contacto: watch("numero_contacto"),
                tipo_numero_contacto: watch("tipo_numero_contacto"),
                linea_trabajo: linea_trabajo.startsWith(";")
                  ? linea_trabajo.slice(1)
                  : linea_trabajo,
              });
            }}
          >
            {isEditing ? "Actualizar" : "Guardar"}
          </Button>
        </Flex>
      </Modal.Body>
    </Modal>
  );
};
