import React, { useEffect, useState } from "react";
import useGetDataReport from "./hooks/useGetDataReport";
import { useParams } from "react-router-dom";
import { Flex, Text } from "../../styles/common";
import { theme } from "../../styles/theme";
import Table from "../../components/Table";
import { Link } from "../../components/Link";
import { useNavigate } from "react-router";
import arrowBack from "../../assets/icons/arrow-back.svg";
import { getUser } from "../../utilities/helpers";
import { Button } from "../../components/Button";
import toast from "react-hot-toast";
import useSaveApproveTest from "./hooks/useSaveApproveTest";
import Modal from "../../components/Modal";
import { TextArea } from "../../components/TextArea";
import {
  columnsCylinderCompressionResults,
  columnsSplitTractionResults,
  columnsConcreteflexingResults,
  columnsCubesCompressionResults,
} from "../../utilities/helpers";

export const Results = () => {
  const { id } = useParams();
  const apiReportData = useGetDataReport(id);
  const { data, isSuccess } = apiReportData;
  const [dataTable, setDataTable] = useState([]);
  const navigate = useNavigate();
  const [rejectMessage, setRejectMessage] = useState("");
  const [error, setError] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const update = useSaveApproveTest();

  const handleUpdate = (accept, reject) => {
    if (reject && rejectMessage === "") {
      setError(true);
      setForceUpdate(!forceUpdate);
    } else {
      update.reset();
      update.mutate(
        {
          id_usuario: getUser().id,
          id_ensayo_realizar: Number(id),
          aprobar: accept,
          rechazar: reject,
          observacion: rejectMessage,
          json: data?.data,
        },
        {
          onSuccess: () => {
            toast.success(
              `Muestra ${accept ? "Aceptada" : "Rechazada"} con éxito`
            );
            navigate("/test");
          },
        }
      );
    }
  };

  const handleChangeReject = (e) => {
    const { value } = e.target;
    setRejectMessage(value);
    setError(false);
    setForceUpdate(!forceUpdate);
  };

  const handleClose = () => {
    setOpenModal(false);
    setRejectMessage("");
    setError(false);
    setForceUpdate(!forceUpdate);
  };

  const handleBackPage = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (isSuccess && data?.data.campos_ensayo.length > 0) {
      const dataTable = data?.data.campos_ensayo.map((ele, i) => ({
        ...ele,
      }));

      setDataTable(dataTable);
    } else {
      setDataTable([]);
    }
  }, [isSuccess, data]);

  let columns;

  switch (data?.data.campos_ensayo[0].codigo_ensayo) {
    case "NC1":
      columns = columnsCylinderCompressionResults();
      break;
    case "NC2":
      columns = columnsSplitTractionResults();
      break;
    case "NC3":
      columns = columnsConcreteflexingResults();
      break;
    case "NC4":
      columns = columnsCubesCompressionResults();
      break;
    default:
      columns = [];
      break;
  }

  return (
    <>
      <Modal
        align="center"
        isOpen={openModal}
        onClose={handleClose}
        padding={36}
        style={{ width: "100px" }}
      >
        <Modal.Header
          padding="0 0 16px 0"
          title="Rechazar ensayo"
          size={theme.fonts.size.h5}
        />
        <Modal.Body margin="16px 0 24px 0" minHeight={600}>
          <TextArea
            value={rejectMessage}
            label={"Motivo de rechazo*"}
            placeholder={"Ingrese el motivo del rechazo del ensayo"}
            onChange={handleChangeReject}
            width={"100%"}
            error={error}
            helper={error ? "Campo requerido" : ""}
          />
        </Modal.Body>
        <Modal.Footer gap={"16px"} justify="center">
          <Button
            background={theme.colors.white}
            hover={theme.colors.light3}
            color={theme.colors.dark}
            onClick={handleClose}
            style={{ width: "150px" }}
          >
            Cancelar
          </Button>
          <Button
            background={theme.colors.orange}
            hover={theme.colors.orangeHover}
            onClick={() => handleUpdate(false, true)}
          >
            Rechazar
          </Button>
        </Modal.Footer>
      </Modal>
      <Link onClick={handleBackPage} icon={<img alt="back" src={arrowBack} />}>
        Atras
      </Link>
      {data?.data ? (
        <>
          <Text
            size={theme.fonts.size.h4}
            weight={theme.fonts.weight.bold}
            color={theme.colors.dark}
            style={{ textAlign: "center" }}
          >
            Resultados del ensayo {data?.data.campos_ensayo[0].nombre_ensayo} de
            la orden de servicio N°{" "}
            {data?.data.campos_ensayo[0].id_orden_servicio}
          </Text>
          <div style={{ textAlign: "left" }}>
            <p>
              <b>SLUMP: </b>
              {data?.data.campos_ensayo[0].slump}
              Pulg
            </p>
            <p>
              <b>Resistencia nominal: </b>
              {data?.data.campos_ensayo[0].resistencia_nominal}
              Mpa
            </p>
            <p>
              <b>Temperatura toma de muestras: </b>
              {data?.data.campos_ensayo[0].temperatura_OS}
              °C
            </p>
          </div>
          <Flex width="100%">
            <Table data={dataTable} columns={columns} pagination={false} />
          </Flex>
          <Flex justify="center">
            {data?.data.campos_ensayo[0].codigo_estado_ensayo === "ER" && (
              <Flex gap="16px" justify="flex-end" m="20px 0 20px 0">
                {getUser().permissions?.includes(21) && (
                  <Button
                    background={theme.colors.orange}
                    hover={theme.colors.orangeHover}
                    onClick={() => handleUpdate(true, false)}
                    style={{ width: "150px" }}
                  >
                    Aceptar
                  </Button>
                )}
                {getUser().permissions?.includes(22) && (
                  <Button
                    background={theme.colors.white}
                    hover={theme.colors.light3}
                    color={theme.colors.dark}
                    onClick={() => setOpenModal(true)}
                    style={{ width: "150px" }}
                  >
                    Rechazar
                  </Button>
                )}
              </Flex>
            )}
          </Flex>
        </>
      ) : (
        <div>Cargando datos...</div>
      )}
    </>
  );
};
