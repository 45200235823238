import api from "../../../core/api";
import { useQuery } from "react-query";

const useLogout = (logout) => {
  const data = useQuery(["logout", logout], () => api.get(`/login/logout`), {
    enabled: logout,
  });

  return data;
};

export default useLogout;
