import { useMutation } from "react-query";
import api from "../../../core/api";

const useDeleteProviders = () => {
  const mutation = useMutation((id) => {
    return api.delete(`/muestra/proveedores/${id}`);
  });
  return mutation;
};

export default useDeleteProviders;
