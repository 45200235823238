/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Container, Flex, Text } from "../../styles/common";
import { theme } from "../../styles/theme";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useGetSamples from "./hooks/useGetSamples";
import { WrapperLine } from "../ServiceOrder/styles";
import { Col, Row, Tooltip } from "antd";
import { Button } from "../../components/Button";
import { Link } from "../../components/Link";
import arrowBack from "../../assets/icons/arrow-back.svg";
import useUpdate from "./hooks/useUpdateOrder";
import useSendReportEmail from "./hooks/useSendReportEmail";
import Modal from "../../components/Modal";
import { TextArea } from "../../components/TextArea";
import { toast } from "react-hot-toast";
import moment from "moment";
import Table from "../../components/Table";
import {
  columns,
  concreteColumnsActionDetail,
  concreteColumnsDetail,
  getUser,
  dateFormatHour,
} from "../../utilities/helpers";
import { useMediaQuery } from "react-responsive";
import email from "../../assets/icons/email.svg";
import emailDisable from "../../assets/icons/email-disable.svg";
import useGetTest from "../ServiceOrder/hook/useGetTests";
import useGetSpecifications from "../Specifications/hooks/useGetSpecifications";
import useGetProviders from "../Providers/hooks/useGetProviders";
import useGetSpecimens from "../Specimens/hooks/useGetSpecimens";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

export const ServiceOrderDetail = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.sm})`,
  });

  const navigate = useNavigate();
  const update = useUpdate();

  const { id } = useParams();
  const samples = useGetSamples(id);
  const { data, isSuccess } = samples;
  const location = useLocation();
  const { search } = location;
  const searchParams = new URLSearchParams(search);
  const status = searchParams.get("status");
  const workLine = searchParams.get("workLine");
  const [samplesData, setSamplesData] = useState([]);
  const [currentSample, setCurrentSample] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [rejectMessage, setRejectMessage] = useState("");
  const [error, setError] = useState(false);
  const [totalItems, setTotalItems] = useState(3);
  const [pages, setPages] = useState({ current: 1, pageSize: 3 });
  const [dataTable, setDataTable] = useState([]);
  const [forceUpdate, setForceUpdate] = useState(false);
  const { data: testData } = useGetTest();
  const { data: specificationsData } = useGetSpecifications();
  const { data: providersData } = useGetProviders();
  const { data: specimensData } = useGetSpecimens();
  const sendReportEmail = useSendReportEmail(samplesData[currentSample]?.id);
  useEffect(() => {
    if (isSuccess) {
      setSamplesData(data?.data);
    }
  }, [isSuccess]);

  const handleNext = () => {
    setCurrentSample(currentSample + 1);
    setForceUpdate(!forceUpdate);
  };

  const handleBack = () => {
    setCurrentSample(currentSample - 1);
    setForceUpdate(!forceUpdate);
  };

  const handleBackPage = () => {
    navigate(-1);
  };

  // const exportColumns = [
  //   "id_orden_servicio",
  //   "linea_trabajo",
  //   "Cliente",
  //   "codigo_muestra",
  //   "nombre_ensayo",
  //   "cantidad_especimen_ensayo",
  //   "edad_falla",
  //   "unidad_tiempo",
  //   "testigo",
  //   "estado_ensayo",
  //   "auxiliares_operativos",
  //   "fecha_fallo",
  // ];

  // const exportToExcel = () => {
  //   const filteredDataTable = dataTable.map((row) => {
  //     const filteredRow = {};
  //     Object.keys(row).forEach((key) => {
  //       if (exportColumns.includes(key)) {
  //         if (key === "fecha_fallo") {
  //           filteredRow[key] = dateFormatHour(row[key]);
  //         } else if (key === "auxiliares_operativos") {
  //           filteredRow[key] = row[key]
  //             .map((auxiliar) => `${auxiliar.nombres} ${auxiliar.apellidos}`)
  //             .join(", ");
  //         } else {
  //           filteredRow[key] = row[key];
  //         }
  //         // filteredRow[key] = row[key];
  //         // filteredRow[key] =
  //         //   key === "fecha_fallo" ? dateFormatHour(row[key]) : row[key];
  //       }
  //     });
  //     return filteredRow;
  //   });

  //   const worksheet = XLSX.utils.json_to_sheet(filteredDataTable);
  //   const workbook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(workbook, worksheet, "Hoja1");
  //   const excelBuffer = XLSX.write(workbook, {
  //     bookType: "xlsx",
  //     type: "array",
  //   });
  //   const blob = new Blob([excelBuffer], {
  //     type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
  //   });
  //   saveAs(blob, "Ensayos.xlsx");
  // };

  const handleUpdate = (accept, reject) => {
    if (reject && rejectMessage === "") {
      setError(true);
      setForceUpdate(!forceUpdate);
    } else {
      update.reset();
      update.mutate(
        {
          id_usuario: getUser().id,
          id_orden: Number(id),
          aprobar: accept,
          rechazar: reject,
          observacion: rejectMessage,
          fecha_aceptacion_muestra: accept
            ? moment().format("YYYY-MM-DD")
            : null,
        },
        {
          onSuccess: () => {
            toast.success(
              `Muestra ${accept ? "Aceptada" : "Rechazada"} con éxito`
            );
            navigate("/service-order/list");
          },
        }
      );
    }
  };

  const handleClose = () => {
    setOpenModal(false);
    setRejectMessage("");
    setError(false);
    setForceUpdate(!forceUpdate);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setRejectMessage(value);
    setError(false);
    setForceUpdate(!forceUpdate);
  };

  const handleSendReportEmail = () => {
    sendReportEmail.reset();
    sendReportEmail.mutate(
      {
        id_muestra: samplesData[currentSample]?.id,
      },
      {
        onSuccess: () => {
          toast.success("Informe envíado con éxito");
        },
      }
    );
  };

  useEffect(() => {
    if (samplesData[currentSample]?.ensayos_realizar.length > 0) {
      const dataTable = samplesData[currentSample]?.ensayos_realizar.map(
        (ele, i) => ({
          key: i,
          ...ele,
          ensayo:
            status === "VoBo_laboratorio"
              ? testData?.data.find((elem) => elem.id === ele.ensayo)?.nombre
              : ele.ensayo,
          // acciones: [
          //   {
          //     key: `1${i}`,
          //     tooltip:
          //       ele.codigo_estado === "FZ"
          //         ? "Enviar informe"
          //         : "Estado no finalizado",
          //     icon: ele.codigo_estado === "FZ" ? email : emailDisable,
          //     onClick: () => handleSendReportEmail(ele.id_ensayo, 0),
          //     disabled: ele.codigo_estado !== "FZ",
          //   },
          // ],
        })
      );

      setDataTable(dataTable);
      setTotalItems(samplesData[currentSample]?.ensayos_realizar.length);
      setForceUpdate(!forceUpdate);
    } else {
      setDataTable([]);
    }
  }, [samplesData[currentSample]]);

  const handleChangeTable = (pagination) => {
    setTotalItems(pagination.total);
    setPages({ current: pagination.current, pageSize: pagination.pageSize });
  };

  return (
    <Container>
      <Modal
        align="center"
        isOpen={openModal}
        onClose={handleClose}
        padding={36}
        style={{ width: "100px" }}
      >
        <Modal.Header
          padding="0 0 16px 0"
          title="Rechazar orden de servicio"
          size={theme.fonts.size.h5}
        />
        <Modal.Body margin="16px 0 24px 0" minHeight={600}>
          <TextArea
            value={rejectMessage}
            label={"Motivo de rechazo*"}
            placeholder={"Ingrese el motivo del rechazo de la orden"}
            onChange={handleChange}
            width={"100%"}
            error={error}
            helper={error ? "Campo requerido" : ""}
          />
        </Modal.Body>
        <Modal.Footer gap={"16px"} justify="center">
          <Button
            background={theme.colors.white}
            hover={theme.colors.light3}
            color={theme.colors.dark}
            onClick={handleClose}
            style={{ width: "150px" }}
          >
            Cancelar
          </Button>
          <Button
            background={theme.colors.orange}
            hover={theme.colors.orangeHover}
            onClick={() => handleUpdate(false, true)}
          >
            Rechazar
          </Button>
        </Modal.Footer>
      </Modal>

      <Link
        onClick={handleBackPage}
        icon={<img alt="back" src={arrowBack} />}
        style={{ marginBottom: "10px" }}
      >
        Atras
      </Link>
      {/* {samplesData.length > 0 && (
        <Flex justify="end">
          <Button
            background={theme.colors.orange}
            hover={theme.colors.orangeHover}
            onClick={() => exportToExcel()}
          >
            Exportar información
          </Button>
        </Flex>
      )} */}

      <Text
        size={theme.fonts.size.h4}
        weight={theme.fonts.weight.bold}
        color={theme.colors.dark}
        style={{ textAlign: "center" }}
      >
        {status === "VoBo_laboratorio" ? (
          <>
            Detalle de la orden de servicio N°{" "}
            {samplesData[currentSample]?.orden_servicio}
          </>
        ) : (
          <>
            Detalle de la orden de servicio N°{" "}
            {samplesData[currentSample]?.orden_servicio_id}
          </>
        )}
      </Text>
      <Flex
        align="center"
        justify="space-between"
        direction={isMobile ? "column" : "row"}
        gap={isMobile ? "20px" : "0px"}
      >
        {status === "VoBo_laboratorio" && (
          <Flex gap="16px">
            {getUser().permissions?.includes(8) && (
              <Button
                background={theme.colors.orange}
                hover={theme.colors.orangeHover}
                onClick={() => handleUpdate(true, false)}
                style={{ width: "150px" }}
              >
                Aprobar
              </Button>
            )}
            {getUser().permissions?.includes(9) && (
              <Button
                background={theme.colors.white}
                hover={theme.colors.light3}
                color={theme.colors.dark}
                onClick={() => setOpenModal(true)}
                style={{ width: "150px" }}
              >
                Rechazar
              </Button>
            )}
          </Flex>
        )}
      </Flex>

      {samplesData.length > 0 ? (
        <Flex direction="column" p="30px 0 0 0">
          <>
            <Flex
              justify="space-between"
              align={isMobile ? "start" : "center"}
              direction={isMobile ? "column" : "row"}
              gap={isMobile ? "20px" : "0px"}
            >
              <Text size={theme.fonts.size.h5} weight={theme.fonts.weight.bold}>
                Muestra {samplesData[currentSample]?.codigo_laboratorio}
              </Text>
              <WrapperLine>
                <Text
                  weight={theme.fonts.weight.bold}
                  size={theme.fonts.size.h6}
                  color={theme.colors.white}
                  style={{ textAlign: "start" }}
                >
                  {workLine}
                </Text>
              </WrapperLine>
            </Flex>
            <Text
              size={theme.fonts.size.h6}
              weight={theme.fonts.weight.bold}
              color={theme.colors.orange}
              mt="30px"
              mb="20px"
            >
              Ensayos
            </Text>
            <Table
              columns={
                workLine === "Línea de concretos"
                  ? getUser().permissions?.includes(28)
                    ? concreteColumnsActionDetail
                    : concreteColumnsDetail
                  : columns
              }
              data={dataTable}
              sizerLabels={["Mostrando", "por página"]}
              pagination={
                totalItems > 3
                  ? {
                      ...pages,
                      total: totalItems,
                      showSizeChanger: true,
                      pageSizeOptions: ["3", "6", "18"],
                      locale: {
                        items_per_page: "",
                      },
                    }
                  : false
              }
              onChange={handleChangeTable}
              locale={{
                emptyText: "No hay datos",
              }}
            />
            <Flex justify="end">
              <Tooltip
                title={
                  !samplesData[currentSample]?.ensayos_realizar.some(
                    (ele) => ele.codigo_estado === "FZ"
                  )
                    ? "Pendiente de finalizar ensayos."
                    : "Enviar ensayos finalizados de esta muestra."
                }
                color={theme.colors.orange}
              >
                <Button
                  background={theme.colors.orange}
                  hover={theme.colors.orangeHover}
                  onClick={() => handleSendReportEmail()}
                  disabled={
                    !samplesData[currentSample]?.ensayos_realizar.some(
                      (ele) => ele.codigo_estado === "FZ"
                    )
                  }
                >
                  Enviar informes
                </Button>
              </Tooltip>
            </Flex>
            <Text
              size={theme.fonts.size.h6}
              weight={theme.fonts.weight.bold}
              color={theme.colors.orange}
              mt="30px"
              mb="20px"
            >
              Información general
            </Text>
            <Row gutter={[0, 20]}>
              <Col md={12} xs={24}>
                <Text>
                  <strong>Cliente:</strong>{" "}
                  {samplesData[currentSample]?.cliente_proyecto}
                </Text>
              </Col>
              <Col md={12} xs={24}>
                <Text>
                  <strong>Especificación:</strong>{" "}
                  {status === "VoBo_laboratorio"
                    ? specificationsData?.data.find(
                        (elem) =>
                          elem.id === samplesData[currentSample]?.especificacion
                      )?.nombre
                    : samplesData[currentSample]?.especificacion_id}
                </Text>
              </Col>
              <Col md={12} xs={24}>
                <Text>
                  <strong>Especimen:</strong>{" "}
                  {status === "VoBo_laboratorio"
                    ? specimensData?.data.find(
                        (elem) =>
                          elem.id === samplesData[currentSample]?.especimen
                      )?.nombre
                    : samplesData[currentSample]?.especimen_id}
                </Text>
              </Col>
              <Col md={12} xs={24}>
                <Text>
                  <strong>Proveedor:</strong>{" "}
                  {status === "VoBo_laboratorio"
                    ? providersData?.data.find(
                        (elem) =>
                          elem.id === samplesData[currentSample]?.proveedor
                      )?.nombre
                    : samplesData[currentSample]?.proveedor_id}
                </Text>
              </Col>
              <Col md={12} xs={24}>
                <Text>
                  <strong>Dirección:</strong>{" "}
                  {samplesData[currentSample]?.georeferenciacion}
                </Text>
              </Col>
              <Col md={12} xs={24}>
                <Text>
                  <strong>Complemento dirección:</strong>{" "}
                  {samplesData[currentSample]?.descripcion_georeferenciacion}
                </Text>
              </Col>
            </Row>
            <Text
              size={theme.fonts.size.h6}
              weight={theme.fonts.weight.bold}
              color={theme.colors.orange}
              mt="30px"
              mb="20px"
            >
              Información de la muestra
            </Text>
            <Row gutter={[0, 20]}>
              {workLine === "Línea de concretos" && (
                <>
                  <Col md={12} xs={24}>
                    <Text>
                      <strong>Fecha de toma de muestras:</strong>{" "}
                      {samplesData[currentSample]?.fecha_toma}
                    </Text>
                  </Col>
                  <Col md={12} xs={24}>
                    <Text>
                      <strong>Hora de toma de muestras:</strong>{" "}
                      {samplesData[currentSample]?.hora_toma}
                    </Text>
                  </Col>
                  <Col md={12} xs={24}>
                    <Text>
                      <strong>Código mezcla:</strong>{" "}
                      {samplesData[currentSample]?.codigo_mezcla}
                    </Text>
                  </Col>
                  <Col md={12} xs={24}>
                    <Text>
                      <strong>Descripción mezcla:</strong>{" "}
                      {samplesData[currentSample]?.descripcion_mezcla}
                    </Text>
                  </Col>
                  <Col md={12} xs={24}>
                    <Text>
                      <strong>Resistencia nominal (MPa):</strong>{" "}
                      {samplesData[currentSample]?.resistencia_nominal}
                    </Text>
                  </Col>
                  <Col md={12} xs={24}>
                    <Text>
                      <strong>Slump (Pulgadas "):</strong>{" "}
                      {samplesData[currentSample]?.slum}
                    </Text>
                  </Col>
                </>
              )}
              {(workLine === "Línea de concretos" ||
                workLine === "Línea de asfaltos") && (
                <Col md={12} xs={24}>
                  <Text>
                    <strong>Temperatura (°C):</strong>{" "}
                    {samplesData[currentSample]?.temperatura}
                  </Text>
                </Col>
              )}
              <Col md={12} xs={24}>
                <Text>
                  <strong>Cantidad de especimenes :</strong>{" "}
                  {samplesData[currentSample]?.cantidad_especimen}
                </Text>
              </Col>
              <Col md={12} xs={24}>
                <Text>
                  <strong>Localización de la muestra:</strong>{" "}
                  {samplesData[currentSample]?.locacion}
                </Text>
              </Col>
              <Col md={12} xs={24}>
                <Text>
                  <strong>Número de remisión :</strong>{" "}
                  {samplesData[currentSample]?.numero_remision}
                </Text>
              </Col>
              <Col md={24} xs={24}>
                <Text>
                  <strong>Observación:</strong>{" "}
                  {samplesData[currentSample]?.observacion}
                </Text>
              </Col>
            </Row>
            {samplesData.length > 1 && (
              <Flex m={"20px 0 0 0"} gap="16px" justify="flex-end">
                {currentSample !== 0 && (
                  <Button
                    background={theme.colors.gray}
                    hover={theme.colors.gray3}
                    color={theme.colors.dark}
                    onClick={handleBack}
                    style={{ width: "150px" }}
                  >
                    Atras
                  </Button>
                )}
                {currentSample !== samplesData.length - 1 && (
                  <Button
                    background={theme.colors.orange}
                    hover={theme.colors.orangeHover}
                    onClick={handleNext}
                    style={{ width: "150px" }}
                  >
                    Siguiente
                  </Button>
                )}
              </Flex>
            )}
          </>
        </Flex>
      ) : (
        <Flex align="center" justify="center" m="50px 0 0 0">
          <Text size={theme.fonts.size.h5}>
            No se han ingresado muestras en esta orden de servicio.
          </Text>
        </Flex>
      )}
    </Container>
  );
};
