/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { WrapperMenu } from "./styles";
import { Collapse } from "../../Collapse";
import { useNavigate } from "react-router-dom";
import { getUser } from "../../../utilities/helpers";
import { Flex, Text } from "../../../styles/common";
import logo from "../../../assets/images/logo2.png";
import { Button } from "../../Button";
import { theme } from "../../../styles/theme";

export const Menu = ({ options, handleLogout, isLoading, logout }) => {
  const navigate = useNavigate();
  const { name, lastName } = getUser();

  const handleHome = () => {
    navigate("/home");
  };

  return (
    <WrapperMenu elevation={2} radius={8} p="12px">
      <Flex direction="column">
        <Flex justify="center">
          <img
            alt="logo"
            src={logo}
            height={"80px"}
            onClick={handleHome}
            style={{ cursor: "pointer", marginBottom: "60px" }}
          />
        </Flex>
        <Collapse panels={options} />
      </Flex>

      <Flex justify="space-between" align="center" m="0 0 30px 0">
        <Text>
          Bienvenido {name} {lastName}
        </Text>
        <Button
          type="link"
          background={theme.colors.white}
          style={{ padding: 0 }}
          onClick={handleLogout}
          loading={isLoading}
        >
          <img alt="logout" src={logout} />
        </Button>
      </Flex>
    </WrapperMenu>
  );
};
