import React, { useState } from "react";
import { CustomCollapse } from "./styles";
import arrowUp from "../../assets/icons/arrow-up.svg";
import arrowDown from "../../assets/icons/arrow-down.svg";
import { theme } from "../../styles/theme";
import { Flex, Text } from "../../styles/common";
import { Link } from "../Link";

const { Panel } = CustomCollapse;

export const Collapse = ({ panels }) => {
  const [activeKey, setActiveKey] = useState(null);

  const handlePanelChange = (key) => {
    setActiveKey(key);
  };

  return (
    <CustomCollapse
      accordion
      activeKey={activeKey}
      onChange={handlePanelChange}
      expandIcon={({ isActive }) =>
        isActive ? (
          <img alt="arrow-up" src={arrowUp} />
        ) : (
          <img alt="arrow-down" src={arrowDown} />
        )
      }
      expandIconPosition={"end"}
      style={{ background: theme.colors.white, border: "none", width: "100%" }}
    >
      {panels.map(
        (ele, indx) =>
          ele.permission && (
            <Panel
              id={indx}
              key={ele.key}
              header={
                <Flex align="center" gap="8px">
                  <img alt="icon" src={ele.icon} />
                  <Text>{ele.label}</Text>
                </Flex>
              }
              style={{
                background: theme.colors.white,
                borderBottom: `1px solid ${theme.colors.light2}`,
              }}
            >
              {ele.options.map(
                (elem, index) =>
                  elem.permission && (
                    <Link key={index} onClick={elem.onClick}>
                      {elem.label}
                    </Link>
                  )
              )}
            </Panel>
          )
      )}
    </CustomCollapse>
  );
};
