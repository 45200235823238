import api from "../../../core/api";
import { useQuery } from "react-query";

const useGetSpecimens = () => {
  const data = useQuery(["useGetSpecimens"], () =>
    api.get(`/muestra/especimenes`)
  );

  return data;
};

export default useGetSpecimens;
