import api from "../../../core/api";
import { useQuery } from "react-query";

const useGetDataReport = (id) => {
  const data = useQuery(["useGetDataReport", id], () =>
    api.get(`/ensayo/ensayos-muestra-informe/?muestra_id=${id}`)
  );

  return data;
};

export default useGetDataReport;
