import React, { useEffect, useState } from "react";
import useGetUsers from "./hooks/useGetUsers";
import useDeleteUsers from "./hooks/useDeleteUsers";
import useCreateUsers from "./hooks/useCreateUsers";
import useUpdateUsers from "./hooks/useUpdateUsers";
import useGetRoles from "./hooks/useGetRoles";
import useGetTypeOfDocument from "./hooks/useGetTypeOfDocument";
import { Container, Flex, Text } from "../../styles/common";
import Table from "../../components/Table";
import { Button } from "../../components/Button";
import { theme } from "../../styles/theme";
import Skeleton from "../../components/Skeleton";
import deleteIcon from "../../assets/icons/delete.svg";
import editIcon from "../../assets/icons/edit.svg";
import toast from "react-hot-toast";
import { Tooltip } from "antd";
import { useForm } from "react-hook-form";
import { ModalUsers } from "./components/ModalUsers";

export const Users = () => {
  const [pages, setPages] = useState({ current: 1, pageSize: 7 });
  const [totalItems, setTotalItems] = useState(7);
  const [dataTable, setDataTable] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const createUsers = useCreateUsers();
  const { data, isLoading, isSuccess, refetch } = useGetUsers();
  const deleteUsers = useDeleteUsers();
  const [editingUsers, setEditingUsers] = useState("");
  const updateUsers = useUpdateUsers(editingUsers.id);
  const [rolesOptions, setRolesOptions] = useState([]);
  const { data: rolesData, isSuccess: isSuccessRoles } = useGetRoles();
  const [typeOfDocumentOptions, setTypeOfDocumentOptions] = useState([]);
  const { data: typeOfDocumentData, isSuccess: isSuccessTypeOfDocument } =
    useGetTypeOfDocument();

  const defaultValues = {
    numeroDocumento: "",
    tipoDocumento: "",
    correoElectronico: "",
    nombres: "",
    apellidos: "",
    firma_imagen: "",
    rol: "",
  };

  const { control, watch, reset, formState, setValue } = useForm({
    mode: "onTouched",
    defaultValues,
  });

  const handleUpdate = (id) => {
    setOpenModal(true);
    setIsEditing(true);
    const usersToUpdate = data && data.data.find((spec) => spec.id === id);
    if (usersToUpdate) {
      setEditingUsers(usersToUpdate);
      reset({
        numeroDocumento: usersToUpdate.numeroDocumento,
        tipoDocumento: usersToUpdate.tipoDocumento,
        correoElectronico: usersToUpdate.correoElectronico,
        nombres: usersToUpdate.nombres,
        apellidos: usersToUpdate.apellidos,
        rol: usersToUpdate.rol,
        firma_imagen: usersToUpdate.firma_imagen,
      });
    } else {
      reset(defaultValues);
    }
  };

  const handleDelete = (id) => {
    deleteUsers.reset();
    deleteUsers.mutate(id, {
      onSuccess: () => {
        toast.success(`Usuario eliminado con éxito`);
        refetch();
      },
    });
  };

  const handleChangeTable = (pagination) => {
    setTotalItems(pagination.total);
    setPages({ current: pagination.current, pageSize: pagination.pageSize });
  };

  const handleClose = () => {
    setOpenModal(false);
    reset();
  };

  const handleCreate = () => {
    setOpenModal(true);
    setIsEditing(false);
    reset(defaultValues);
  };

  useEffect(() => {
    if (isSuccessRoles) {
      setRolesOptions(
        rolesData?.data.map((ele) => ({
          label: ele.nombre,
          value: ele.id,
        }))
      );
    }
  }, [isSuccessRoles]);

  useEffect(() => {
    if (isSuccessTypeOfDocument) {
      setTypeOfDocumentOptions(
        typeOfDocumentData?.data.map((ele) => ({
          label: ele.nombre,
          value: ele.id,
        }))
      );
    }
  }, [isSuccessTypeOfDocument]);

  useEffect(() => {
    if (isSuccess && data?.data.length > 0) {
      const dataTable = data?.data.map((ele, i) => ({
        ...ele,
        rol: rolesOptions.find((elem) => elem.value === ele.rol)?.label,
        tipoDocumento: typeOfDocumentOptions.find(
          (elem) => elem.value === ele.tipoDocumento
        )?.label,
        acciones: [
          {
            key: `1${i}`,
            tooltip: "Editar",
            icon: editIcon,
            onClick: () => handleUpdate(ele.id),
            disabled: false,
          },
          {
            key: `1${i}`,
            tooltip: "Eliminar",
            icon: deleteIcon,
            onClick: () => handleDelete(ele.id),
            disabled: false,
          },
        ],
      }));

      setDataTable(dataTable);
      setTotalItems(dataTable.length);
    } else {
      setDataTable([]);
    }
  }, [data, isSuccess, isSuccessRoles, isSuccessTypeOfDocument]);

  const onSubmit = (payload) => {
    if (isEditing && editingUsers) {
      updateUsers.mutate(
        {
          ...payload,
        },
        {
          onSuccess: () => {
            toast.success("Usuario actualizado con éxito");
            setOpenModal(false);
            reset();
            refetch();
          },
        }
      );
    } else {
      createUsers.mutate(payload, {
        onSuccess: () => {
          toast.success("Usuario creado con éxito");
          setOpenModal(false);
          reset();
          refetch();
        },
      });
    }
  };

  const columns = [
    {
      title: "Id de usuario",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Tipo de documento",
      dataIndex: "tipoDocumento",
      key: "tipoDocumento",
    },
    {
      title: "Número de documento",
      dataIndex: "numeroDocumento",
      key: "numeroDocumento",
    },
    {
      title: "Correo electronico",
      dataIndex: "correoElectronico",
      key: "correoElectronico",
    },
    {
      title: "Nombre",
      dataIndex: "nombres",
      key: "nombres",
    },
    {
      title: "Apellidos",
      dataIndex: "apellidos",
      key: "apellidos",
    },
    {
      title: "Firma",
      dataIndex: "firma_imagen",
      key: "firma_imagen",
      render: (imageBase64) =>
        imageBase64 ? (
          <img
            src={`data:image/png;base64,${imageBase64}`}
            alt="Firma"
            style={{ width: "100px", height: "auto" }}
          />
        ) : (
          "Sin firma"
        ),
    },
    {
      title: "Rol",
      dataIndex: "rol",
      key: "rol",
    },
    {
      title: "Acciones",
      dataIndex: "acciones",
      key: "acciones",
      render: (actions) => (
        <Flex gap={16} justify={"space-around"}>
          {actions.map((action, i) => (
            <Tooltip title={action.tooltip} color={theme.colors.orange}>
              <Button
                icon={
                  <img
                    alt={`${action.icon}`}
                    src={action.icon}
                    width={"24px"}
                  />
                }
                onClick={() => action.onClick()}
                disabled={action.disabled}
                style={{
                  background: "transparent",
                  border: "none",
                  boxShadow: "none",
                }}
              />
            </Tooltip>
          ))}
        </Flex>
      ),
    },
  ];

  return (
    <Container>
      <ModalUsers
        openModal={openModal}
        handleClose={handleClose}
        onSubmit={onSubmit}
        formState={formState}
        control={control}
        watch={watch}
        isLoading={createUsers.isLoading}
        isEditing={isEditing}
        rolesOptions={rolesOptions}
        typeOfDocumentOptions={typeOfDocumentOptions}
        signatureImage={editingUsers.firma_imagen}
        setValue={setValue}
      />
      <Flex justify="space-between">
        <Text
          size={theme.fonts.size.h4}
          weight={theme.fonts.weight.bold}
          color={theme.colors.dark}
          style={{ textAlign: "left" }}
          mb="40px"
        >
          Usuarios
        </Text>
        <Button
          background={theme.colors.orange}
          hover={theme.colors.orangeHover}
          onClick={handleCreate}
        >
          Crear usuario
        </Button>
      </Flex>
      <Table
        columns={columns}
        data={dataTable}
        sizerLabels={["Mostrando", "por página"]}
        pagination={{
          ...pages,
          total: totalItems,
          showSizeChanger: true,
          pageSizeOptions: ["7", "45", "70"],
          locale: {
            items_per_page: "",
          },
        }}
        onChange={handleChangeTable}
        locale={{
          emptyText: isLoading ? (
            <Flex justify="center" style={{ gap: 16 }}>
              {[...Array(9)].map((v, idx) => (
                <Skeleton
                  title={false}
                  paragraph={{
                    requests: 10,
                    width: "100%",
                  }}
                  loading
                  key={idx}
                />
              ))}
            </Flex>
          ) : (
            "No hay datos"
          ),
        }}
      />
    </Container>
  );
};
